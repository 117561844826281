import React, {useCallback} from 'react';
import {Backdrop, Card, Divider, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import validator from "validator";
import ReportParamsConfig from "./ReportParamsConfig";
import {getFinalDayOfMonthInUTC, getInitOfDayMonthInUTC} from "../../../../../utils/dateUtil";
import {dateToUTCServerFormatString} from "../../../../../utils/dateToUtcStringConverter";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ReportSteps from "./ReportSteps";
import Step2 from "../../download/steps/Step2";

export const isCurrentPeriodValid = (date,period)=>{
    const initialDayOfCurrentMont = getInitOfDayMonthInUTC(new Date())
    const endOfPeriod = getFinalDayOfMonthInUTC(date,period-1)
    return initialDayOfCurrentMont.getTime() > endOfPeriod.getTime()
}

const useStyles = makeStyles({
    steps:{
        marginRight:20,
        marginLeft:20
    },
    card:{
        paddingTop:16,
        paddingBottom:10,
    },
    tittle:{
        marginLeft:30,
        color:"#434343",
        marginBottom:16
    },
    buttons:{
        marginRight:30,
        display:"flex",
        marginTop:10,
        justifyContent:"flex-end"
    },
    divider:{
        marginBottom:32
    },
    divider2:{
        marginTop:8
    },
    popUpContainer:{
        maxWidth:980
    },

    step1Container:{
        paddingTop:60,
        paddingBottom:30,
        paddingLeft:30,
        paddingRight:30,
        maxWidth:1000,
        justifyContent:"center"
    },
    step2Container:{
        padding:30,
        maxWidth:700,
        justifyContent:"center"
    }

});

const ReportConfigPopUp = ({open, finishReportSetup, cancelReportSetup}) => {

    const {t} = useTranslation()
    const currentDate = new Date()
    const { units } = useSelector( state => state.auth );
    const [{name,initDate,pollutants,period,
        temperature,index,windSpeed,activeStep,
        selectedStations,step1Valid,step2Valid}, updateState] = React.useState(
        {
            name:"",
            initDate:new Date(currentDate.getFullYear(),currentDate.getMonth()-1,currentDate.getDate()),
            period:1,
            pollutants:units.pollutants,
            temperature:units.temperature,
            index:units.index,
            windSpeed:units.windSpeed,
            activeStep:0,
            selectedStations:[],
            step1Valid:false,
            step2Valid:false
        });

    const indexCallback = useCallback((event) => {
        updateState(state=>({...state,index:event.target.value}))
    },[])

    const windSpeedCallback =  useCallback((event) => {
        updateState(state=>({...state,windSpeed:event.target.value}))
    },[])

    const nameCallback = useCallback((event)=>{
        let value = event.target.value
        let step1Valid = !validator.isEmpty(value)
        updateState(state=>({...state,name:value,step1Valid}))
    },[])

    const tempUnitCallback = useCallback((event) => {
        updateState(state=>({...state,temperature:event.target.value}))
    },[])

    const periodCallback = useCallback((event)=>{
        updateState(state=>({...state,period:event.target.value}))
    },[])

    const unitsCallback = useCallback((event)=>{
        updateState(state=>({...state,pollutants:event.target.value}))
    },[])

    const dateCallback = useCallback((value)=>{
        updateState(state=>({...state,initDate:value}))
    },[])

    //Step 2 callbacks
    const selectedStationsCallback = useCallback((stations)=>{
        updateState(state=>({...state,selectedStations: stations, step2Valid: stations.length>0}))
    },[])

    const classes = useStyles();

    const handleFirstButton = ()=>{
        if (activeStep===0){
            cancelReportSetup();
        }else {
            updateState(state => ({...state,activeStep: activeStep-1}))
        }
    }

    const handleSecondButton = ()=>{
        if (activeStep<1){
            updateState(state => ({...state,activeStep: activeStep+1}))
        }else {
            finishReportSetup({
                name,
                time: dateToUTCServerFormatString(getInitOfDayMonthInUTC(initDate)),
                pollutants,
                temperature,
                windSpeed,
                index,
                months: period,
                stations:selectedStations.map(item => item.id)
            })
        }
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <Card className={classes.card} data-testid={"create-report-popup"}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <Typography variant={"h6"} className={classes.tittle}>{t("reportScreen.reportConfig.title")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid item xs={12} className={classes.steps}>
                        <ReportSteps activeStep={activeStep}/>
                    </Grid>
                    { activeStep === 0 &&<Grid container item xs={12} className={classes.step1Container} >
                         <ReportParamsConfig name={name} nameCallback={nameCallback}
                                             period={period} periodCallback={periodCallback}
                                             pollutantUnit={pollutants}
                                             tempUnits={temperature}
                                             tempoCallback={tempUnitCallback}
                                             pollutantUnitsCallback={unitsCallback}
                                             initialDate={initDate}
                                             updateDate={dateCallback}
                                             index={index}
                                             indexCallback={indexCallback}
                                             windSpeedCallback={windSpeedCallback}
                                             windSpeedUnit={windSpeed}
                         />
                    </Grid>}
                    {activeStep === 1 && <Grid container item xs={12} className={classes.step2Container} >
                        <Step2 initialSelection={selectedStations} callback={selectedStationsCallback} />
                    </Grid>}
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            {activeStep !== 0 && <Button onClick={cancelReportSetup}>{t("cancel")}</Button>}
                            <Button onClick={handleFirstButton}>{activeStep === 0 ? t("cancel") : t("back")}</Button>
                            <Button
                                data-testid={"next-finish-report-config"}
                                disabled={activeStep === 0 ? !step1Valid : !step2Valid  }
                                onClick={handleSecondButton}>{activeStep <1 ? t("next") : t("finish")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default ReportConfigPopUp;
