import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const getPasswordListRequest = (callback) => {
    webClient.get('/user/app-password')
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        })
};