import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";
import {dateToUTCServerFormatString} from "../../utils/dateToUtcStringConverter";
import {getInitOfDayMonthInUTC} from "../../utils/dateUtil";

export const predominantPollutantRequest = (stationId,date,index,callback) => {
    const initialDayOfMonth = getInitOfDayMonthInUTC(date)
    let time =  dateToUTCServerFormatString(initialDayOfMonth);
    let url = `/station-data/${stationId}/predominant-pollutant?time=${time}&index=${index}`
    webClient.get(url)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        })
};