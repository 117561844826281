

export const unitsMap = new Map();
unitsMap.set("ppb", "ppb");
unitsMap.set("ppm", "ppm");
unitsMap.set("ug-m3", "µg/m³");
unitsMap.set("mg-m3", "mg/m³");
unitsMap.set("celsius", "ºC");
unitsMap.set("percentage", "%");
unitsMap.set("fahrenheit", "ºF");

unitsMap.set("mps", "m/s");
unitsMap.set("mph", "mph");
unitsMap.set("knots", "kn");
unitsMap.set("degrees", "º");

unitsMap.set("hPa", "hPa");

export const getVariableUnit = (variable, pollutantUnit,tempUnit)=>{

    if(variable === "pressure" || variable === "equivalentPressure"){
        return "hPa";
    }

    if(variable === "PM10" || variable === "PM1" || variable === "PM4" || variable === "PM2P5"){
        return  "ug-m3"
    }
    if(variable === "relativeHumidity" || variable ==="absoluteHumidity"){
        return "%"
    }

    if(variable === "noise"){
        return "dBA"
    }

    if(variable ==="temperature"){
        if(tempUnit === "celsius"){
            return "ºC"
        }else {
            return "ºF"
        }
    }

    if(variable === "NO2" ||
        variable === "O3"||
        variable === "NO" ||
         variable === "H2S" ||
        variable === "SO2"){
        if(pollutantUnit === "usa"){
            return "ppb"
        }else {
            return "µg/m³"
        }
    }

    if(variable === "CO" || variable ==="CO2"){
        if(pollutantUnit === "usa"){
            return "ppm"
        }else {
            return "mg/m³"
        }
    }

}
