import React from 'react';
import {Grid} from "@mui/material";
import StationTransferList from "../../../../../common/StationTransferList";
import {useSelector} from "react-redux";

const AlarmConfigStep2 = ({selectedVariable,callback}) => {

    const { stations } = useSelector( state => state.dashboardUI );

    const filterStationsByPollutant = ()=>{
       return stations.filter(station => station.sensors.includes(selectedVariable))
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <StationTransferList initialSelected={[]}
                                     initialNotSelected={filterStationsByPollutant()}
                                     selectedCallback={callback}/>
            </Grid>
        </Grid>
    );
};

export default AlarmConfigStep2;
