import React from 'react';
import {Popover, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => {
    return {
        help:{
            background:"#424242",
            color:"#ffffff",
            padding:20
        }
    }
});

const HelpPopup = ({anchorEl,handleHelpClose,message, halfCard = false}) => {

    const classes = useStyles();

    return (
            <Popover
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClose={handleHelpClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: !halfCard ? 'right' : "center",
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                PaperProps={{
                    style: { width: '25%' },
                }}
            >
                <Typography variant={"subtitle1"} className={classes.help}>{message}</Typography>
            </Popover>
    );
};

export default HelpPopup;
