import type from './action_types/type'
import { setRequestError} from "./requestUIReducer";

const initialState={
    name:'',
    lastName:'',
    email:'',
    id:'',
    units:{
        pollutants: '',
        temperature:'',
        index: '',
        windSpeed:''
    },
    organization:{
        name:'',
        id:'',
        map:{
            center:{
                lat:0.0,
                lon:0.0
            },
            zoom:4
        }
    }
    ,
    signedIn:false
}

export const authReducer = (state = initialState, action) =>{
    switch (action.type){
        case type.login:
            return {...action.payload}
        case type.logout:
            return {...initialState}
        case type.updateProfile:
            return {...state,...action.payload}
        default:
            return state;
    }
};

export const expiredSession = (path)=>{
    localStorage.setItem('lastPath',path);
    return (dispatch)=>{
        dispatch(logoutAction());
        dispatch(setRequestError("Session expired"));
    }
}

export const loginAction = function ({name,lastName, mail,id,units,organization}){
    return {
        type:type.login,
        payload:{name,lastName,email:mail,id,signedIn:true,units,organization}
    };
}

export const logoutAction = function (){
    return {
        type:type.logout
    };
}

export const updateProfileAction = function(name,lastName,units){
    return {
        type:type.updateProfile,
        payload:{name,lastName,units}
    };
};
