
export const dateToReadableDateTimeString = (date)=>{
    return dateToTimeString(date) +
        " "+dateToReadableUTCString(date)
}

const dateToTimeString = (date) =>{
    return `${date.getUTCHours()<10?`0${date.getUTCHours()}`:
        `${date.getUTCHours()}`}:${date.getUTCMinutes()<10?`0${date.getUTCMinutes()}`:
        `${date.getUTCMinutes()}`}:${date.getUTCSeconds()<10?`0${date.getUTCSeconds()}`:
        `${date.getUTCSeconds()}`}`
}

export const dateToReadableUTCString = (date)=>{
    return `${date.getUTCDate()<10?`0${date.getUTCDate()}`:
        date.getUTCDate()}/${date.getUTCMonth()<9?`0${date.getUTCMonth()+1}`:
        date.getUTCMonth()+1}/${date.getUTCFullYear()}`
}
