import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const windDataRequest = (windUnits,stationId,init,end,callback) => {
    let params = end ? `?windSpeed=${windUnits}&time=${init}&endtime=${end}` : `?windSpeed=${windUnits}&time=${init}`
    let url = `/station-data/${stationId}/wind-data/${params}`

    webClient.get(url)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        })
};