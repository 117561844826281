import React, {useCallback, useEffect, useRef, useState} from "react";
import theme from "../../theme/bettairTheme";
import {BrowserRouter as Router} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import {profileRequest} from "../../requests/profile/profileRequest";
import LoadingFullScreen from "../common/LoadingFullScreen";
import {useDispatch, useSelector} from "react-redux";
import {expiredSession, loginAction} from "../../reducers/authReducer";
import {HOME_ROUTE, LOGIN_ROUTE} from "../../constants";
import ServerErrorScreen from "../common/ServerErrorScreen";
import {stationRequest} from "../../requests/stationsRequests";
import {updateAQIAction, updateStationsAction} from "../../reducers/dashboardUIReducer";
import {useSnackbar} from "notistack";
import {downloadConfigRequest} from "../../requests/downloads/downloadConfigRequest";
import {setDownloadListAction} from "../../reducers/downloadReducer";
import {webSocket} from "../../websocket/webSocket";
import * as Sentry from "@sentry/react";
import {downloadReportListRequest} from "../../requests/report/downloadReportListRequest";
import {useMountComponent} from "../../hooks/useMountComponent";
import {setReportListAction} from "../../reducers/reportReducer";
import TopLevelSwitch from "./TopLevelSwitch";
import WebSockedError from "./WebSockedError";


export const TopLevelRoutesTree = () => {

    const isMounted = useMountComponent();
    const dispatch = useDispatch()
    const [{checkState, serverError, isSocketConnected}, updateState] = useState({
        checkState: false,
        serverError: false,
        isSocketConnected: false,
    })
    const {signedIn} = useSelector(state => state.auth);
    const {enqueueSnackbar} = useSnackbar();
    const {units} = useSelector(state => state.auth);
    let webSockedRef = useRef(null)
    let updateDataCallbackRef = useRef(null)


    const sockedFailToConnectCallback = useCallback(() => {
        enqueueSnackbar(`Fail to connect websocket`, {variant: "error"});
    }, [enqueueSnackbar])

    const webSocketStatusCallback = useCallback((isConnected) => {
        updateState(state => ({...state, isSocketConnected: isConnected}))
    }, []);

    const downloadReportList = useCallback(() => {
        downloadReportListRequest((err, data) => {
            if (!isMounted.current) {
                return
            }
            if (!err && data) {
                if (data.length > 0) {
                    dispatch(setReportListAction(data));
                }
            }
            if (err) {
                if (data.status === 401) {

                    expiredSession(HOME_ROUTE)(dispatch)
                } else {
                    enqueueSnackbar(`Error ${data.status},
                         Fetching report list`, {variant: "error"});
                }
            }
        })
    }, [enqueueSnackbar, dispatch, isMounted])

    const downloadDownloadsConfig = useCallback(() => {
        downloadConfigRequest((err, data) => {
            if (!isMounted.current) {
                return
            }
            if (!err && data) {
                if (data.length > 0) {
                    dispatch(setDownloadListAction(data));
                }
            }
            if (err) {
                if (data.status === 401) {
                    expiredSession(HOME_ROUTE)(dispatch)
                } else {
                    enqueueSnackbar(`Error ${data.status},
                         Fetching download list`, {variant: "error"});
                }
            }
        })
    }, [ isMounted, dispatch, enqueueSnackbar])


    const updateDataCallback = useCallback((type, data) => {
        switch (type) {
            case "download":
                downloadDownloadsConfig()
                break;
            case "report":
                downloadReportList()
                break;
            case "index":
                dispatch(updateAQIAction(data, units.index))
                break;
            case "alarms":
                break;
            default:
                Sentry.captureMessage(`Error, updateDataCallback called with invalid type ${type}`);
        }
    }, [units.index, dispatch, downloadReportList, downloadDownloadsConfig])

    useEffect(() => {
        updateDataCallbackRef.current = updateDataCallback
    }, [updateDataCallback])

    useEffect(() => {
        if (signedIn) {
            updateDataCallbackRef.current = updateDataCallback
            webSocket(dispatch, webSockedRef, sockedFailToConnectCallback, updateDataCallbackRef, webSocketStatusCallback);
        } else {
            if (webSockedRef.current !== null) {
                webSockedRef.current.close(1000)
            } else {
                Sentry.captureMessage("Error, web socked close function ref is null");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signedIn]);


    useEffect(() => {
        document.getElementById('splash-screen').style.display = 'none';
        downloadCurrentUseProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
            if (signedIn) {
                downloadStations(units.index);
                downloadDownloadsConfig();
                downloadReportList();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [units.index]);

    const downloadStations = useCallback((index) => {
        stationRequest(index, (err, data) => {
            if (!err && data) {
                if (data.length > 0) {
                    dispatch(updateStationsAction(data));
                }
            }
            if (err) {
                if (data.status === 401) {
                    expiredSession(HOME_ROUTE)(dispatch)
                } else {
                    enqueueSnackbar(`Error ${data.status},
                         Downloading sensors.`, {variant: "error"});
                }
            }
        })
    }, [dispatch, enqueueSnackbar]);

    const downloadCurrentUseProfile = useCallback(() => {
        profileRequest((err, data) => {
            if (err && data.status === 500) {
                return updateState(state => ({...state, checkState: true, serverError: true}))
            }
            if (!err) {
                localStorage.removeItem('lastPath');
                dispatch(loginAction(data));
            }else{
                let arr = window.location.href.split("/");
                let path = `/${arr.slice(3,arr.length).join("/")}`;
                if(path !== LOGIN_ROUTE && !path.includes("register")){
                    localStorage.setItem('lastPath',path);
                }else{
                    localStorage.removeItem('lastPath');
                }
            }
            updateState(state => ({...state, checkState: true, serverError: false}));
        })
    }, [dispatch]);


    return (<ThemeProvider theme={theme}>
        {checkState &&
            <Router>
                <TopLevelSwitch signedIn={signedIn}/>
            </Router>
        }
        {!isSocketConnected && signedIn && <WebSockedError/>}
        <LoadingFullScreen loading={!checkState}/>
        {serverError && <ServerErrorScreen/>}
    </ThemeProvider>);
}

