import React, {useCallback, useEffect, useState} from 'react';
import {
    Card,
    Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {sensorLogRequest} from "../../../../../requests/analytics/logDataRequest";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ANALYTICS_ROUTE} from "../../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import CardLoading from "../common/CardLoading";
import {dateToReadableDateTimeString} from "../../../../../utils/dateToReadableDateTimeString";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    cardTittle:{
        marginLeft:20,
        color:"gray"
    },
    table:{
        paddingTop:10,
        paddingRight:15,
        paddingLeft:15,
        paddingBottom:0

    },
    container:{
        maxHeight:190
    }
});

const LogCardView = ({className}) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const isMounted = useMountComponent();
    const { selectedStation } = useSelector( state => state.dashboardUI );

    const [{log,loading},updateState] = useState({log:[],loading:false});


    useEffect(()=>{
        updateLog();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedStation]);

    const updateLog= useCallback(()=>{
        updateState(state =>{
            return {...state,loading: true}
        });
        sensorLogRequest(selectedStation,(err,data)=>{
            if(!isMounted.current){return}
            if(!err){
                    let list = data.logs.map((item,index) =>{
                        return {date:new Date(item.timestamp),value:item.message, id:index}
                    })
                    updateState(state => {
                       return {...state,log:list,loading: false}
                    });
            }
            else {
                updateState(state =>{
                    return {...state,loading: false}
                });
                if(data.status === 401){
                    expiredSession(ANALYTICS_ROUTE)(dispatch)
                }else {
                    enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("analyticScreen.deviceLog.could_not_update_log")}`,{ variant:"error" });
                }
            }
        })
    },[t,dispatch,enqueueSnackbar,isMounted,selectedStation]);

    const classes = useStyles();

    return (
        <Card className={className}>
            { !loading &&
            <Grid container>
                <Grid container item xs={12} alignItems={"center"}>
                    <Typography className={classes.cardTittle} variant={"h6"}>{t("analyticScreen.deviceLog.sensor_log")}</Typography>
                </Grid>
                <Grid item xs={12}  className={classes.table}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="event table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">{t("analyticScreen.deviceLog.date")}</TableCell>
                                    <TableCell align="left">{t("analyticScreen.deviceLog.event")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {log.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell
                                                   align="left">{dateToReadableDateTimeString(item.date)}</TableCell>
                                        <TableCell  align="left">{item.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {log.length === 0 && <Typography
                        data-testid={"empty-message"}
                        variant={"h6"}
                        style={{width:"100%",marginTop:65,
                            color:"#e0e0e0",textAlign:"center"}}>{t("analyticScreen.deviceLog.empty")}</Typography>}
                </Grid>
            </Grid>}
            {loading && <CardLoading/>}
        </Card>
    );
};

export default LogCardView;
