import React, {useCallback, useState} from 'react';
import {Backdrop, Card, Divider, Grid, Typography} from "@mui/material";
import StationTransferList from "../../../../common/StationTransferList";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import { useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    card:{
        paddingTop:16,
        paddingBottom:10,
    },
    tittle:{
        marginLeft:30,
        color:"#434343",
        marginBottom:16
    },
    buttons:{
        marginRight:30,
        display:"flex",
        marginTop:10,
        justifyContent:"flex-end"
    },
    divider:{
        marginBottom:32
    },
    divider2:{
        marginTop:32
    },
    transferContainer:{
        justifyContent:"center"
    }
});

const CompareStationSelectorPopUp = ({open,handleClose}) => {

    const {t} = useTranslation()
    const { stations,selectCompareStations } = useSelector( state => state.dashboardUI );
    const [{transferCurrentSelection}, updateSelectedStations] = useState({selectedStations:selectCompareStations})
    let history = useHistory();

    const updateSelectedStationsCallback = useCallback((selectedStations)=>{
        updateSelectedStations({transferCurrentSelection:selectedStations})
    },[updateSelectedStations]);

    const classes = useStyles();

    const notSelectedStations = useCallback(()=>{
        let stationsNotSelected = stations.filter( station => {
            return !selectCompareStations.includes(station)
        })

        return stationsNotSelected
    },[stations,selectCompareStations])


    const handleSelected = ()=>{
        let query = ""
        transferCurrentSelection.forEach((value,index) =>{

            if(index === 0){
                query = query +`?id=${value.id}`
            }else{
                query = query+`&id=${value.id}`
            }
        })
        history.push(`/compare/${query}`);
        handleClose()
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <Card className={classes.card}>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid item xs={12}>
                    <Typography variant={"h6"} className={classes.tittle}>{t("dashboard.select_stations")}</Typography>
                    <Divider className={classes.divider}/>
                </Grid>
                <Grid container className={classes.transferContainer} item xs={12}>
                    { (stations?.length>0) && <StationTransferList
                        initialNotSelected={notSelectedStations()}
                                             initialSelected={selectCompareStations}
                    selectedCallback={updateSelectedStationsCallback}
                    />}
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider2}/>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.buttons}>
                        <Button onClick={handleClose}>{t("cancel")}</Button>
                        <Button onClick={handleSelected}>{t("save")}</Button>
                    </div>
                </Grid>
            </Grid>
            </Card>
        </Backdrop>
    );
};

export default CompareStationSelectorPopUp;
