const variableMaxValueMap = new Map();
variableMaxValueMap.set("PM1", 500);
variableMaxValueMap.set("PM10", 500);
variableMaxValueMap.set("PM4", 500);
variableMaxValueMap.set("PM2P5", 500);
variableMaxValueMap.set("O3", 500);
variableMaxValueMap.set("NO", 1000);
variableMaxValueMap.set("NO2", 1000);
variableMaxValueMap.set("SO2", 1000);
variableMaxValueMap.set("H2S", 500);
variableMaxValueMap.set("CO", 50);
variableMaxValueMap.set("CO2", 2000);
variableMaxValueMap.set("relativeHumidity", 100);
variableMaxValueMap.set("temperature", 140);


export const getVariableMaxValue = (variable)=>{

    const value = variableMaxValueMap.get(variable)
    return value
}