import React from 'react';
import {CircularProgress, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    loading: {
        alignSelf:"center",
        marginBottom:10
    }
});

const VerifyingDevice = ({className}) => {

    const {t} = useTranslation();
    const classes = useStyles();
    return (
        <Grid className={className}
              container
              direction={"column"}
              item xs={12}
              justifyContent={"center"}
              alignContent={"center"}
        >
            <CircularProgress className={classes.loading}/>
            <Typography variant={"h3"}>{t("analyticScreen.verifying_device")}</Typography>
        </Grid>

    );
};

export default VerifyingDevice;
