import React, {useCallback, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Button, Card, CardContent, Grid, Typography} from "@mui/material";
import CardLoading from "../analytics/common/CardLoading";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGrid} from "@mui/x-data-grid";
import {dateToReadableDateTimeString} from "../../../../utils/dateToReadableDateTimeString";
import {getVariableName} from "../pollutantNames";
import ConfirmDialog from "../../../common/ConfirmDialog";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getTriggerName} from "../triggerNames";
import {unitsMap} from "../unitsNames";
import {expiredSession} from "../../../../reducers/authReducer";
import {ALARM_ROUTE} from "../../../../constants";
import {useMountComponent} from "../../../../hooks/useMountComponent";
import {useSnackbar} from "notistack";
import {deleteNotificationRequest} from "../../../../requests/alarms/notifications/deleteNotificationRequest";

const useStyles = makeStyles({
    cardTittle:{
        marginLeft:20,
        color:"#000000",
        fontWeight:"bold"
    },
    deleteButton:{
        color:"#ffffff",
        background:"#f6343d",
        '&:hover': {
            background:"#81191c"
        },
    },
    rowTable:{
        display:"flex",
        width:"100%vw",
        justifyContent:"center",
        marginTop:32
    },
    table:{
        maxWidth:1600,
        height: 480,
        '& .super-app.current_user': {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {
        },
    }
});

const NotificationsCardView = ({className}) => {

    const{t} = useTranslation()
    const dispatch = useDispatch()
    const isMounted = useMountComponent();
    const { notifications } = useSelector( state => state.notification );
    const { alarms } = useSelector( state => state.alarm );
    const { enqueueSnackbar } = useSnackbar();
    const { stations } = useSelector( state => state.dashboardUI );
    const [{enableDelete,selectionModel,loading,openConfirmDialog},updateState] =
        useState({selectionModel:[],openConfirmDialog:false,
            enableDelete:false,data:[],loading:false,selectedDate:new Date(),error:false});

    const hideDialog = useCallback(()=>{
        updateState(state => ({...state,openConfirmDialog: false}))
    },[]);

    const handleDeleteButtonPressed = ()=>{
        updateState(state =>({...state,openConfirmDialog: true}))
    }

    const onSelectionModelChange = (newSelectionModel)=>{
        updateState(state =>{
            return {...state,enableDelete:(newSelectionModel.length !== 0),
                selectionModel:newSelectionModel}
        })
    }

    const confirmDelete = useCallback(()=>{
        updateState(state => ({...state,loading: true, openConfirmDialog: false }))
        Promise.all(deleteNotificationRequest(selectionModel)).then(() => {
            if (!isMounted.current) {return}
            updateState(state => ({...state,loading: false,selectionModel:[],enableDelete:false }))
            enqueueSnackbar(`${t("alarmScreen.notification_deleted")}`,{ variant:"success" });
        }).catch(err => {
            if (!isMounted.current) {return}
            updateState(state => ({...state,loading: false,selectionModel:[],enableDelete:false}))
            if(err.response){
                switch (err.response.status){
                    case 401:
                        expiredSession(ALARM_ROUTE)(dispatch)
                        break;
                    default:
                        enqueueSnackbar(`${t("error")} ${err.response.status},
                         ${t("alarmScreen.deleting_notification")}`,{ variant:"error" });
                        break;
                }
            }
        });
    },[t,dispatch,selectionModel,enqueueSnackbar,isMounted])

    const columns = [
        { field: 'id', headerName: 'ID',  hide:true },
        { field: 'stationId', headerName: t("alarmScreen.station") , width: 140,
            renderCell: (params) => (
                <Typography>{ stations.find(station => station.id === params.value)?.alias}</Typography>
            )
        },
        { field: 'alarmId', headerName: t("alarmScreen.sensor"),width: 140,
             renderCell: (params) => (
                 <Typography>{ getVariableName(alarms.find(alarm => alarm.id === params.value)?.pollutant)}</Typography>
             )
         },
        {field: 'trigger', headerName: t("alarmScreen.trigger"),width: 200,
            renderCell: (params) => (
                <Typography>{ getTriggerName(alarms.find(alarm => alarm.id === params.row.alarmId)?.trigger,t)}</Typography>
            )
        },
       { field: 'threshold', headerName: t("alarmScreen.threshold"), width: 180,
           renderCell: (params) => (
               <Typography>{ `${alarms.find(alarm => alarm.id === params.row.alarmId)?.value} 
               ${ unitsMap.get(alarms.find(alarm => alarm.id === params.row.alarmId)?.units)}`}</Typography>
           )},
       { field: 'value', headerName: t("alarmScreen.notifications.value"), width: 180,
           renderCell: (params) => (
            <Typography>{ `${params.value} 
               ${ unitsMap.get(alarms.find(alarm => alarm.id === params.row.alarmId)?.units)}`}</Typography>)
       },
        { field: 'time', headerName: t("alarmScreen.notifications.date"), width: 300,
            renderCell: (params) => (
                <Typography>{ dateToReadableDateTimeString(new Date(params.value))}</Typography>
            )
        }
    ];

    const classes = useStyles();

    return (
        <>
            {openConfirmDialog && <ConfirmDialog hideDialog={hideDialog} tittle={t("alarmScreen.notifications.delete_notification")}
                           question={t("alarmScreen.notifications.are_you_sure_to_delete")}
                           confirm={confirmDelete}/>}

        <Card className={className}>
            {!loading &&
            <CardContent>
                <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid container  item xs={12} sm={12} md={8} lg={9} alignItems={"flex-start"} alignContent={"flex-start"}>
                        <Typography className={classes.cardTittle} variant={"h5"}>{t("alarmScreen.notifications.notifications")}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Button
                            fullWidth
                            disabled={loading || !enableDelete }
                            variant="contained"
                            onClick={handleDeleteButtonPressed}
                            className={classes.deleteButton}
                            startIcon={<DeleteIcon />}
                        >
                            {t("delete")}

                        </Button>
                    </Grid>
                    <Grid item xs={12} className={classes.rowTable} >
                        <DataGrid
                            className={classes.table}
                            rows={notifications}
                            columns={columns}
                            columnBuffer = {8}
                            pageSize={7}
                            Name="dataGrid2"
                            selectionModel={selectionModel}
                            onSelectionModelChange={onSelectionModelChange}
                            checkboxSelection  density={"standard"}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            }
            {loading &&
            <CardLoading/>
            }
        </Card>
        </>
    );
}

export default NotificationsCardView;
