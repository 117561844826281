import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import { useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';

import {TextField} from "@mui/material";
import {useTranslation} from "react-i18next";


const getOptions = (stations)=> {
    return stations.map(value =>{
        return {label:value.alias,id:value.id}
    })
}

const getValue = (options,selectedStation)=>{
   return options.find(value => {return value.id === selectedStation})
}

const useStyles = makeStyles({
    select: {
        width:140,
        color:"white",
        marginRight :20,
        '&:before': {
            borderColor: "white",
        },
        '&:after': {
            borderColor: "white",
        }
    },
    icon: {
        fill: "white",
    },
    root: {
        "& .MuiFormLabel-root": {
            color: "white",
        },
        "& .MuiInput-input": {
            color: "white"
        },
        "& .MuiButtonBase-root": {
            color: "white"
        },
        "& .MuiInputBase-root.MuiInput-root:after": {
            borderBottom: "2px solid #ffffff"
        },
        "& .MuiInputBase-root.MuiInput-root:before":{
            borderBottom: "1px solid rgba(256, 256, 256, 0.72)"
        }
    }
});

const AnalyticsStationSelector = () => {

    const { t } = useTranslation();
    const { stations, selectedStation } = useSelector( state => state.dashboardUI );
    let history = useHistory();
    const [{value,options},updateState] = useState({value:null,options:[]})


    useEffect(()=>{
        const options = getOptions(stations);
        const value = getValue(options,selectedStation);
       updateState(state =>({...state,options: options,value: value}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[stations.length,selectedStation])


    const onSensorChange = (newValue)=>{
        if(newValue !== null){
            let sensorId = newValue.id
            history.push(`/analytics/${sensorId}`);//TODO default sensor
        }
    };

    const classes = useStyles();

    return (
        <Autocomplete
            onChange={(event, newValue) => onSensorChange(newValue)}
            className={classes.select}
                      value={value !== undefined ? value : null}
            disablePortal
            options={options}
            style={{ width: 200,marginRight:50 }}
            renderInput={(params) =>
                <TextField
                    variant="standard" className={classes.root} {...params} label={t("dashboard.select_station")} />}
        />
    );
};

export default AnalyticsStationSelector;
