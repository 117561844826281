import React from 'react';
import {CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    loadingContainer:{
        display:"flex",
        height:"100%",
        alignSelf:"center",
        alignItems:"center",
        justifyContent:"center",
    }
});

const CardLoading = () => {
    const classes = useStyles();
    return (
        <div className={classes.loadingContainer}><CircularProgress /></div>
    );
};

export default CardLoading;
