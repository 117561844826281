import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const downloadConfigRequest = (callback) => {
    webClient.get('/download')
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        })
};