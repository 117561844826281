import React, {useCallback, useEffect, useState} from 'react';
import {FormControl, Grid, Input, InputLabel, MenuItem, Select, Slider, TextField, Typography} from "@mui/material";
import PollutantToggleButtons from "../../../compare/compare_data_card/PollutantToggleButtons";
import {makeStyles} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {getAvailableSensors} from "../../../../../../utils/stationUtil";
import {getVariableUnit} from "../../../unitsNames";
import {useTranslation} from "react-i18next";
import {organizationRequest} from "../../../../../../requests/organization/organizationRequests";
import {expiredSession} from "../../../../../../reducers/authReducer";
import {ALARM_ROUTE} from "../../../../../../constants";
import {useMountComponent} from "../../../../../../hooks/useMountComponent";
import {useSnackbar} from "notistack";
import LoadingFullScreen from "../../../../../common/LoadingFullScreen";
import {getVariableMaxValue} from "../../alarmsMaxThresholdValues";

const useStyles = makeStyles({
    container:{
        maxWidth:620
    },
    formControl:{
        minWidth: 200,
    },
    pollutants:{
        width:620
    },
    trigger:{
        marginTop:40,
        marginBottom:40
    },
    triggerValue:{
        marginBottom:30
    },
    name:{
        marginBottom:40
    }
});

const AlarmConfigStep1 = ({selectedPollutant,pollutantCallback,
                              trigger,triggerCallback,
                              value,handleInputChange,
                              handleSliderChange,handleBlur,
                              name,nameCallback,cancelCallback,
                              organizationUnits,unitsCallback

}) => {

    const {t} = useTranslation()
    const { stations } = useSelector( state => state.dashboardUI );
    const stationSensors =  getAvailableSensors(stations).map(sensor => sensor.id)
    const isMounted = useMountComponent();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const [{loading},updateState] = useState({loading:true})

    useEffect(()=>{
        if(stationSensors.length>0){
            pollutantCallback(stationSensors[0])
        }
        getOrganizationUnits()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    useEffect(()=>{
        const maxValue = getVariableMaxValue(selectedPollutant)
        if(value>maxValue){
            handleInputChange({target:{value:maxValue}})
        }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[selectedPollutant])

    const getOrganizationUnits = useCallback(()=>{
        organizationRequest((error,data)=> {
            if (!isMounted.current) {return}
            if (!error) {
                unitsCallback(data.units)
                updateState(state => {
                    return {...state, organizationUnits: data.units,loading:false}
                })
            } else {
                switch (data.status){
                    case 401:
                        expiredSession(ALARM_ROUTE)(dispatch)
                        break;
                    default:
                        updateState(state => {
                            return {...state, organizationUnits: {} ,loading:false}})
                        enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("organizationUnit.could_not_fetch_organization_unit")}`,{ variant:"error" });
                        cancelCallback()
                        break;
                }
            }
        });
    },[unitsCallback,cancelCallback,t,enqueueSnackbar,dispatch,isMounted]);

    const classes = useStyles();

    return (
        <>
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <TextField
                    disabled={loading}
                    className={classes.name}
                    autoFocus = {true} label={t("alarmScreen.popUp.name")} variant="outlined"
                    required fullWidth  margin="normal"
                    onChange={nameCallback} value={name}
                />
            </Grid>

            <Grid item xs={12} className={classes.pollutants}>
                <PollutantToggleButtons pollutantList={stationSensors}
                                        pollutantCallback={pollutantCallback}
                                        selectedPollutant={selectedPollutant}/>
            </Grid>

            <Grid item xs={12} className={classes.trigger}>
                <FormControl variant="standard" className={classes.formControl}>
                    <InputLabel >{t("alarmScreen.trigger")}</InputLabel>
                    <Select
                        disabled={loading}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={trigger}
                        onChange={triggerCallback}
                        label={t("alarmScreen.trigger")}
                    >
                        <MenuItem value={"biggerThan"}>{t("alarmScreen.popUp.value_gratter_theshold")}</MenuItem>
                        <MenuItem value={"biggerEqual"}>{t("alarmScreen.popUp.value_gratter_equal_theshold")}</MenuItem>
                        <MenuItem value={"lowerThan"}>{t("alarmScreen.popUp.value_smaller_threshold")}</MenuItem>
                        <MenuItem value={"lowerEqual"}>{t("alarmScreen.popUp.value_smaller_equal_threshold")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.triggerValue}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant={"subtitle2"} gutterBottom>
                            {t("alarmScreen.threshold")}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Slider
                            step={0.1}
                            value={typeof value === 'number' ? value : 0}
                            onChange={handleSliderChange}
                            aria-labelledby="input-slider"
                            max= {getVariableMaxValue(selectedPollutant)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            disabled={loading}
                            value={typeof value === 'number' ? value : 0}
                            size="small"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                                step: 0.1,
                                min: 0,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        {!loading && !organizationUnits.pollutants !== undefined &&
                        !organizationUnits.temperature !== undefined &&<Typography>{
                            getVariableUnit(selectedPollutant,
                                organizationUnits.pollutants,
                                organizationUnits.temperature)}
                        </Typography>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
            {loading && <LoadingFullScreen loading={loading}/>}
        </>
    );
};

export default AlarmConfigStep1;
