import React from 'react';
import {CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => {
    return {
        notFound:{
            display:"flex",
            height:"100%",
            width:"100%",
            justifyContent:"center",
            alignContent:"center",
            alignItems:"center",
        },
    }
});

const ChartLoading = () => {
    const classes = useStyles();
    return (
        <div className={classes.notFound}>
            <CircularProgress  />
        </div>
    );
};

export default ChartLoading;
