import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {pollutantNames} from "../../pollutantNames";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => {
    return {
        container:{
            maxHeight:450
        },
    }
});

const DaysOverThresholdTable = ({daysOverWho}) => {

    const {t} = useTranslation()

    const classes = useStyles()
    return (
        <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="event table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">{t("analyticScreen.daysOverThreshold.pollutant")}</TableCell>
                        <TableCell align="right">{t("analyticScreen.daysOverThreshold.last_year")}</TableCell>
                        <TableCell align="right">{t("analyticScreen.daysOverThreshold.last_30_days")}</TableCell>
                        <TableCell align="right">{t("analyticScreen.daysOverThreshold.last_7_days")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {daysOverWho[0].map((item,index) => (
                        <TableRow key={index}>
                            <TableCell
                                align="left"> {pollutantNames.get(item)}</TableCell>
                            <TableCell  align="right">{daysOverWho[1][index]}</TableCell>
                            <TableCell  align="right">{daysOverWho[2][index]}</TableCell>
                            <TableCell  align="right">{daysOverWho[3][index]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DaysOverThresholdTable;
