import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import BettairApp from "./BettairApp";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


Sentry.init({
    dsn: "https://2a04cae62d0c4e97ab57c33b496bcd2a@sentry.bettair.city/9",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
    environment: `${process.env.REACT_APP_ENVIRONMENT}_release`,
    release: `client-front@${process.env.REACT_APP_VERSION}`
});


ReactDOM.render(
    <BettairApp />
, document.getElementById('root')
);
