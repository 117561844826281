import moment from "moment-timezone";

export const toLocalTimeZone = (data) => {
    const dataCopy = [...data]
    return dataCopy.map((dataSet,dataSetIndex) => {
        const xCopy = [...dataSet.x]
        return {...dataSet,
            x: xCopy.map(item => utcToLocalTimeDate(item))}
    })
}

export const copyVisibility = (origin,destination)=>{
   let output = [...destination]
    let newOutput =output.map((item,index) =>{
        let visible = origin.find(element => element.name === item.name).visible
        return {...item,visible:visible}
    })
    return newOutput
}

const utcToLocalTimeDate = (date) => {
    let t = moment.tz(date, moment.tz.guess());
    let newDate = (new Date(Date.UTC(t.year(), t.month(), t.date(), t.hour(), t.minute(), t.second(), t.millisecond())))
    return newDate.toISOString();
}