import React, {useCallback, useEffect, useState} from 'react';
import {
    Button,
    Card,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ORGANIZATION_ROUTE} from "../../../../../constants";
import {useDispatch} from "react-redux";
import HelpPopup from "../../analytics/common/HelpPopup";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {organizationRequest, updateUnitsRequest} from "../../../../../requests/organization/organizationRequests";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useSnackbar} from "notistack";
import CardViewLoading from "../../analytics/common/ChartLoading";
import DataNotFound from "../../../../common/DataNotFound";
import CommonDialog from "../../../../CommonDialog";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    saveButton:{
        color:'#ffffff',
    },
    form:{
        width:"100%",
    },
    tittle:{
        fontWeight:"bold"
    },
    grip:{
        marginTop:12,
    },
    helpButton:{
        color:"black"
    }
});

const OrganizationUnits = ({className}) => {

    const { t } = useTranslation();
    const [{organizationUnits,loading,enableSave,error,openWarningDialog}, updateState] = useState({
        organizationUnits: {},loading:true,error:"",enableSave:true,openWarningDialog:false
    });
    const isMounted = useMountComponent();
    const { enqueueSnackbar } = useSnackbar();
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const dispatch = useDispatch();

    const classes = useStyles();

    useEffect(()=>{
        updateData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleIndexChange = (event) =>{
        updateState(state =>(
            {...state,organizationUnits:{...state.organizationUnits,index:event.target.value}}))
    }

    const handleWindSpeedChange = (event) =>{
        updateState(state =>(
            {...state,organizationUnits:{...state.organizationUnits,windSpeed:event.target.value}}))
    }

    const handleTempChange = (event) =>{
        updateState(state =>(
            {...state,organizationUnits:{...state.organizationUnits,temperature:event.target.value}}))
    }

    const handlePollutantsChange = (event) =>{
        updateState(state =>(
            {...state,organizationUnits:{...state.organizationUnits,pollutants:event.target.value}}))
    }

    const updateData = useCallback(()=>{
        organizationRequest((error,data)=> {
            if (!isMounted.current) {return}
            if (!error) {
                updateState(state => {
                    return {...state, organizationUnits: data.units,loading:false,error:  ""}
                })
            } else {
                switch (data.status){
                    case 401:
                        expiredSession(ORGANIZATION_ROUTE)(dispatch)
                        break;
                    default:
                        updateState(state => {
                            return {...state, organizationUnits: null ,loading:false,error: t("organizationUnit.could_not_fetch_organization_unit")}
                        })
                        enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("organizationUnit.could_not_fetch_organization_unit")}`,{ variant:"error" });
                        break;
                }
            }
        });
    },[t,enqueueSnackbar,dispatch,isMounted]);

    const handleWarningDialogClose = useCallback(()=>{
        updateState(state=>({...state,openWarningDialog: false}))
    },[])

    const handleSave = ()=>{

        updateUnitsRequest({...organizationUnits },(error,data) =>{
            updateState(state =>({...state,enableSave: false}))
            if (!isMounted.current) {return}

            if(!error){
                updateState(state =>({...state,enableSave: true,openWarningDialog:true}))
                enqueueSnackbar(t("organizationUnit.units_updated"),{ variant:"success" });
            }else {
                switch (data.status){
                    case 401:
                        expiredSession(ORGANIZATION_ROUTE)(dispatch)
                        break;
                    default:
                        updateState(state => {
                            return {...state ,loading:false,enableSave: true}
                        })
                        enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("organizationUnit.updating_organization_units")}`,{ variant:"error" });
                        break;
                }
            }
        })
    }

    return (
        <>
            <Card className={className} >
                {loading && <CardViewLoading/>}
                {!loading && error === "" &&  <div style={{position:"relative",width:"100%"}}><form  noValidate className={classes.form} >
                    <Grid className={classes.grip} container direction={"row"} spacing={2}>
                        <Grid item container xs={12}  >
                            <Typography className={classes.tittle} variant={"h4"}>{t("organizationUnit.title")}</Typography>
                            <IconButton className={classes.helpButton} aria-label="help"
                                        onClick={e => setAnchorEl(e.currentTarget)}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Grid>

                        <Grid item xs={12} md={6} >
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                                <InputLabel >{t("units.pollutant_index")}</InputLabel>
                                <Select
                                    disabled={!enableSave}
                                    onChange={handleIndexChange}
                                    label={t("units.pollutant_index")}
                                    value={organizationUnits.index}
                                    data-testid = "profile-pollutant-index"
                                    labelId="demo-simple-select-label"
                                    id="profile-selector-pollutant"
                                >
                                    <MenuItem value={"aqi"}>AQI</MenuItem>
                                    <MenuItem value={"caqi"}>CAQI</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                                <InputLabel>{t("units.concentration_units")}</InputLabel>
                                <Select
                                    disabled={!enableSave}
                                    label={t("units.concentration_units")}
                                    onChange={handlePollutantsChange}
                                    data-testid = "profile-concentration"
                                    value={organizationUnits.pollutants}
                                    labelId="demo-simple-select-label"
                                    id="profile-selector-concentration"
                                >
                                    <MenuItem value={"eu"}>µg/m³ - mg/m³</MenuItem>
                                    <MenuItem value={"usa"}>{t("units.ppm_ppb")}</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                                <InputLabel>{t("units.temperature_units")}</InputLabel>
                                <Select
                                    disabled={!enableSave}
                                    label={t("units.temperature_units")}
                                    onChange={handleTempChange}
                                    data-testid = "profile-temperature"
                                    value={organizationUnits.temperature}
                                    labelId="demo-simple-select-label"
                                    id="profile-selector-temperature"
                                >
                                    <MenuItem value={"celsius"}>Celsius (ºC)</MenuItem>
                                    <MenuItem value={"fahrenheit"}>Fahrenheit (ºF)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} >
                            <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                                <InputLabel>{t("units.wind_speed_unit")}</InputLabel>
                                <Select
                                    disabled={!enableSave}
                                    label={t("units.wind_speed_unit")}
                                    onChange={handleWindSpeedChange}
                                    data-testid = "profile-wind"
                                    value={organizationUnits.windSpeed}
                                    labelId="demo-simple-select-label"
                                    id="profile-selector-wind"
                                >
                                    <MenuItem value={"mps"}>{t("units.meter_per_second")}</MenuItem>
                                    <MenuItem value={"mph"}>{t("units.mile_per_hour")}</MenuItem>
                                    <MenuItem value={"knots"}>{t("units.knots")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item md={8} lg={9}> </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Button
                                data-testid = "organzation-units-save-button"
                                className={classes.saveButton}
                                     disabled={!enableSave}
                                     color={"primary"} fullWidth
                                    onClick={handleSave} size={"large"} variant="contained">{t("save")}</Button>

                        </Grid>
                    </Grid>
                </form>
                    {!enableSave && <div style={{position:"absolute",top:0,
                        left: 0,width:"100%",height:"100%",
                        display:"flex", flexDirection:"row",alignSelf:"center",
                        justifyContent:"center"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>}
                </div>
                    }
                {!loading && error !== "" && <DataNotFound message={error}/>}

            </Card>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("helpMessages.en_organization_units")}/>
            {openWarningDialog && <CommonDialog
                title={t("warning")}
                handleClose={handleWarningDialogClose}
                description={t("organizationUnit.previous_report_keep_units")}/>}
        </>
    );
};

export default OrganizationUnits;
