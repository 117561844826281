
export const pollutantNames = new Map();
pollutantNames.set("NO2","NO₂");
pollutantNames.set("CO2","CO₂");
pollutantNames.set("CO","CO");
pollutantNames.set("O3","O₃");
pollutantNames.set("NO","NO");
pollutantNames.set("SO2","SO₂");
pollutantNames.set("PM1","PM₁");
pollutantNames.set("PM10","PM₁₀");
pollutantNames.set("PM4","PM4");
pollutantNames.set("PM2P5","PM₂․₅");
pollutantNames.set("temperature","Temp");
pollutantNames.set("relativeHumidity","RH");
pollutantNames.set("absoluteHumidity","AH");
pollutantNames.set("noise","Ambient Noise");
pollutantNames.set("H2S","H₂S");
pollutantNames.set("NH3","NH₃");
pollutantNames.set("VOC_IAQ","VOC IAQ");
pollutantNames.set("pressure","Pressure");
pollutantNames.set("equivalentPressure","Eq. Pressure");


pollutantNames.set("windSpeed","Wind Speed");
pollutantNames.set("windGust","Wind Gust");
pollutantNames.set("wdirection","Wind Direction");



export const getVariableName = (name)=>{
    let output = pollutantNames.get(name)
    return output !== undefined ? output : `Unhandled type ${name}`
}
