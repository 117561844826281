
export const pollutantColors = new Map();

pollutantColors.set("NO2","#D01A55");
pollutantColors.set("CO2","#BF1AD0");
pollutantColors.set("CO","#741AD0");
pollutantColors.set("O3","#1A23D0");
pollutantColors.set("NO","#1AD0AA");
pollutantColors.set("SO2","#40D01A");
pollutantColors.set("PM1","#D0B11A");
pollutantColors.set("PM10", undefined);
pollutantColors.set("PM4", undefined);
pollutantColors.set("PM2P5", undefined);
pollutantColors.set("temperature", "#FF2000");
pollutantColors.set("relativeHumidity","#00AAFF");
pollutantColors.set("absoluteHumidity", undefined);
pollutantColors.set("noise", undefined);
pollutantColors.set("H2S", undefined);
pollutantColors.set("VOC_IAQ", undefined);
pollutantColors.set("pressure","#000");
pollutantColors.set("equivalentPressure","#7B7B7B");
