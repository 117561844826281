import React from 'react';
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {getVariableName} from "../../pollutantNames";

const PollutantToggleButtons = ({pollutantList,pollutantCallback,selectedPollutant}) => {

        const handleAlignment = (event, newAlignment) => {
            if (newAlignment !== null) {
                pollutantCallback(newAlignment)
            }
        };

    return (
        <ToggleButtonGroup
            value={selectedPollutant}
            exclusive
            onChange={handleAlignment}
        >
            {pollutantList.map(pollutant => {
            return <ToggleButton
                        data-testid={"toggle-"+pollutant}
                        key={pollutant}
                        value={pollutant} >
                        {
                            getVariableName(pollutant)
                        }
                </ToggleButton>
            })}
        </ToggleButtonGroup>
    );
};

export default PollutantToggleButtons;
