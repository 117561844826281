import {dateToUTCServerFormatString} from "./dateToUtcStringConverter";
import {DAYS_IN_MS} from "../components/routes/private/analytics/calendar_card/CalendarComponent";

export const getDateRange =(currentPeriod,range)=>{
    if(currentPeriod > 0 ){
        let date = new Date(new Date() - DAYS_IN_MS * currentPeriod);
        return [dateToUTCServerFormatString(date),null];
    }else {
        return [dateToUTCServerFormatString(range.startDate),
            dateToUTCServerFormatString(new Date(range.endDate ))];
    }
}

export const getDateRangeIncludingEndDate =(currentPeriod,range)=>{
    if(currentPeriod > 0 ){
        let startDate = new Date(new Date() - DAYS_IN_MS * currentPeriod);
        let endDate = new Date()
        return [dateToUTCServerFormatString(startDate),dateToUTCServerFormatString(endDate)];
    }else {
        return [dateToUTCServerFormatString(range.startDate),
            dateToUTCServerFormatString(new Date(range.endDate.getTime()))];
    }
}