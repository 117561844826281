import React from 'react';
import {Link, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles({
    box: {
        background: "#f18484",
        borderRadius: 6,
        width: 240,
        padding: 16,
        position: "absolute",
        top: 80,
        left: 100,
        zIndex: 1999,
        boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
    }
});

const WebSockedError = () => {

    const {t} = useTranslation();

    const classes = useStyles();

    const handleOnClick = (event) => {
        event.preventDefault();
        const newWindow = window.open(`https://docs.bettair.city/${window.navigator.language.includes("es-ES") ? "es" : "en"}` +
            `/${window.navigator.language.includes("es-ES") ? "#websocket-desconectado" : "#websocket-disconnected"}`,
            '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (<div className={classes.box}>
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        }}>
            <ErrorIcon style={{color: "#fff"}}/>
            <Typography variant={"subtitle1"}
                        style={{
                            color: "#fff",
                            marginLeft: 8,
                            fontWeight: "bold"
                        }}>{t("websocket_disconnected")}</Typography>
        </div>
        <Link
            onClick={handleOnClick}
            style={{color: "#fff", marginLeft: 28, marginTop: 14, fontSize: 14}}>{t("more_info")}</Link>
    </div>)
};

export default WebSockedError;
