import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = (privateRouteProps) => {

    const {isAuthenticated,
        component: Component,
        ...rest} = privateRouteProps

    return (
        <Route { ...rest }
               render={ (props) => (
                ( isAuthenticated ) ? ( <Component { ...props } /> ) : ( <Redirect to="/login" /> )
            )}
        />
    )
};

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired
}


