import React from 'react';
import {Step, StepLabel, Stepper} from "@mui/material";
import {useTranslation} from "react-i18next";

const DownloadSteps = ({activeStep}) => {


    const {t} = useTranslation()

    const steps = [t("downloadScreen.popUp.step1"),t("downloadScreen.popUp.step2"), t("downloadScreen.popUp.step3")];

    return (
        <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                    <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};

export default DownloadSteps;
