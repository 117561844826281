import React, {useCallback} from 'react';
import StationTransferList from "../../../../common/StationTransferList";
import {getAvailableSensors} from "../../../../../utils/stationUtil";

const Step3 = ({selectedStations,initialSelection,callback}) => {

    const notSelectedStations = useCallback(()=>{
        return getAvailableSensors(selectedStations).filter(variable => {
            return !initialSelection.includes(variable)
        })
    },[selectedStations,initialSelection])


    return (
        <StationTransferList initialSelected={initialSelection}
                             initialNotSelected={notSelectedStations()}
                             selectedCallback={callback}/>
    );
};

export default Step3;
