import type from "./action_types/type";


const initialState={
    loading:false,
    error:null,
    success:null
}

export const requestUIReducer = (state = initialState, action) =>{
    switch (action.type){
        case type.startLoading:
            return {...state,loading:true}
        case type.stopLoading:
            return {...state,loading:false}
        case type.setRequestError:
            return {...state,error: action.payload.message}
        case type.removeRequestError:
            return {...state,error: null}
        case type.setSuccessRequest:
            return {...state,success: action.payload.message}
        case type.removeSuccessRequest:
            return {...state,success: null}
        default:
            return state;
    }
};

export const startLoadingAction = () =>({
    type:type.startLoading
})

export const stopLoadingAction = () =>({
    type:type.stopLoading
})

export const setRequestError = (error) =>({
    type:type.setRequestError,
    payload:{message:error}
})

export const removeRequestErrorAction = () =>({
    type:type.removeRequestError
})

export const setSuccessRequest = (message) =>({
    type:type.setSuccessRequest,
    payload:{message:message}
})

export const removeSuccessRequest = () =>({
    type:type.removeSuccessRequest
})


