import React, {useCallback, useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import DeviceDescriptionCardView from "./device_description_card/DeviceDescriptionCardView";
import LogCardView from "./device_log_card/LogCardView";
import SensorDataCardView from "./sensor_data_card/SensorDataCardView";
import PollutantTrendCardView from "./pollutant_trend_card/PollutantTrendCardView";
import DaysOverWhoThresholdCardView from "./days_over_who_threshold_card/DaysOverWhoThresholdCardView";
import clsx from "clsx";
import AQICalendarCardView from "./calendar_card/AQICalendarCardView";
import NoiseCardView from "./noise_card/NoiseCardView";
import SensorStatisticCardView from "./sensor_statistic_card/SensorStatisticCardView";
import {useMountComponent} from "../../../../hooks/useMountComponent";
import {useDispatch, useSelector} from "react-redux";
import {updateSelectedStationAction} from "../../../../reducers/dashboardUIReducer";
import DeviceNotFound from "./DeviceNotFound";
import {startLoadingAction, stopLoadingAction} from "../../../../reducers/requestUIReducer";
import VerifyingDevice from "./VerifyingDevice";
import {stationInfoRequest} from "../../../../requests/analytics/stationInfoRequest";
import {expiredSession} from "../../../../reducers/authReducer";
import {ANALYTICS_ROUTE} from "../../../../constants";
import WindSpeedCardView from "./wind_speed_card/WindSpeedCardView";
import VocIaqCardView from "./voc_iaq_card/VocIaqCardView";

const useStyles = makeStyles({
    root: {
        maxWidth: 1800,
        paddingBottom: 20,
        paddingTop: 20,
        paddingLeft: 40,
        paddingRight: 40,
    },
    card: {
        background: "white",
        padding: 20,
        borderRadius: 5,
        minHeight: 245
    },
    firstRowCard: {
        height: 250
    },
    sensorDataRowCard: {
        height: 760
    },
    vocDataRowCard: {
        height: 480
    },
    noiseDataRowCard: {
        height: 480
    },
    statisticRowCard: {
        height: 480
    },
    thirdRowCard: {
        height: 460
    },
    windSpeedCard: {
        height: 560
    },
    fourthRowCard: {
        height: 460
    },
    notFoundCard: {
        height: 600
    },
    loading: {
        height: 600
    },
    trendCard: {
        paddingLeft: 0,
        paddingRight: 0
    }
});

const AnalyticScreen = ({location}) => {

    let dispatch = useDispatch()
    let isMounted = useMountComponent();
    let [{isSensorIdValid, loading}, updateState] = useState({isSensorIdValid: false, loading: true});
    const {stations} = useSelector(state => state.dashboardUI);
    const {selectedStation} = useSelector(state => state.dashboardUI);
    const selectedStationData = stations?.find(item => item.id === selectedStation);


    useEffect(() => {
            checkSensorId(location);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [location]);

    const checkSensorId = useCallback((location) => {
        updateState({isSensorIdValid: false, loading: true})
        let sensorId = location.pathname.split("/")[2];
        dispatch(startLoadingAction())
        stationInfoRequest(sensorId, (err, data) => {
            dispatch(stopLoadingAction())
            if (isMounted.current) {
                if (!err) {
                    localStorage.setItem('lastStation', sensorId);
                    dispatch(updateSelectedStationAction(sensorId))
                } else {
                    if (data.status === 401) {
                        expiredSession(ANALYTICS_ROUTE)(dispatch)
                    } else {
                        dispatch(updateSelectedStationAction(""))
                    }
                }
                updateState({isSensorIdValid: !err, loading: false})
            }
        });
    }, [dispatch, isMounted]);

    const classes = useStyles();

    return (
        <Grid className={classes.root} container direction={"row"}
              alignItems={"center"} justifyContent="space-around" spacing={2}>
            {(isSensorIdValid && !loading) &&
            <>
                <Grid item xs={12} md={12} lg={6}>
                    <DeviceDescriptionCardView className={clsx(classes.card, classes.firstRowCard)}/>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <LogCardView className={clsx(classes.card, classes.firstRowCard)}/>
                </Grid>
                <Grid item xs={12}>
                    <SensorDataCardView className={clsx(classes.card, classes.sensorDataRowCard)} boxShadow={2}/>
                </Grid>
                {selectedStationData?.sensors.includes("VOC_IAQ") &&
                    <Grid item xs={12}>
                        <VocIaqCardView className={clsx(classes.card, classes.vocDataRowCard)} boxShadow={2}/>
                    </Grid>
                }
                <Grid item xs={12}>
                    <SensorStatisticCardView className={clsx(classes.card, classes.statisticRowCard)} boxShadow={2}/>
                </Grid>
                {selectedStationData?.sensors.includes("noise") &&
                    <Grid item xs={12}>
                        <NoiseCardView className={clsx(classes.card, classes.noiseDataRowCard)} boxShadow={2}/>
                    </Grid>
                }
                <Grid item xs={12}>
                    <PollutantTrendCardView className={clsx(classes.card, classes.thirdRowCard, classes.trendCard)}
                                            boxShadow={2}/>
                </Grid>
                {selectedStationData?.sensors.includes("wind") &&
                    <Grid item xs={12}>
                        <WindSpeedCardView className={clsx(classes.card, classes.windSpeedCard)} boxShadow={2}/>
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <DaysOverWhoThresholdCardView className={clsx(classes.card, classes.thirdRowCard)} boxShadow={2}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AQICalendarCardView className={clsx(classes.card, classes.fourthRowCard)} boxShadow={2}/>
                </Grid>
            </>
            }
            {(!isSensorIdValid && !loading) &&
            <Grid item xs={12}>
                <DeviceNotFound className={clsx(classes.card, classes.notFoundCard)}/>
            </Grid>
            }
            {loading && <VerifyingDevice className={classes.loading}/>}

        </Grid>

    );
};

export default AnalyticScreen;
