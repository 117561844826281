import React, {useCallback, useState} from 'react';
import {Backdrop, Card, Divider, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import AlarmConfigStep2 from "./steps/AlarmConfigStep2";
import AlarmConfigSteps from "./AlarmConfigSteps";
import AlarmConfigStep1 from "./steps/AlarmConfigStep1";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    card:{
        paddingTop:16,
        paddingBottom:10,
    },
    popUpContainer:{
        maxWidth:1000
    },
    tittle:{
        marginLeft:30,
        color:"#434343",
        marginBottom:16
    },
    step1Container:{
        paddingTop:60,
        paddingLeft:30,
        paddingRight:30,
        maxWidth:780,
        justifyContent:"center"
    },
    step2Container:{
        padding:30,
        maxWidth:720,
        justifyContent:"center"
    },
    divider:{
        marginBottom:32
    },
    divider2:{
        marginTop:8
    },
    steps:{
        marginRight:20,
        marginLeft:20
    },
    buttons:{
        marginRight:30,
        display:"flex",
        marginTop:10,
        justifyContent:"flex-end"
    },
});

const AlarmConfigPopUp = ({open,cancelAlarmSetup,finishAlarmSetup}) => {

    const {t} = useTranslation()
    const classes = useStyles();

    const [{
        name,
        activeStep,
        pollutant,trigger,value,step1Valid,
        selectedStations,step2Valid,
        units
        },updateState] = useState({name:"",selectedStations:[],trigger:"biggerThan",value:0,
        pollutant:"no",activeStep:0,step1Valid:false,step2Valid:false,units:{}})


    //Step 1 callbacks
    const nameCallback = useCallback((event)=>{
        updateState(state => ({...state,name:event.target.value, step1Valid: event.target.value !== ""}))
    },[])

    const updateSelectedPollutantCallback = useCallback((pollutant)=>{
        updateState(state => ({...state,pollutant }))
    },[])

    const updateTriggerCallback = useCallback((event)=>{
        updateState(state => ({...state,trigger:event.target.value }))
    },[])

    const handleSliderChange = (event, newValue) => {
        updateState(state => ({...state,value:newValue }))
    };

    const handleInputChange = (event) => {
        updateState(state => ({...state,value:event.target.value === '' ? '' : Number(event.target.value) }))
    };

    const handleBlur = () => {
        if (value < 0) {
            updateState(state => ({...state,value:0 }))
        }
    };

    const unitsCallback = useCallback((units)=>{
        updateState(state => ({...state,units }))
    },[])

    //Step 2 callback
    const updateSelectedStationsCallback = useCallback((selectedStations)=>{
        updateState(state => ({...state,selectedStations,step2Valid: selectedStations.length>0 }))
    },[])


    const handleFirstButton = ()=>{
        if (activeStep===0){
            cancelAlarmSetup();
        }else {
            updateState(state => ({...state,activeStep: activeStep-1}))
        }
    }


    const handleSecondButton = ()=>{
        if (activeStep<1){
            updateState(state => ({...state,activeStep: activeStep+1}))
        }else {
            const body = {
                name,
                pollutant,
                trigger,
                value,
                pollutants:units.pollutants,
                temperature:units.temperature,
                index:units.index,
                windSpeed:units.windSpeed,
                stations:selectedStations.map(station => {return station.id})
            }
            finishAlarmSetup(body)
        }
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <Card className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <Typography variant={"h6"} className={classes.tittle}>{t("alarmScreen.popUp.configureAlarm")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid item xs={12} className={classes.steps}>
                        <AlarmConfigSteps activeStep={activeStep}/>
                    </Grid>
                    {activeStep === 0 && <Grid container item xs={12} className={classes.step1Container} >
                        <AlarmConfigStep1
                            name={name}
                            nameCallback={nameCallback}
                            pollutantCallback={updateSelectedPollutantCallback}
                            selectedPollutant={pollutant}
                            trigger={trigger}
                            triggerCallback={updateTriggerCallback}
                            value={value}
                            handleBlur={handleBlur}
                            handleInputChange={handleInputChange}
                            handleSliderChange={handleSliderChange}
                            cancelCallback={cancelAlarmSetup}
                            unitsCallback = {unitsCallback}
                            organizationUnits = {units}
                        />
                    </Grid>}
                    {activeStep === 1 && <Grid container item xs={12} className={classes.step2Container} >
                        <AlarmConfigStep2 selectedVariable={pollutant} callback={updateSelectedStationsCallback}/>
                    </Grid>}

                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            {activeStep !== 0 && <Button onClick={cancelAlarmSetup}>{t("cancel")}</Button>}
                            <Button onClick={handleFirstButton}>{activeStep === 0 ? t("cancel") : t("back")}</Button>
                            <Button
                                disabled={activeStep === 0 ? !step1Valid : !step2Valid  }
                                onClick={handleSecondButton}>{activeStep <1 ? t("next") : t("finish")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default AlarmConfigPopUp;
