import {webClient} from "../config/axios";
import {handleError} from "./handleRequestError";

export const stationRequest = (index,callback) => {
    webClient.get(`/stations/?index=${index}`)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        })
};