import React from 'react';
import {Divider, Menu, MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";

const SettingMenu = ({anchorEl, closeMenu,logoutSession,openProfile,openOrganization}) => {

    const { t } = useTranslation();

    const handleCloseSession = ()=>{
        closeMenu();
        logoutSession();
    }

    const handleProfile=()=>{
        closeMenu();
        openProfile();
    }

    const handleOrganization = ()=>{
        closeMenu();
        openOrganization();
    }

    const handleClose = ()=>{
        closeMenu();
    }

    const handlePlatformManual = ()=>{
        closeMenu();
        if(window.navigator.language.includes("es")){
            const newWindow = window.open(`https://docs.bettair.city/es/`, '_blank')
            if (newWindow) newWindow.opener = null
        }else {
            const newWindow = window.open(`https://docs.bettair.city/en/`, '_blank')
            if (newWindow) newWindow.opener = null
        }
    }

    const handleApiManual = ()=>{
        closeMenu();
        const newWindow = window.open(`https://docs.bettair.city/api`, '_blank')
        if (newWindow) newWindow.opener = null
    }

    return (

        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handlePlatformManual}>{t("dashboardMenu.platform_manual")}</MenuItem>
            <MenuItem onClick={handleApiManual}>{t("dashboardMenu.api_manual")}</MenuItem>
            <Divider />
            <MenuItem
                data-testid={"dashboard-profile"}
                onClick={handleProfile}>{t("dashboardMenu.profile")}</MenuItem>
            <MenuItem
                data-testid={"dashboard-organization"}
                onClick={handleOrganization}>{t("dashboardMenu.organization")}</MenuItem>
            <MenuItem
                data-testid={"dashboard-logout"}
                onClick={handleCloseSession}>{t("dashboardMenu.logout")}</MenuItem>
        </Menu>
    );
};

export default SettingMenu;
