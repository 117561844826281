import React, {useCallback, useEffect, useState} from 'react';
import DateRangeComponent from "../../../../common/dateRange/DateRangeComponent";
import {Card, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@mui/material";
import CardTittle from "../../analytics/common/CardTittle";
import DataNotFound from "../../../../common/DataNotFound";
import ErrorFetchingDataMessage from "../../analytics/common/ErrorFetchingDataMessage";
import ChartLoading from "../../analytics/common/ChartLoading";
import PeriodSelector, {CUSTOM_RANGE} from "../../analytics/common/PeriodSelector";
import HelpPopup from "../../analytics/common/HelpPopup";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import useDateRange from "../../../../../hooks/useDateRange";
import {makeStyles} from "@mui/styles";
import './chart_style.css';
import Plot from "react-plotly.js";
import {pollutantNames} from "../../pollutantNames";
import {unitsMap} from "../../unitsNames";
import {roundAccurately} from "../../../../../utils/roundNumbers";
import {compareStationsColors} from "../CompareScreen";
import {compareStatisticRequest} from "../../../../../requests/compare/compareStatisticsRequest";
import {useDispatch, useSelector} from "react-redux";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {expiredSession} from "../../../../../reducers/authReducer";
import {COMPARE_ROUTE, DEFAULT_PERIOD} from "../../../../../constants";
import {useSnackbar} from "notistack";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {useTranslation} from "react-i18next";


const initialPlotLayout = {
    legend:{x: -0.41, y: 0.9},
    xaxis3:{
        domain: [0,1],
        anchor:"y3"
    },
    yaxis3: {
        anchor:"x3",
        title: 'µg/m³',
        domain: [0.38, 1],
    },
    yaxis4: {
        anchor:"x4",
        side:"right",
        overlaying: 'y3',
        title: 'mg/m³',
        domain: [0.38, 1],
    },
    yaxis2: {
        anchor:"x2",
        range:[0,60],
        domain: [0, 0.26],
        title: "ºC",
    },
    xaxis2:{
        anchor:"y2",
        domain: [0, 0.45]
    },
    yaxis: {
        anchor:"x1",
        title: "RH %",
        range:[0,100],
        domain: [0, 0.26],
    },
    xaxis:{
        anchor:"y1",
        domain: [0.55, 1.0],
        showgrid:false
    },
    bargap:0.2,
    bargroupgap: 0.1
};

const useStyles = makeStyles({
    rightControls:{
        display:"flex",
        flexDirection:"column",
        alignItems:"flex-start"
    },
    radioGroup:{
        marginTop:30
    }
});

const CompareStatisticCardView = ({className}) => {

    const {t} = useTranslation()
    const dispatch = useDispatch()
    const initialState = {data:[],error:"",maxY3AxeValue:0,maxY4AxeValue:0,
        type:"mean",period:DEFAULT_PERIOD,loading:false,plotLayout:initialPlotLayout}
    const[{data,period,loading,plotLayout,type,rawData,error,maxY3AxeValue
        ,maxY4AxeValue},updateState] = useState(initialState)
    const [{openDateRangePicker,dateRange},updateOpenDatePickerCallback,
        updateDatePickedCallback,clearDataRange] = useDateRange();
    const { selectCompareStations,stations } = useSelector( state => state.dashboardUI );
    const { units } = useSelector( state => state.auth );
    const isMounted = useMountComponent();
    const { enqueueSnackbar } = useSnackbar();


    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();

    const classes = useStyles();

    const plotConfig = {
        modeBarButtonsToRemove:[ "select2d", "lasso2d","autoScale2d",
            "zoomIn2d", "zoomOut2d","resetScale2d","zoom2d",
            "toggleHover", "resetViews", "sendDataToCloud",
            "toggleSpikelines", "resetViewMapbox","pan2d","hoverClosestCartesian",
            "hoverCompareCartesian"
        ],
        displaylogo : false
    };

    useEffect(()=>{
        updateState(state =>({...state,plotLayout: {...initialPlotLayout,
                yaxis3: units.pollutants === "eu" ? {...initialPlotLayout.yaxis3} : {
                    anchor:"x3",
                    title: 'ppb - µg/m³',
                    domain: [0.38, 1],
                },
                yaxis4:units.pollutants === "eu" ? {...initialPlotLayout.yaxis4} : {
                    anchor:"x4",
                    side:"right",
                    overlaying: 'y3',
                    title: 'ppm',
                    domain: [0.38, 1],
                },
                yaxis2:units.temperature === "celsius" ? {...initialPlotLayout.yaxis2}: {
                    anchor:"x2",
                    domain: [0, 0.26],
                    title: "ºF",
                    range:[32,140]
                }
            }}))
    },[units.pollutants, units.temperature])


    const onCustomPressedCallback = useCallback(()=>{
            updateOpenDatePickerCallback(true);
        }
        ,[updateOpenDatePickerCallback]);

    const handleRadioChange = (event)=>{
        let type = event.target.value
        updateState(state =>{
            return{...state,data:getChartData(null,type),type}
        });
    }

    const groupDataByStation = useCallback((filteredData)=>{
        let stationData = []
        let stationsIdList = selectCompareStations.map(item => item.id)
        stationsIdList.forEach(stationId => {
                let currentStation = {id:stationId,alias:stations.find(station => station.id === stationId).alias}
                filteredData.forEach(currentPollutant =>{
                    currentStation[currentPollutant[0]] = currentPollutant[1][stationId]
                })
                stationData.push(currentStation)
            }
        )
        return stationData
    },[stations,selectCompareStations])

    const createPollutantChartData = useCallback((pollutantData)=>{
        return Object.entries(pollutantData).filter(item => item[0] !== "id" && item[0] !== "alias").map(value => {
            let units = value[1].units
            return {
                x:value[0],
                y:value[1].value,
                yaxis:
                    units === "celsius" || units === "fahrenheit" ? 'y2' :
                        units === "percentage" ? 'y1' : units === "ppb" ||  units === "ug-m3" ? 'y3' :
                            units === "mg-m3" || units === "ppm" ? 'y4': "error",
                xaxis:
                    units === "celsius" || units === "fahrenheit" ? 'x2' :
                        units === "percentage" ? 'x1' : units === "ppb" ||  units === "ug-m3" ? 'x3' :
                            units === "mg-m3" || units === "ppm" ? 'x3': "error",
                units,
                groupY: units === "celsius" || units === "fahrenheit" ? 'y2' :
                    units === "percentage" ? 'y1' :
                        units === "ppb" ? "y3" : units === "ug-m3" ? 'y4' :
                        units === "mg-m3" || units === "ppm" ? 'y5': "error",
            }
        });
    },[])

    const getChartData = useCallback( (serverData,selectedType)=>{

        let currentType = selectedType || type
        let currentData = serverData || rawData
        if(currentData.mean === undefined || currentData.max === undefined){return []}
        let filteredData = currentType === "mean" ? Object.entries(currentData.mean) : Object.entries(currentData.max);

        let stationData = groupDataByStation(filteredData)

        let output = []
        stationData.forEach((pollutantData,stationIndex) => {
            let pollutantChartData = createPollutantChartData(pollutantData)

            //group units
            let y1Group = pollutantChartData.filter(value => {return value.groupY === "y1"})
            let y2Group = pollutantChartData.filter(value => {return value.groupY === "y2"})
            let y3Group = pollutantChartData.filter(value => {return value.groupY === "y3"})
            let y4Group = pollutantChartData.filter(value => {return value.groupY === "y4"})
            let y5Group = pollutantChartData.filter(value => {return value.groupY === "y5"})

            let yGroupList = [y1Group,y2Group,y3Group,y4Group,y5Group]
            yGroupList.forEach( (yGroup,yGroupIndex) =>{
                if(yGroup.length>0){
                    output.push({
                        name:` ${pollutantData.alias} ` ,
                        x: yGroup.map(value => pollutantNames.get(value.x)),
                        y: yGroup.map(value => value.y !== null ? roundAccurately(value.y,2): null),
                        hovertemplate: `<b>${pollutantData.alias} - %{x}</b>: %{y} ${unitsMap.get(yGroup[0].units)}`,
                        hoverlabel:{namelength:0},
                        yaxis: yGroup[0].yaxis,
                        legendgroup: `group${stationIndex+1}`,
                        showlegend: yGroupIndex === 0,
                        marker: {color:compareStationsColors[stationIndex]},
                        xaxis: yGroup[0].xaxis,
                        type: 'bar',
                        width:  yGroup.map(value => (0.70/stationData.length))
                    })
                }
            })
        })
        return output
    },[type,createPollutantChartData,groupDataByStation,rawData])

    useEffect( ()=>{
            updateData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[selectCompareStations]);

    const getMaxYAxeValue = (axe,data)=>{
        let aux = Object.entries(data.max)
        let max = 0
        aux.forEach(pollutant => {
            let aux2 = Object.entries(pollutant[1])
            aux2.forEach( station => {
                if(station[1].units === (axe === "y3" ? "ppb" : "ppm") ||
                    station[1].units === (axe === "y3" ? "ug-m3" : "mg-m3")){
                    if(station[1].value>max){
                        max = station[1].value
                    }
                }
            })
        })
        return max
    }

    const updateData = useCallback((selectedPeriod,dateRange)=>{

        let currentPeriod = selectedPeriod || period
        updateState(state =>{
            return {...state,loading: true, data: [],error: "",rawData:[], period: currentPeriod}
        });

        let selectedRange = getDateRange(currentPeriod,dateRange)
        compareStatisticRequest({
            "stations": selectCompareStations.map(item=> item.id),
            "pollutants": units.pollutants,
            "temperature": units.temperature,
            "time": selectedRange[0],
            "endtime": selectedRange[1]
        }, (err,data)=>{
            if (isMounted.current) {
                if(!err){

                    updateState(state =>({...state,loading:false,
                        data: getChartData(data,null),
                        rawData:data,
                        maxY3AxeValue: getMaxYAxeValue("y3",data),
                        maxY4AxeValue:getMaxYAxeValue("y4",data)
                    }))
                }
                else {
                    if(data.status === 404){
                        updateState(state =>{
                            return {...state,loading: false}
                        });
                        enqueueSnackbar(t("compareScreen.statistic.data_not_found"),{ variant:"info" });
                    }
                    else {
                        updateState(state =>{
                            return {...state,loading: false,error:data.status}
                        });
                        if(data.status === 401){
                            expiredSession(COMPARE_ROUTE)(dispatch)
                        }
                        else {
                            enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("compareScreen.statistic.could_not_update")} `,{ variant:"error" });
                        }
                    }
                }
            }
        })
    },[t,dispatch,period,enqueueSnackbar,getChartData,isMounted,selectCompareStations,units])


    const handleSelectorChange = useCallback((event)=>{
        if( Number(event.target.value) !== CUSTOM_RANGE){
            clearDataRange();
            updateData(event.target.value)
        }
    },[updateData,clearDataRange]);

    useEffect(
        ()=>{
            if(dateRange != null){
                updateData(CUSTOM_RANGE,dateRange);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[dateRange]);

    return (
        <Card className={className}>
            <DateRangeComponent open={openDateRangePicker} changeState={updateOpenDatePickerCallback}
                                onDateRangePicked={updateDatePickedCallback} />
            <Grid container>
                <Grid container item xs={12} alignItems={"center"} alignContent={"center"}>
                    <CardTittle tittle={ t("compareScreen.statistic.title")} setAnchorEl={setAnchorEl}/>
                </Grid>

                { data.length>0 && <Grid  container item xs={10} className={"compareStatisticData"} >
                    <Plot
                        useResizeHandler = {true}
                        layout={{...plotLayout,
                            yaxis3:{...plotLayout.yaxis3, range: [0,maxY3AxeValue]},
                            yaxis4:{...plotLayout.yaxis4, range: [0,maxY4AxeValue]},
                        }}
                        data={data}
                        onInitialized={(figure) => this.setState(figure)}
                        onUpdate={
                            (figure) =>
                                this.setState(figure)
                        }
                        config = {plotConfig}>
                    </Plot>

                </Grid>}

                {(data.length === 0 && !loading) &&  <Grid container item xs={10} className={"notFoundStatisticCompareData"} >
                    <DataNotFound/>
                </Grid>}
                {error !== "" &&  <Grid container item xs={10} className={"notFoundStatisticCompareData"} >
                    <ErrorFetchingDataMessage/>
                </Grid>}
                {loading &&  <Grid container item xs={10} className={"notFoundStatisticCompareData"} >
                    <ChartLoading/>
                </Grid>}
                <Grid item className={classes.rightControls} xs={2}>
                    <PeriodSelector loading={loading}
                                    period={period}
                                    handleSelectorChange={handleSelectorChange}
                                    dateRange={dateRange}
                                    onCustomPressedCallback={onCustomPressedCallback}
                    />
                    <FormControl className={classes.radioGroup} disabled={loading}>
                        <FormLabel component="legend">{t("compareScreen.statistic.value")}</FormLabel>
                        <RadioGroup  value={type} onChange={handleRadioChange}>
                            <FormControlLabel value="mean" control={<Radio />} label={t("compareScreen.statistic.mean")} />
                            <FormControlLabel value="max" control={<Radio />} label={t("compareScreen.statistic.max")} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("compareScreen.statistic.en_compare_stationStatistic")}/>
        </Card>
    );
};

export default CompareStatisticCardView;
