import React from 'react';
import {Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {
    GREEN_CAQI,
    GREEN_YELLOW_CAQI,
    ORANGE_CAQI,
    RED_CAQI,
    TEXT_COLOR,
    TEXT_COLOR_CONTRAST,
    YELLOW_CAQI
} from "./colors";



const useStyles = makeStyles({
    valueDark:{
        textAlign:"center",
        minWidth:60,
        color: TEXT_COLOR,
        fontWeight:"bold",
        marginRight:42,
        marginLeft:8
    },
    descriptionDark:{
        textAlign:"center",
        color: TEXT_COLOR,
        fontWeight:"bold",
    },
    value:{
        textAlign:"center",
        minWidth:60,
        color: TEXT_COLOR_CONTRAST,
        fontWeight:"bold",
        marginRight:42,
        marginLeft:8
    },
    description:{
        textAlign:"center",
        color: TEXT_COLOR_CONTRAST,
        fontWeight:"bold",
    },

    greenCAQI:{
        background: GREEN_CAQI
    },
    greenYellowCAQI:{
        background: GREEN_YELLOW_CAQI,
        marginBottom: 0
    },
    yellowCAQI:{
        background: YELLOW_CAQI,
        marginBottom: 0
    },
    orangeCAQI:{
        background: ORANGE_CAQI,
        marginBottom: 0
    },
    redCAQI:{
        background: RED_CAQI,
        marginBottom: 0
    }
});

const CAQILegend = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} container className={classes.redCAQI}>
                <Typography variant={"subtitle2"} className={classes.value}>>100 </Typography>
                <Typography variant={"subtitle2"} className={classes.description}>{t("caqiLegend.very_high")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.orangeCAQI}>
                <Typography variant={"subtitle2"} className={classes.value}>75–100 </Typography>
                <Typography variant={"subtitle2"} className={classes.description}>{t("caqiLegend.high")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.yellowCAQI}>
                <Typography variant={"subtitle2"} className={classes.valueDark}>50–75 </Typography>
                <Typography variant={"subtitle2"} className={classes.descriptionDark}>{t("caqiLegend.medium")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.greenYellowCAQI}>
                <Typography variant={"subtitle2"} className={classes.valueDark}>25–50 </Typography>
                <Typography variant={"subtitle2"} className={classes.descriptionDark}>{t("caqiLegend.low")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.greenCAQI}>
                <Typography variant={"subtitle2"} className={classes.valueDark}>0–25 </Typography>
                <Typography variant={"subtitle2"} className={classes.descriptionDark}>{t("caqiLegend.very_low")}</Typography>
            </Grid>
        </>
    );
};

export default CAQILegend;
