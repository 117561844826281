import type from "./action_types/type";

const initialState={
    downloadList:[],
    processingProgressList:[],
    downloadProcessList:[],
    minimized:false
}

export const downloadReducer = (state = initialState, action) =>{
    switch (action.type){
            //download list
            //TODO update processing progress
        case type.setDownloadListAction:
            return {...state,downloadList:action.payload }
        case type.deleteDownloadAction:
            return {...state,downloadList:state.downloadList.filter(item => !action.payload.includes(item.id))}
        case type.createDownloadAction:
            return {...state,downloadList: state.downloadList.find(item => item.id === action.payload.id) !== undefined ?
                    state.downloadList : [...state.downloadList,action.payload]}
        case type.updateDownloadStateAction:
            return {...state,downloadList:state.downloadList.map(item => {return item.id !== action.payload.id ? item :
                    action.payload.state !== "completed" ? {...item,status:{state:action.payload.state}} :
                        {...item,status:{state:action.payload.state, size:action.payload.size}}})}

            //visibility
        case type.setDownloadMinimizedAction:
            return {...state,minimized: action.payload}

            //progress
        case type.setProcessingProgressListAction:
            return {...state,processingProgressList: action.payload}
        case type.removeProcessingProgressListAction:
            return {...state,processingProgressList: state.processingProgressList.filter(item => !action.payload.includes(item.id))}
        case type.updateProcessingProgressListAction:
            return {...state,processingProgressList: state.processingProgressList.find(item => item.id === action.payload.id) === undefined ?
                     [...state.processingProgressList, action.payload] :
                    state.processingProgressList.map(item => {return item.id !== action.payload.id ?
                    item : {...item,progress:action.payload.progress}})}

            //download process
        case type.addDownloadProcessAction:
            return {...state,downloadProcessList: state.downloadProcessList.find(item => item.id === action.payload.id) !== undefined ?
                    state.downloadProcessList : [...state.downloadProcessList,action.payload]}
        case type.removeDownloadProcessAction:
            return {...state,downloadProcessList: state.downloadProcessList.filter(item => item.id !== action.payload)}
        case type.removeAllDownloadProcessAction:
            return {...state,downloadProcessList: []}
        case type.updateDownloadProgressAction:
            return {...state,downloadProcessList: [...state.downloadProcessList.map(item => {
                return item.id !== action.payload.id ? item : {...item,progress: action.payload.progress}
                })]}
        case type.setDownloadProcessErrorAction:
            return {...state,downloadProcessList: [...state.downloadProcessList.map(item => {
                    return item.id !== action.payload.id ? item : {...item,error: true}
                })]}
        default:
            return state;
    }
};

//Package generation progress actions
export const setProcessingProgressListAction = (progressList) =>({
    type:type.setProcessingProgressListAction,
    payload:progressList
})

export const removeProcessingProgressListAction = (idArray) =>({
    type:type.removeProcessingProgressListAction,
    payload:idArray
})

export const updateProcessingProgressListAction = (item) =>({
    type:type.updateProcessingProgressListAction,
    payload:item
})

//Download popup visibility actions
export const setDownloadProgressMinimizedStateAction = (minimized) =>({
    type:type.setDownloadMinimizedAction,
    payload:minimized
})

//Download list actions
export const setDownloadListAction = (downloadList) =>({
    type:type.setDownloadListAction,
    payload:downloadList
})

export const updateDownloadStateAction = (payload) =>({
    type:type.updateDownloadStateAction,
    payload:payload
})

export const deleteDownloadAction = (payload) => ({
    type:type.deleteDownloadAction,
    payload:payload
})

export const createDownloadAction = (payload)=>({
    type:type.createDownloadAction,
    payload:payload
})


//Download process actions
export const updateDownloadProcessProgressAction = (id,progress) =>({
    type:type.updateDownloadProgressAction,
    payload: {id,progress}
})

export const addDownloadProcessAction = (downloadProcess) =>({
    type:type.addDownloadProcessAction,
    payload:downloadProcess
})

export const removeDownloadProcessAction = (id) =>({
    type:type.removeDownloadProcessAction,
    payload:id
})

export const removeAllDownloadProcessAction = () =>({
    type:type.removeAllDownloadProcessAction,
})

export const setDownloadProcessErrorAction = (id) =>({
    type:type.setDownloadProcessErrorAction,
    payload:id
})
