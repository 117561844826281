import React from 'react';
import {Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {
    DARK_PURPLE_AQI,
    GREEN_AQI,
    ORANGE_AQI,
    PURPLE_AQI,
    RED_AQI,
    TEXT_COLOR,
    TEXT_COLOR_CONTRAST,
    YELLOW_AQI
} from "./colors";

const useStyles = makeStyles({
    valueDark:{
        textAlign:"center",
        minWidth:60,
        color: TEXT_COLOR,
        fontWeight:"bold",
        marginRight:42,
        marginLeft:8
    },
    descriptionDark:{
        textAlign:"center",
        color: TEXT_COLOR,
        fontWeight:"bold",
    },
    value:{
        textAlign:"center",
        minWidth:60,
        color: TEXT_COLOR_CONTRAST,
        fontWeight:"bold",
        marginRight:42,
        marginLeft:8
    },
    description:{
        textAlign:"center",
        color: TEXT_COLOR_CONTRAST,
        fontWeight:"bold",
    },

    green:{
        background: GREEN_AQI
    },
    yellow:{
        background: YELLOW_AQI,
        marginBottom: 0
    },
    orange:{
        background: ORANGE_AQI,
        marginBottom: 0
    },
    red:{
        background: RED_AQI,
        marginBottom: 0
    },
    purple:{
        background: PURPLE_AQI,
        marginBottom: 0
    },
    darkPurple:{
        background: DARK_PURPLE_AQI,
        marginBottom: 0
    }
});

const AQILegend = () => {
    const { t } = useTranslation();

    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} container className={classes.darkPurple}>
                <Typography variant={"subtitle2"} className={classes.value}>301-500 </Typography>
                <Typography variant={"subtitle2"} className={classes.description}>{t("aqiLegend.hazardous")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.purple}>
                <Typography variant={"subtitle2"} className={classes.value}>201-300 </Typography>
                <Typography variant={"subtitle2"} className={classes.description}>{t("aqiLegend.very_unhealthy")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.red}>
                <Typography variant={"subtitle2"} className={classes.value}>151-200 </Typography>
                <Typography variant={"subtitle2"} className={classes.description}>{t("aqiLegend.unhealthy")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.orange}>
                <Typography variant={"subtitle2"} className={classes.value}>101-150 </Typography>
                <Typography variant={"subtitle2"} className={classes.description}>{t("aqiLegend.unhealthy_for_sensitive")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.yellow}>
                <Typography variant={"subtitle2"} className={classes.valueDark}>51-100 </Typography>
                <Typography variant={"subtitle2"} className={classes.descriptionDark}>{t("aqiLegend.moderate")}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.green}>
                <Typography variant={"subtitle2"} className={classes.valueDark}>0-50 </Typography>
                <Typography variant={"subtitle2"} className={classes.descriptionDark}>{t("aqiLegend.good")}</Typography>
            </Grid>
        </>
    );
};

export default AQILegend;
