import React, {useCallback} from 'react';
import {Backdrop, Card, Divider, Grid, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import validator from "validator";
import AppPasswordParamsConfig from "./AppPasswordParamsConfig";
import {getFinalDayOfMonthInUTC, getInitOfDayMonthInUTC} from "../../../../../utils/dateUtil";
import {useTranslation} from "react-i18next";

export const isCurrentPeriodValid = (date,period)=>{
    const initialDayOfCurrentMont = getInitOfDayMonthInUTC(new Date())
    const endOfPeriod = getFinalDayOfMonthInUTC(date,period-1)
    return initialDayOfCurrentMont.getTime() > endOfPeriod.getTime()
}

const useStyles = makeStyles({
    card:{
        paddingTop:16,
        paddingBottom:10,
    },
    tittle:{
        marginLeft:30,
        color:"#434343",
        marginBottom:16
    },
    buttons:{
        marginRight:30,
        display:"flex",
        marginTop:10,
        justifyContent:"flex-end"
    },
    divider:{
        marginBottom:32
    },
    divider2:{
        marginTop:8
    },
    popUpContainer:{
        maxWidth:980
    },
    paramsSetup:{
        paddingTop:20,
        paddingBottom:40,
        paddingLeft:30,
        paddingRight:30,
        maxWidth:1000,
        justifyContent:"center"
    }
});

const AppPasswordConfigPopUp = ({open, finishAppPasswordSetup, cancelAppPasswordSetup}) => {

    const { t } = useTranslation();

    const [{name,valid}, updateState] = React.useState(
        {
            name:"",valid:false
        });

    const nameCallback = useCallback((event)=>{
        let value = event.target.value
        let valid = !validator.isEmpty(value)
        updateState(state=>({...state,name:value,valid}))
    },[])

    const classes = useStyles();

    const handleFinishSetup = ()=>{
        finishAppPasswordSetup({
            "name": name
        })
    }
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <Card data-testid={ "configure-app-password-pop-up"} className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <Typography
                            variant={"h6"} className={classes.tittle}>{t("appPassword.configure_app_password")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                     <Grid container item xs={12} className={classes.paramsSetup} >
                         <AppPasswordParamsConfig name={name} nameCallback={nameCallback}
                         />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button onClick={cancelAppPasswordSetup}>{ t("cancel")}</Button>
                            <Button
                                data-testid={ "finish-password-config"}
                                disabled={!valid}
                                onClick={handleFinishSetup}>{t("finish")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default AppPasswordConfigPopUp;
