export const triggerList = ["biggerThan","biggerEqual","lowerThan","lowerEqual"]

export const getTriggerName = (value,t)=>{
    if(value === undefined){
        return "Trigger undefined"
    }

    switch (value){
        case triggerList[0]:
            return t("alarmScreen.popUp.value_gratter_theshold")
        case triggerList[1]:
            return t("alarmScreen.popUp.value_gratter_equal_theshold")
        case triggerList[2]:
            return t("alarmScreen.popUp.value_smaller_threshold")
        case triggerList[3]:
            return t("alarmScreen.popUp.value_smaller_equal_threshold")
        default: return "Not found"
    }
}
