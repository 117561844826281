import React, {useCallback, useEffect, useState} from 'react';
import {
    Card,
    CardHeader,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const useStyles = makeStyles((theme) => ({
    divider: {
        // Theme Color, or use css color in quote
        borderColor:"white",
        borderWidth:1
    },
    cardHeader:{
        background:"#e5e5e5"
    },
    transfer:{
        width:700,
    }
}));

const StationTransferList = ({initialSelected, initialNotSelected, selectedCallback}) => {

    const {t} = useTranslation()
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(initialNotSelected);
    const [right, setRight] = React.useState(initialSelected);

    const [{leftFilter,rightFilter},updateState] = useState({leftFilter:"",rightFilter:""});

    const leftFilterCallback = useCallback((event)=>{
        updateState(state=>({...state,leftFilter: event.target.value}));
    },[]);

    const rightFilterCallback = useCallback((event)=>{
        updateState(state=>({...state,rightFilter: event.target.value}));
    },[]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(
        ()=>{selectedCallback(right)},
        [right,selectedCallback]);

    const classes = useStyles();

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (title, items,filter,onchangeFilter) => (

        <Card  >
            <CardHeader className={classes.cardHeader}
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                            "data-testid": "select-all"
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} ${t("dashboard.selected")}`}
            />
            <Divider
                classes={ {root:classes.divider}}  />
                <TextField
                    value ={filter}
                    onChange={onchangeFilter}
                    variant={"filled"}
                    fullWidth
                    label={"Filter by Alias"}
                />
            <List
                sx={{
                    width: 260,
                    height: 330,
                    bgcolor: '#f1f1f1',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value.id}-label`;
                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value?.alias}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );



    return (
        <Grid container className={classes.transfer} spacing={2} justifyContent="center" alignItems="center">
            <Grid  item >{customList(t("dashboard.available_stations"), leftFilter === "" ? left : left.filter(item=> item.alias.toLowerCase().includes(leftFilter.toLowerCase())), leftFilter,leftFilterCallback)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        data-testid={"transfer-add-variables"}
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList(t("dashboard.selected_stations"), rightFilter === "" ? right: right.filter(item=> item.alias.toLowerCase().includes(rightFilter.toLowerCase())),rightFilter,rightFilterCallback)}</Grid>
        </Grid>
    );
};

export default StationTransferList;
