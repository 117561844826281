import React, {useCallback, useEffect, useState} from 'react';
import {Card, Grid, IconButton} from "@mui/material";
import DateRangeComponent from "../../../../common/dateRange/DateRangeComponent";
import CardTittle from "../common/CardTittle";
import RefreshIcon from "@mui/icons-material/Refresh";
import Plot from "react-plotly.js";
import DataNotFound from "../../../../common/DataNotFound";
import ErrorFetchingDataMessage from "../common/ErrorFetchingDataMessage";
import ChartLoading from "../common/ChartLoading";
import PeriodSelector, {CUSTOM_RANGE} from "../common/PeriodSelector";
import HelpPopup from "../common/HelpPopup";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ANALYTICS_ROUTE, DEFAULT_PERIOD} from "../../../../../constants";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import {useSnackbar} from "notistack";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import useDateRange from "../../../../../hooks/useDateRange";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {roundAccurately} from "../../../../../utils/roundNumbers";
import {expiredSession} from "../../../../../reducers/authReducer";
import {makeStyles} from "@mui/styles";
import './chart_style.css';
import {pollutantNames} from "../../pollutantNames";
import WindRoseChart from "./WindRoseChart/WindRoseChart";
import {unitsMap} from "../../unitsNames";
import {windDataRequest} from "../../../../../requests/analytics/windDataRequest";


const legendColors = ['#1A23D0','#D0B11A','#741AD0'];

const useStyles = makeStyles({
    loading:{
        left: "50%",
        position: "relative",
        top: "50%",
        zIndex:999
    },
    rightControls:{
        display:"flex",
        flexDirection:"column",
        alignItems:"flex-end",
        paddingRight:80
    },
    refreshButton:{
        marginLeft:"auto",
        color:"gray"
    }
});

const plotConfig = {
    modeBarButtonsToRemove:[ "select2d", "lasso2d",
        "toggleHover", "sendDataToCloud", "toggleSpikelines",
    ],
    displaylogo : false
};

const getInitialPlotLayout = (t)=> {
    return {
    legend:{x: -0.25, y: 0.9},
    yaxis2: {
        domain: [0.4,1],
        title: t("analyticScreen.windSpeed.speed_mps"),
    },
    yaxis: {
        title: t("analyticScreen.windSpeed.degrees_uppercase"),
        range:[0,359],
        domain: [0, 0.4],
    },
    xaxis:{showgrid:false,
        domain: [0.08, 1]
    }}
};

const WindSpeedCardView = ({className}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const initialState = {data:[],roseData:null,
        visibleVariables:[0,1],
        error:"",
        period:DEFAULT_PERIOD,
        loading:true,
        plotLayout:getInitialPlotLayout(t),
    }

    const[{data,roseData,period,loading,plotLayout,error,visibleVariables},updateState] = useState(initialState)
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const { enqueueSnackbar } = useSnackbar();
    const isMounted = useMountComponent();
    const { selectedStation } = useSelector( state => state.dashboardUI );
    const { units } = useSelector( state => state.auth);
    const [{openDateRangePicker,dateRange},updateOpenDatePickerCallback,
        updateDatePickedCallback,clearDataRange] = useDateRange();


    useEffect(()=>{
        updateState(state =>({...state,plotLayout: {...getInitialPlotLayout(t),
                yaxis2: units.windSpeed === "mps" ? {...getInitialPlotLayout(t).yaxis2} :
                    units.windSpeed === "mph" ? {
                    domain: [0.4,1],
                    title: t("analyticScreen.windSpeed.speed_mph")}:
                        {
                            domain: [0.4,1],
                            title: t("analyticScreen.windSpeed.speed_knots")}
            }}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[units.pollutants, units.temperature])

    useEffect(
        ()=>{
            if(dateRange != null){
                updateData(CUSTOM_RANGE);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[dateRange]);

    useEffect(()=>{
        updateData(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedStation])



    const classes = useStyles();


    const updateWindSpeedChart = useCallback((data)=>{
        let dataArray = Object.entries(data.timeSeries)

        let newData = dataArray.map((value,index) =>{
            let units = unitsMap.get(value[1].units)
            let y = value[1].y.map(item =>{
                return roundAccurately(item,2);
            });
            let adaptedData = {...value[1],y};
            return {
                visible:  visibleVariables.includes(index)  ?  true :
                    "legendonly"  ,
                name:` ${pollutantNames.get(value[0])}` ,
                ...adaptedData,
                type:  'scatter',
                mode: value[0] === "wdirection" || value[0] === "windGust" ? 'markers' :'lines',
                hoverlabel:{namelength:0},
                hovertemplate: `<b>${pollutantNames.get(value[0])}</b>: %{y} ${units}`,
                marker: {color:legendColors[index],size:3},
                yaxis: value[1].units === "mps" || value[1].units === "mph"  || value[1].units === "knots" ?
                    'y2' : value[1].units === "degrees" ? 'y1': "error"
            };
        });

        if (newData.length > 0 ){
            updateState(state =>{
                return {...state,
                    data:newData,
                    roseData:data.roseData,
                    loading: false
                }
            });
        }else {
            updateState(state =>{
                return {...state,data:[],roseData:data.roseData,loading: false, }
            });
        }
    },[visibleVariables])


    const updateData = useCallback((selectedPeriod)=>{
        let currentPeriod = selectedPeriod || period
        updateState(state =>{
            return {...state,loading: true,data: [],roseData:null,error: "", period: currentPeriod,y4Range:[]}
        });


        let selectedRange = getDateRange(currentPeriod,dateRange);
        windDataRequest(units.windSpeed,selectedStation,
            selectedRange[0],
            selectedRange[1], (err,data)=>{
                if (!isMounted.current) {return}
                if(!err){
                    updateWindSpeedChart(data)
                }
                else {
                    if(data.status === 404){
                        updateState(state =>{
                            return {...state,loading: false}
                        });
                        enqueueSnackbar(t("analyticScreen.windSpeed.sensor_data_not_found"),{ variant:"info" });
                    }
                    else {
                        updateState(state =>{
                            return {...state,loading: false,error:data.status}
                        });
                        if(data.status === 401){
                            expiredSession(ANALYTICS_ROUTE)(dispatch)
                        }
                        else {
                            enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("analyticScreen.windSpeed.could_not_update_station_data")}`,{ variant:"error" });
                        }
                    }
                }
            } );

    },[updateWindSpeedChart,units.windSpeed,t,dispatch, dateRange,
        isMounted,period,selectedStation,enqueueSnackbar]);


    const handleSelectorChange = useCallback((event)=>{
        if( Number(event.target.value) !== CUSTOM_RANGE){
            clearDataRange();
            updateData(event.target.value)
        }
    },[clearDataRange,updateData]);

    const onCustomPressedCallback = useCallback(()=>{
            updateOpenDatePickerCallback(true);
        }
        ,[updateOpenDatePickerCallback]);

    const handleRefresh = ()=>{
        updateData(null);
    }

    const onLegendClick = (event)=>{
        let directionPosition = data.length-1
        if(directionPosition === event.curveNumber ){return false}
        let position = event.curveNumber
        let newVisibleList = !visibleVariables.includes(position) ? [...visibleVariables,position] :
            visibleVariables.filter(item => item !== position)
        updateState(state => ({...state,visibleVariables: newVisibleList}))
    }

    const onLegendDoubleClick = (event)=>{
        return false
    }

    return (
        <Card className={className}>
            <DateRangeComponent open={openDateRangePicker} changeState={updateOpenDatePickerCallback}
                                onDateRangePicked={updateDatePickedCallback} />
            <Grid container>
                <Grid container item xs={12} alignItems={"center"} alignContent={"center"}>
                    <CardTittle tittle={t("analyticScreen.windSpeed.title")} setAnchorEl={setAnchorEl}/>
                    <IconButton className={classes.refreshButton} aria-label="refresh"  disabled={loading}
                                onClick={handleRefresh}>
                        <RefreshIcon fontSize={"large"}/>
                    </IconButton>
                </Grid>
                <Grid item className={classes.rightControls} xs={12}>
                    <PeriodSelector loading={loading}
                                    period={period}
                                    handleSelectorChange={handleSelectorChange}
                                    dateRange={dateRange}
                                    onCustomPressedCallback={onCustomPressedCallback}
                    />
                </Grid>

                { data.length>0 && !loading &&<Grid  container item xs={6} md={8} className={"windSpeedChart"} >
                    <Plot
                        useResizeHandler = {true}
                        layout={{...plotLayout
                        }}
                        data={data}
                        onInitialized={(figure) => this.setState(figure)}
                        onUpdate={
                            (figure) =>
                                this.setState(figure)
                        }
                        onLegendDoubleClick = {onLegendDoubleClick}
                        onLegendClick = {onLegendClick}
                        config = {plotConfig}>
                    </Plot>

                </Grid>}
                {roseData !== null && <Grid item xs={6} md={4} style={{paddingRight:40}}>
                    <WindRoseChart data={roseData}/>
                </Grid>}
                {(data.length === 0 && roseData === null && !loading) &&  <Grid container item xs={10} className={"notFoundWindData"} >
                    <DataNotFound />
                </Grid>}
                {error !== "" &&  <Grid container item xs={10} className={"notFoundWindData"} >
                    <ErrorFetchingDataMessage/>
                </Grid>}
                {loading &&  <Grid container item xs={10} className={"notFoundWindData"} >
                    <ChartLoading/>
                </Grid>}

            </Grid>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("analyticScreen.windSpeed.en_analytics_wind_speed")}/>
        </Card>
    );
};

export default WindSpeedCardView;
