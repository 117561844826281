import React from 'react';
import { Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {calendarAQIColors, calendarCAQIColors} from "./calendarAQIColors";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    legendContainer:{
        marginTop:8,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    base:{
        width:15,
        height:10,
        background:"#000",
        borderRadius:5
    },
    textLabel:{
        fontSize:"x-small",
        marginLeft:2,
        marginRight:10,
        color:"#545454"
    },
    lastTextLabel:{
        fontSize:"x-small",
        marginLeft:2,
        color:"#545454"
    },
    ...calendarAQIColors,
    ...calendarCAQIColors
});

const CalendarLegend = ({indexType}) => {
    const classes = useStyles();
    const {t} = useTranslation()
    return (
            <div >
                { indexType === "aqi" &&<div className={classes.legendContainer}>
                    <div className={clsx(classes.base, classes.green)}/>
                    <Typography
                        data-testid={"aqi-good"}
                        className={classes.textLabel} variant={"subtitle2"}>{t("aqiLegend.good")}</Typography>
                    <div className={clsx(classes.base, classes.yellow)}/>
                    <Typography
                        data-testid={"aqi-moderate"}
                        className={classes.textLabel} variant={"subtitle2"}>{t("aqiLegend.moderate")}</Typography>
                    <div className={clsx(classes.base, classes.orange)}/>
                    <Typography
                        data-testid={"aqi-unhealthy-for-sensitive"}
                        className={classes.textLabel} variant={"subtitle2"}>{t("aqiLegend.unhealthy_for_sensitive")}</Typography>
                    <div className={clsx(classes.base, classes.red)}/>
                    <Typography
                        data-testid={"aqi-unhealthy"}
                        className={classes.textLabel} variant={"subtitle2"}>{t("aqiLegend.unhealthy")}</Typography>
                    <div className={clsx(classes.base, classes.purple)}/>
                    <Typography
                        data-testid={"aqi-very-unhealthy"}
                        className={classes.textLabel} variant={"subtitle2"}>{t("aqiLegend.very_unhealthy")}</Typography>
                    <div className={clsx(classes.base, classes.darkPurple)}/>
                    <Typography
                        data-testid={"aqi-hazardous"}
                        className={classes.lastTextLabel} variant={"subtitle2"}>{t("aqiLegend.hazardous")}</Typography>
                </div>
                }
                { indexType === "caqi" &&
                <div className={classes.legendContainer}>
                    <div className={clsx(classes.base, classes.greenCAQI)}/>
                    <Typography
                        data-testid={"caqi-very-low"}
                        className={classes.textLabel} variant={"subtitle2"}>{t("caqiLegend.very_low")}</Typography>
                    <div className={clsx(classes.base, classes.greenYellowCAQI)}/>
                    <Typography
                        data-testid={"caqi-low"}
                        className={classes.textLabel} variant={"subtitle2"}>{t("caqiLegend.low")}</Typography>
                    <div className={clsx(classes.base, classes.yellowCAQI)}/>
                    <Typography
                        data-testid={"caqi-medium"}
                        className={classes.textLabel} variant={"subtitle2"}>{t("caqiLegend.medium")}</Typography>
                    <div className={clsx(classes.base, classes.orangeCAQI)}/>
                    <Typography
                        data-testid={"caqi-high"}
                        className={classes.textLabel} variant={"subtitle2"}>{t("caqiLegend.high")}</Typography>
                    <div className={clsx(classes.base, classes.redCAQI)}/>
                    <Typography
                        data-testid={"caqi-very-high"}
                        className={classes.lastTextLabel} variant={"subtitle2"}>{t("caqiLegend.very_high")}</Typography>
                </div>
                }
            </div>
        );
};

export default CalendarLegend;
