import React from 'react';
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((
    {
       button:{
           color:"white",
           marginRight:24
       }
    }
));

const CompareStationSelectorButton = ({onButtonPressCallback}) => {

    const {t} = useTranslation()
    const classes = useStyles();

    return (
        <Button
            onClick={onButtonPressCallback}
            className={classes.button}>{t("dashboard.select_stations")}</Button>
    );
};

export default CompareStationSelectorButton;
