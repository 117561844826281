import React from 'react';
import {
    Backdrop,
    Button, Grid, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";

const ConfirmDialog = ({tittle,question, hideDialog,confirm}) => {


    const {t} = useTranslation()

    return (

        <Backdrop data-testid={"confirm-delete-pop-up"} open={true} style={{zIndex:1999}}>
            <Grid container style={{backgroundColor:"#fff", maxWidth:680, padding:30}}>
                <Grid item xs={12}>
                    <Typography variant={"h5"}>{tittle}</Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop:40,marginBottom:40}}>
                    <Typography>{question}</Typography>
                </Grid>
                <Grid item container xs={12} style={{justifyContent:"flex-end"}}>
                    <Button onClick={hideDialog} color="primary" >
                        {t("cancel")}
                    </Button>
                    <Button
                        data-testid={"confirm-delete-button"}
                        onClick={confirm} color="primary">
                        {t("delete")}
                    </Button>
                </Grid>
            </Grid>
        </Backdrop>
    );
};

export default ConfirmDialog;
