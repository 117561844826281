import React, {useCallback} from "react";


export const useAnchorEl = ()=>{

    const [anchorEl, setAnchorEla] = React.useState(null);

    const handleHelpClose = useCallback(()=>{
        setAnchorEla(null)
    },[]);

    const setAnchorEl = useCallback((event)=>{
        setAnchorEla(event)
    },[])

    return {anchorEl,setAnchorEl,handleHelpClose}
}