import React from 'react';
import {CircularProgress, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    progressBase: {
        color:"#bebebe",
        position:"absolute",
        left: 0,
        right: 0,
        top:0,
        bottom:0,
        margin:"auto",
        height:"100%",
        width:"100%"
        },
    progressTop:{
        position:"absolute",
        left: 0,
        right: 0,
        top:0,
        bottom:0,
        margin:"auto",
        height:"100%",
        width:"100%"
    },
    text:{
        textAlign:"center",
        fontSize:11,
        position:"absolute",
        left: 0,
        right: 0,
        top:0,
        bottom:0,
        margin:"auto",
        height:18,
        width:"100%",
    }
    ,
    root:{
        width:46,
        height:46,
        position:"relative",
        justifyContent:"center"
    }
}));

const CircularProgressWithPercentage = (props) => {

    const classes = useStyles();

    return (

                <div className={classes.root}>
                    <Typography variant="caption"  className={classes.text} component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                    <CircularProgress variant="determinate"
                                      size={30}
                                      value={100}
                                      thickness={4}
                                      className={classes.progressBase}
                    />
                    <CircularProgress variant="determinate" value={props.value}
                                      thickness={4}
                                      size={30}
                                      className={classes.progressTop}
                    />
                </div>

    );
};

export default CircularProgressWithPercentage;
