import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const pollutantTrendRequest = (stationId,pollutantUnits,callback) => {
    let url = `station-data/${stationId}/pollutant-trend?pollutants=${pollutantUnits}`
    webClient.get(url)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        })
};