

export const getY4Range = (data) => {
    const y4Data = Object.entries(data).filter(item =>{
        return item[1].units === "mg-m3" || item[1].units === "ppm"
    })
    let max = 0
    y4Data.forEach(pollutant => {
        pollutant[1].y.forEach(yValue => {
            if(yValue>max){
                max = yValue
            }
        })
    })
    if(max<1){
        return [0,2]
    }else {
        return []
    }
}