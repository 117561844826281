import React from 'react';
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    tittle:{
        marginTop:120,
        textAlign:"center",
        color:"#8a8a8a"
    }
});

const InvalidInvitationScreen = ({errorMessage}) => {
    const classes = useStyles();
    return (
        <Box  sx={{
            width: "100%",
            minHeight: 300,
            bgcolor: 'white',
            margin:3,
            borderRadius:1
        }} boxShadow={3} >
                <Typography
                    data-testid =  {"invitation-invalid-text"}
                    className={classes.tittle}  variant={"h4"}>{errorMessage}</Typography>
        </Box>
    );
};

export default InvalidInvitationScreen;
