import {Typography} from "@mui/material";

import {useEffect} from "react";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    box: {
        zIndex:9999,
        position:"absolute",
        top:0,
        left:0,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width:"100vw",
        height:"100vh",
        background:"white",
    }
});

const NotFoundScreen = ({updateNotFound}) => {

    const {t} = useTranslation()

    useEffect(
        ()=>{
            updateNotFound(true);
            return ()=>{
                updateNotFound(false);
            }
        }
        ,[updateNotFound]);

    const classes = useStyles();

    return (
        <div className={classes.box}>
           <Typography data-testid={"not-found-screen-message"}  variant={"h1"}>{t("error_404")}</Typography>
        </div>
    );
};

export default NotFoundScreen;
