import React from 'react';
import {
    Backdrop,
    Card,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((
    {
        card:{
            paddingTop:16,
            paddingBottom:10,
        },
        title:{
            marginLeft:30,
            color:"#434343",
            marginBottom:16
        },
        subTitle:{
            fontSize:20,
            color:"#434343",
            marginBottom:4
        },
        buttons:{
            marginRight:30,
            display:"flex",
            marginTop:10,
            justifyContent:"flex-end"
        },
        divider:{
            marginBottom:32
        },
        divider2:{
            marginTop:8
        },
        popUpContainer:{
            width:520
        },
        detailContainer:{
            paddingRight:20,
            paddingLeft:20,
            maxWidth:500,
            paddingBottom:20
        },
        tableContainer:{
            marginTop:20,
            height:340,
            borderRadius:6,
            background:"#eaeaea",
        }
    }
));

const AlarmStationListPopUp = ({handleCloseDetailCallback,stationIdList}) => {

    const {t} = useTranslation()
    const { stations } = useSelector( state => state.dashboardUI );
    const classes = useStyles();

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <Card className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <Typography variant={"h6"} className={classes.title}>{t("alarmScreen.alarm_station")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid container item xs={12} className={classes.detailContainer} >
                        <Grid item xs={12} >
                            <Typography variant={"h5"} className={classes.subTitle}>{t("alarmScreen.stations")}</Typography>
                            <TableContainer  className={classes.tableContainer}>
                                <Table stickyHeader aria-label="event table" >
                                    <TableBody className={classes.table}>
                                        {stationIdList.map((id) => (
                                            <TableRow key={id}>
                                                <TableCell
                                                    align="left">{stations.find(station => station.id === id).alias}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button onClick={handleCloseDetailCallback}>{t("ok")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default AlarmStationListPopUp;
