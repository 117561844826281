import {
    createDownloadAction,
    deleteDownloadAction, removeProcessingProgressListAction,
    setProcessingProgressListAction,
    updateDownloadStateAction,
    updateProcessingProgressListAction
} from "../reducers/downloadReducer";
import * as Sentry from "@sentry/react";
import {
    createReportAction,
    deleteReportAction,
    removeReportProcessingProgressListAction,
    setReportProcessingProgressListAction,
    updateReportProcessingProgressListAction, updateReportStateAction
} from "../reducers/reportReducer";
import {addAlarmAction, removeAlarmAction} from "../reducers/alarmReducer";
import {
    addNotificationAction,
    removeNotificationByAlarmIdAction,
    removeNotificationByIdAction,
    setNotificationAction
} from "../reducers/notificationReducer";
import {
    updateBattery, updateFW,
    updateLastConnection,
    updateLastData, updateModel,
    updatePosition, updateSerial, updateState
} from "../reducers/dashboardUIReducer";


export const eventMessage = (socket,dispatch,messageEvent,updateDataCallbackRef) =>{

    try{
        const data = JSON.parse(messageEvent.data);
        switch (data.path){
            case "download":
                processDownloadPackage(data,dispatch,updateDataCallbackRef)
                break;
            case "alarms":
                processAlarmPackage(data,dispatch)
                break;
            case "report":
                processReportPackage(data,dispatch,updateDataCallbackRef)
                break;
            case "alarm":
                processAlarmPackage(data,dispatch)
                break;
            case "alarmNotification":
                processNotificationPackage(data,dispatch)
                break;
            case "station":
                processStationPackage(data,dispatch,updateDataCallbackRef);
                break;
            default:
                Sentry.captureMessage(`WebSocket path ${data.path} not supported`);
                break;
        }
    }
    catch (e) {
        Sentry.captureMessage(`Error parsing websocket message:`,messageEvent.data);
    }
}

    const processStationPackage = (data,dispatch,updateDataCallbackRef)=>{
        switch (data.type) {
            case "index":
                updateDataCallbackRef.current("index", data.payload);
                break;
            case "lastConnection":
                dispatch(updateLastConnection(data.payload))
                break;
            case "lastData":
                dispatch(updateLastData(data.payload))
                break;
            case "position":
                dispatch(updatePosition(data.payload))
                break;
            case "battery":
                dispatch(updateBattery(data.payload))
                break;
            case "state":
                dispatch(updateState(data.payload))
                break;
            case "fw":
                dispatch(updateFW(data.payload))
                break;
            case "serial":
                dispatch(updateSerial(data.payload))
                break;
            case "model":
                dispatch(updateModel(data.payload))
                break;
            default:
                Sentry.captureMessage(`WebSocket station path data type ${data.type} not supported`);
                break;
        }
    };

    const processDownloadPackage = (data,dispatch,updateDataCallbackRef)=> {
        switch (data.type) {
            case "init":
                updateDataCallbackRef.current("download",null);
                dispatch(setProcessingProgressListAction(data.payload))
                break;
            case "progress":
                dispatch(updateProcessingProgressListAction(data.payload))
                break;
            case "state":
                if(data.payload.state === "completed" || data.payload.state === "error"){
                    dispatch(removeProcessingProgressListAction([data.payload.id]))
                }
                dispatch(updateDownloadStateAction(data.payload))
                break;
            case "delete":
                dispatch(removeProcessingProgressListAction(data.payload))
                dispatch(deleteDownloadAction(data.payload))
                break;
            case "create":
                dispatch(createDownloadAction(data.payload))
                break;
            default:
                Sentry.captureMessage(`WebSocket download path data type ${data.type} not supported`);
                break;
        }
    }

    const processReportPackage = (data,dispatch,updateDataCallbackRef) =>{
        switch (data.type) {
            case "init":
                updateDataCallbackRef.current("report",null)
                dispatch(setReportProcessingProgressListAction(data.payload))
                break;
            case "progress":
                dispatch(updateReportProcessingProgressListAction(data.payload))
                break;
            case "state":
                if(data.payload.state === "completed" || data.payload.state === "error"){
                    dispatch(removeReportProcessingProgressListAction([data.payload.id]))
                }
                dispatch(updateReportStateAction(data.payload))
                break;
            case "delete":
                dispatch(removeReportProcessingProgressListAction(data.payload))
                dispatch(deleteReportAction(data.payload))
                break;
            case "create":
                dispatch(createReportAction(data.payload))
                break;
            default:
                Sentry.captureMessage(`WebSocket report path data type ${data.type} not supported`);
                break;
        }
    }

    const processAlarmPackage = (data,dispatch)=>{
        switch (data.type) {
            case "delete":
                dispatch(removeAlarmAction(data.payload))
                dispatch(removeNotificationByAlarmIdAction(data.payload))
                break;
            case "create":
                dispatch(addAlarmAction(data.payload))
                break;
            default:
                Sentry.captureMessage(`WebSocket report path data type ${data.type} not supported`);
                break;
        }
    }

    const processNotificationPackage =(data,dispatch)=>{
        switch (data.type) {
            case "init":
                dispatch(setNotificationAction(data.payload))
                break;
            case "delete":
                dispatch(removeNotificationByIdAction(data.payload))
                break;
            case "create":
                dispatch(addNotificationAction(data.payload))
                break;
            default:
                Sentry.captureMessage(`WebSocket report path data type ${data.type} not supported`);
                break;
        }
    }
