import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import validator from 'validator';
import {forgotRequest} from "../../../../requests/auth/authRequests";
import {useDispatch} from "react-redux";
import {
    setRequestError,
    setSuccessRequest,
    startLoadingAction,
    stopLoadingAction
} from "../../../../reducers/requestUIReducer";
import {useTranslation} from "react-i18next";

export default function ForgotDialog({showForgotDialog,setForgotState,serverErrorCallback}) {

    const initialState = {
        email:'',
        validCredentials:false
    }

    const dispatch = useDispatch()
    const [state, setState] = useState(initialState);
    const {email,validCredentials} = state;
    const { t } = useTranslation();

    const handleRecoverPassword = () => {
        dispatch(startLoadingAction())
        forgotRequest({mail:email.toLowerCase()},(err,data)=>{
            dispatch(stopLoadingAction())
            if(!err){
                dispatch(setSuccessRequest(t('forgot.success')));
            }else{
                switch (data.status) {
                    case 404:
                        dispatch(setRequestError(t('forgot.not_user_found')))
                        break;
                    case 400:
                        dispatch(setRequestError(t('forgot.invalid_email')));
                        break;
                    case 500:
                        serverErrorCallback();
                        break;
                    default:
                        dispatch(setRequestError(t('forgot.unknown')));
                        break;
                }
            }
            setState(initialState);
            setForgotState( oldState => ({...oldState,showForgotDialog:false}));
        })
    };

    const handleEmail =( event)=>{
        const value = event.target.value;
        setState({email: value,validCredentials:validator.isEmail(value)});
    };

    const handleClose = ()=>{
        setState(initialState);
        setForgotState( oldState => ({...oldState,showForgotDialog:false}));
    }

    return (
        <Dialog open={showForgotDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('forgot.forgot')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('forgot.enter_email')}
                </DialogContentText>
                <TextField
                    label={t('forgot.email')} variant="outlined"
                    required autoComplete="email" autoFocus fullWidth  margin="normal"
                    onChange={handleEmail} value={email}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!validCredentials}
                    onClick={handleRecoverPassword} color="primary">
                    {t('forgot.reset_password')}
                </Button>
            </DialogActions>
        </Dialog>

    );
}