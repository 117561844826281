export const handleError = (error, callback) => {
    if (error.response) {
        callback(true,{status: error.response.status, error: error.response.data});
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an mapGenerator of XMLHttpRequest in the browser and an mapGenerator of
        // http.ClientRequest in node.js
        callback(true,{status: 500, error: 'Service could not be reached'});
    } else {
        // Something happened in setting up the request that triggered an Error
        callback(true,{status: 500, error: 'Service could not be reached'});
    }
};