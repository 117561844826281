import React from 'react';
import {Card, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import box from "../../../../assets/empty_box.png"
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    notFound:{
        display:"flex",
        height:"100%",
        width:"100%",
        flexDirection:"column",
        justifyContent:"center",
        alignContent:"center",
        alignItems:"center",
    },
    text:{
        color:"#dddddd"
    },
    img:{
        width :180,
        paddingBottom : 10,
        opacity: 0.9
    },
    card:{
        background:"white",
        padding:20,
        borderRadius: 5,
        height:600
    }
}));

const NoStationSelected = () => {

    const {t}  = useTranslation();
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <div className={classes.notFound}>
                <img className={classes.img} src={box} alt="logo"/>
                <Typography variant={"h5"} align={"center"} className={classes.text} >
                    {t("compareScreen.noStationSelected")}
                </Typography>
            </div>
        </Card>
    );
};

export default NoStationSelected;
