import {getVariableName} from "../components/routes/private/pollutantNames";

const sensorTypes = [
    "NO2", "NO", "O3", "CO2", "CO", "SO2", "H2S", "NH3", "PM1", "PM10", "PM4", "PM2P5", "temperature", "relativeHumidity",
    "noise", "pressure", "VOC_IAQ"
];

export const sortBySensorType = (dataArray, typeGetter) => {
    return dataArray.sort((a, b) => sensorTypes.indexOf(typeGetter(a)) - sensorTypes.indexOf(typeGetter(b)));
};

export const getAvailableSensors = (stationList) => {
    const output = [];
    stationList.forEach(station => {
        station.sensors.forEach((sensorId) => {
            const sensorEntry = output.find((item) => item.id === sensorId);
            if (sensorEntry === undefined && sensorTypes.includes(sensorId)) {
                output.push({id: sensorId, alias: getVariableName(sensorId)});
            }
        });
    });

    return sortBySensorType(output, (sensor) => sensor.id);
};