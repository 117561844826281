import React, {useEffect, useState} from 'react';
import {Card, Grid, IconButton, Link, TextField, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import ClearIcon from '@mui/icons-material/Clear';
import {useHistory} from "react-router-dom";


const useStyles = makeStyles({
    currentUser: {
        '& .super-app.current_user': {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {},
    },
    deleteButton: {
        color: "#ffffff",
        background: "#f6343d",
        '&:hover': {
            background: "#81191c"
        },
    },
    subtitle: {
        color: "#8a8a8a"
    },
    organizationName: {
        fontWeight: "bold"
    },
    rowTable: {
        display: "flex",
        width: "100%vw",
        justifyContent: "center",
        margin: 0,
    },
    table: {
        maxWidth: 1600,
        height: 480,
        '& .super-app.current_user': {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {},
    },
    clickable: {
        color: "#1A23D0",
        '&:hover': {
            textDecoration: "underline",
            cursor: "pointer"
        }
    }
});

const OrganizationNodes = ({className}) => {

    const {t} = useTranslation();
    const {stations} = useSelector(state => state.dashboardUI);
    let history = useHistory();

    const [{currentStations, searchField,page}, updateState] = useState({currentStations: [], searchField: "",page:0})

    const classes = useStyles();

    const openMap = (position) => {
        history.push(`/home?lat=${position.lat}&long=${position.long}&zoom=16`);
    }

    const openAnalytics = (deviveId) => {
        history.push(`/analytics/${deviveId}`);
    }

    const columns = [
        {field: 'id', headerName: 'ID', hide: true},
        {field: "deviceId", headerName: t("organizationNodes.deviceId"), width: 240},
        {field: "serial", headerName: t("organizationNodes.serial"), width: 240},
        {field: "alias", headerName: t("organizationNodes.alias"), width: 240},
        {
            field: "location", headerName: t("organizationNodes.location"), width: 240,
            renderCell: (params) => (
                (params.row?.position?.lat === undefined || params.row?.position?.long === undefined) ?
                    <Typography>--</Typography> :
                    <Typography
                        onClick={_ => {
                            openMap(params.row.position)
                        }}
                        className={classes.clickable}>{params.value}</Typography>
            )
        },
        {
            field: "data", headerName: t("organizationNodes.data"), width: 240,
            renderCell: (params) => (
                <Link onClick={event => {
                    openAnalytics(params.row.deviceId)
                }}>{t("organizationNodes.view")}</Link>
            )

        }
    ];

    useEffect(() => {
        if (currentStations.length === 0) {
            updateState(state => ({
                ...state, currentStations: stations.map((item, index) => {
                    return {
                        id: index,
                        deviceId: item.id,
                        alias: item.alias,
                        serial: item.serial,
                        position: item.position,
                        location: `${item?.position?.lat}, ${item?.position?.long}`
                    }
                })
            }));
        }
    }, [currentStations, stations])

    const handleSearchFieldChange = (event) => {
        updateState(state => ({...state, searchField: event.target.value,page: 0}));
    }

    const clearFilter = () => {
        updateState(state => ({...state, searchField: ""}));
    }

    const onPageChange = (newPage,detail)=>{
        updateState(state=>({...state,page:newPage.page}));
    }


    return (
        <>
            <Card className={className}>
                <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <Typography className={classes.organizationName}
                                    variant={"h4"}>{t("organizationNodes.nodes")}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <div style={{display: "flex", marginBottom: 12, alignSelf: "start", marginLeft: 32}}>
                            <TextField
                                style={{width: 600}}
                                autoFocus
                                margin={"none"}
                                value={searchField}
                                onChange={handleSearchFieldChange}
                                label={t("organizationNodes.filter")}/>
                            {searchField !== "" && <IconButton color={"primary"} size="small"
                                                               onClick={event => {
                                                                   clearFilter()
                                                               }}>
                                <ClearIcon/>
                            </IconButton>}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={classes.rowTable}>
                        <DataGrid
                            loading={false}
                            page={page}
                            onPageChange={onPageChange}
                            className={classes.table}
                            rows={searchField === "" ? currentStations : currentStations.filter(item => {
                                return item?.alias.toLowerCase()?.includes(searchField.toLowerCase()) ||
                                    item?.serial?.toLowerCase()?.includes(searchField.toLowerCase()) ||
                                    item?.deviceId?.toLowerCase()?.includes(searchField.toLowerCase())
                            })}
                            columns={columns.map((column) => {
                                    return {
                                        ...column, disableClickEventBubbling: column.field === "registered"
                                    }
                                }
                            )}
                            columnBuffer={8}
                            pageSize={7}
                            Name="dataGrid1"
                            density={"standard"}
                        />
                    </Grid>
                </Grid>
            </Card>
        </>
    );
};

export default OrganizationNodes;
