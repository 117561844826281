import type from "./action_types/type";


const initialState = {
    drawerOpen:false,
    stations:[],
    selectedStation:null,
    selectCompareStations:[]
};


export const dashboardUIReducer = (state = initialState, action) =>{
    switch (action.type){
        case type.updateCompareStation:
            return {...state,selectCompareStations:action.payload}
        case type.updateDashboardDrawerVisibility:
            return {...state,drawerOpen:action.payload}
        case type.updateStation:
            return {...state,stations: action.payload}
        case type.updateSelectedStation:
            return {...state,selectedStation: action.payload}

        case type.updateAQI:
            let updatedAqiStations = state.stations.map(obj => {
                let payLoadStation = action.payload.data.find(it => it.id === obj.id)

                if(payLoadStation !== undefined){
                    obj.index = action.payload.currentUnits === "aqi" ? payLoadStation.index.aqi : payLoadStation.index.caqi
                }
                return obj;
            });
            return {...state,stations: updatedAqiStations}

        case type.updateLastConnection:
            let updatedLastConnectionStations = state.stations.map(obj => {
                let payLoadStation = action.payload.find(it => it.id === obj.id)
                if(payLoadStation !== undefined){
                    obj.lastConnection = payLoadStation.lastConnection
                }
                return obj;
            });
            return {...state,stations: updatedLastConnectionStations}

        case type.updateLastData:
            let updatedLastDataStations = state.stations.map(obj => {
                let payLoadStation = action.payload.find(it => it.id === obj.id)
                if(payLoadStation !== undefined){
                    obj.lastData = payLoadStation.lastData
                }
                return obj;
            });
            return {...state,stations: updatedLastDataStations}

        case type.updatePosition:
            let updatedLastPositionStations = state.stations.map(obj => {
                let payLoadStation = action.payload.find(it => it.id === obj.id)
                if(payLoadStation !== undefined){
                    obj.position = payLoadStation.position
                }
                return obj;
            });
            return {...state,stations: updatedLastPositionStations}


        case type.updateBattery:
            let updatedBatteryStations = state.stations.map(obj => {
                let payLoadStation = action.payload.find(it => it.id === obj.id)
                if(payLoadStation !== undefined){
                    obj.battery = payLoadStation.battery
                }
                return obj;
            });
            return {...state,stations: updatedBatteryStations}

        case type.updateState:
            let updatedStateStations = state.stations.map(obj => {
                let payLoadStation = action.payload.find(it => it.id === obj.id)
                if(payLoadStation !== undefined){
                    obj.state = payLoadStation.state
                }
                return obj;
            });
            return {...state,stations: updatedStateStations}

        case type.updateFW:
            let updatedFWStations = state.stations.map(obj => {
                let payLoadStation = action.payload.find(it => it.id === obj.id)
                if(payLoadStation !== undefined){
                    obj.fw = payLoadStation.fw
                }
                return obj;
            });
            return {...state,stations: updatedFWStations}

        case type.updateSerial:
            let updatedSerialStations = state.stations.map(obj => {
                let payLoadStation = action.payload.find(it => it.id === obj.id)
                if(payLoadStation !== undefined){
                    obj.serial = payLoadStation.serial
                }
                return obj;
            });
            return {...state,stations: updatedSerialStations}

        case type.updateModel:
            let updatedModelStations = state.stations.map(obj => {
                let payLoadStation = action.payload.find(it => it.id === obj.id)
                if(payLoadStation !== undefined){
                    obj.model = payLoadStation.model
                }
                return obj;
            });
            return {...state,stations: updatedModelStations}

        case type.updateAlias:
            let newStations = state.stations.map(obj => {
                if(obj.id === action.payload[0]){
                    obj.alias = action.payload[1]
                }
                return obj;
            });
            return {...state,stations: newStations}
        default:
            return state;
    }
};


export const updateAQIAction = (payload,currentUnits)=>({
    type:type.updateAQI,
    payload:{data:payload,currentUnits}
});

export const updateLastConnection = (payload)=>({
    type:type.updateLastConnection,
    payload
});

export const updateLastData = (payload)=>({
    type:type.updateLastData,
    payload
});

export const updatePosition = (payload)=>({
    type:type.updatePosition,
    payload
});

export const updateBattery = (payload)=>({
    type:type.updateBattery,
    payload
});

export const updateState = (payload)=>({
    type:type.updateState,
    payload
});

export const updateFW = (payload)=>({
    type:type.updateFW,
    payload
});

export const updateSerial = (payload)=>({
    type:type.updateSerial,
    payload
});

export const updateModel = (payload)=>({
    type:type.updateModel,
    payload
});


export const updateCompareStationsAction = (stationsList)=>({
    type:type.updateCompareStation,
    payload:stationsList
});

export const updateStationsAction = (stations) =>({
    type:type.updateStation,
    payload:stations
});

export const updateAliasAction = (deviceId,alias) =>({
    type:type.updateAlias,
    payload:[deviceId,alias]
});

export const updateSelectedStationAction = (stationId) =>({
    type:type.updateSelectedStation,
    payload:stationId
});

export const updateDashboardDrawerAction = (open) =>({
    type:type.updateDashboardDrawerVisibility,
    payload:open
});