import React, {useCallback, useEffect, useState} from 'react';
import {
    Card,
    CardContent,
    Grid
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import HelpPopup from "../common/HelpPopup";
import CalendarComponent from "./CalendarComponent";
import CalendarMonthSelector from "./CalendarMonthSelector";
import CalendarDaysTittle from "./CalendarDaysTittle";
import {predominantPollutantRequest} from "../../../../../requests/analytics/predominantPollutantRequest";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ANALYTICS_ROUTE} from "../../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import CardTittle from "../common/CardTittle";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import CalendarLegend from "./CalendarLegend";
import CardLoading from "../common/CardLoading";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    calendarTittle:{
        marginTop:8
    },
    tittle:{
        display:"flex",
        alignItems:"center"
    },
});

const AQICalendarCardView = ({className}) => {

    const {t} = useTranslation()
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const isMounted = useMountComponent();
    const { units } = useSelector( state => state.auth );
    const { selectedStation } = useSelector( state => state.dashboardUI );
    const [{data,selectedDate,loading},updateState] =
        useState({data:[],loading:false,selectedDate: new Date(),error:false});


    const fetchAQIData = useCallback(() =>{
        updateState(state =>{
            return {...state,data:[],loading: true,error:false}
        });
        predominantPollutantRequest(selectedStation,selectedDate,units.index,(err,data) =>{
            if (!isMounted.current) {return}
            if (!err){
               updateState(state =>{
                   return {...state,data,loading: false,error:false}
               });
            } else {
                updateState(state =>{
                    return {...state,data:[],loading: false,error:true}
                });
                switch (data.status){
                    case 401:
                        expiredSession(ANALYTICS_ROUTE)(dispatch)
                        break;
                    case 404:
                        enqueueSnackbar(t("analyticScreen.calendar.data_not_found"),{ variant:"info" });
                        break;
                    case 400:
                        enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("analyticScreen.calendar.could_not_update_calendar_invalid_date")}`,{ variant:"error" });
                        break;
                    default:
                        enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("analyticScreen.calendar.could_not_update")}`,{ variant:"error" });
                        break;
                }
            }
        });
    },[t,dispatch,units.index,isMounted,enqueueSnackbar,selectedStation,selectedDate]);

    useEffect(()=>{
        fetchAQIData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedDate,selectedStation]);

    const handleDateChange  = useCallback((value)=>{
        updateState( state =>{
            return {...state,selectedDate:value}
        })
    },[]);

    const classes = useStyles();

    return (
        <Card className={className}>
            {!loading &&<CardContent>
                <Grid container>
                    <Grid container item xs={12}  direction={"row"}   justifyContent="space-between" >
                        <Grid>
                            <div className={classes.tittle}>
                            <CardTittle tittle={units.index === "aqi" ? t("analyticScreen.calendar.aqi_predominant") :
                                t("analyticScreen.calendar.caqi_predominant")}
                                        setAnchorEl={setAnchorEl}/>
                            </div>
                        </Grid>
                        <Grid>
                            <CalendarMonthSelector
                                                   date={selectedDate}
                                                   handleDateChange={handleDateChange}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.calendarTittle}>
                        <CalendarDaysTittle/>
                    </Grid>
                    <Grid item xs={12}>
                        <CalendarComponent data={data} date={selectedDate} indexType={units.index}/>
                    </Grid>
                    <Grid item xs={12}>
                        <CalendarLegend indexType={units.index}/>
                    </Grid>
                </Grid>
            </CardContent>
            }
            {loading &&
            <CardLoading/>
            }
            <HelpPopup anchorEl={anchorEl}
                       handleHelpClose={handleHelpClose}
                       message={t("analyticScreen.calendar.en_analytics_calendar")}
                       halfCard={true}/>
        </Card>
    );
}

export default AQICalendarCardView;
