import "./map_style.css"
import React, {useCallback, useEffect, useRef} from 'react';
import * as Sentry from "@sentry/react";
import {makeStyles} from "@mui/styles";
import {drawerWidth} from "../dashboard/Dashboard";
import clsx from "clsx";
import {useSelector} from "react-redux";
import ReactDOM from "react-dom";
import NodeDetail from "./NodeDetail";
import {useHistory, useLocation} from "react-router-dom";
import MapLegend from "./legend/MapLegend";
import mapBoxGL from 'mapbox-gl';
import {isStationRedrawRequired} from "./util/stationDiffUtil";
import {
    DARK_GRAY,
    DARK_PURPLE_AQI, GRAY,
    GREEN_AQI,
    GREEN_CAQI,
    GREEN_YELLOW_CAQI,
    ORANGE_AQI, ORANGE_CAQI,
    PURPLE_AQI,
    RED_AQI, RED_CAQI, TEXT_COLOR_CONTRAST,
    YELLOW_AQI,
    YELLOW_CAQI
} from "./legend/colors";
import {HOME_ROUTE, INDEX_AQI, INDEX_CAQI, MODEL_EXTERNAL_PREFIX, STATION_STATE_OFFLINE} from "../../../../constants";
import getIconStyle from "./util/getIconStyle";
import styles from './HomeScreen.module.scss';

const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
    rootHeight: {
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        '@media (min-width:0px) and (orientation: landscape)': {
            height: `calc(100vh - 48px)`
        },
        '@media (min-width:600px)': {
            height: `calc(100vh - 64px)`
        }
    },
    rootWidth: {
        width: `calc(100vw - ${theme.spacing(7)})`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100vw - ${theme.spacing(9)})`
        }
    },
    rootWidthOpen: {
        width: `calc(100vw - ${drawerWidth}px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100vw - ${drawerWidth}px)`
        }
    }
}));

const HomeScreen = () => {

    const location = useLocation();
    const history = useHistory();
    const {drawerOpen} = useSelector(state => state.dashboardUI);
    const {stations} = useSelector(state => state.dashboardUI);
    const {units, organization} = useSelector(state => state.auth);

    const previousStation = useRef("");

    const navigateToAnalyticsCallback = useCallback((deviveId) => {
        history.push(`/analytics/${deviveId}`);
    }, [history]);

    const usePopUp = useRef(makeStyles(({
        popUp: {
            zIndex: 1999,
        }
    })));

    const classes = useStyles();
    const popUpClass = useRef(usePopUp.current());
    const mapRef = useRef(null);
    const markers = useRef(null);
    const detailRef = useRef(null);

    const updateDetailScreen = useCallback(({ deviceId, domElement }) => {
        const device = stations.find(element => element.id === deviceId);
        ReactDOM.render(
            <NodeDetail index={units.index} device={device} deviceSelectedCallback={navigateToAnalyticsCallback}/>,
            domElement
        );
    }, [units.index, stations, navigateToAnalyticsCallback])

    const showDetail = useCallback((deviceId, position) => {
        let markerHeight = 20, markerRadius = 12, linearOffset = 25;
        let popupOffsets = {
            'top': [0, markerHeight],
            'top-left': [0, 0],
            'top-right': [0, 0],
            'bottom': [0, -markerHeight],
            'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
            'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
            'left': [markerRadius, (markerHeight - markerRadius) * -1],
            'right': [-markerRadius, (markerHeight - markerRadius) * -1]
        };

        const detailScreen = document.createElement('div');
        detailScreen.id = 'detailScreen';
        updateDetailScreen({ deviceId, domElement: detailScreen });

        detailRef.current?.remove();
        detailRef.current = new mapBoxGL.Popup({
            className: popUpClass.current.popUp, maxWidth: 600,
            closeOnClick: false, offset: popupOffsets, focusAfterOpen: false
        })
            .setLngLat([position.long, position.lat])
            .setDOMContent(detailScreen)
            .addTo(mapRef.current);
    }, [updateDetailScreen, popUpClass]);

    useEffect(() => {

        const parsedValues = queryString.parse(location.search);

        // Reset location
        history.push(HOME_ROUTE);

        mapBoxGL.accessToken = 'pk.eyJ1Ijoiam1zYWJpbjEzMjciLCJhIjoiY2ttZWx0dHZhMndmbDJ4a25sazFiOWs5ZiJ9.bm1M1koxEnJI_jwx5OCbyQ';
        mapRef.current = new mapBoxGL.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/light-v10',
            boxZoom: true
        });

        const zoom = parsedValues.zoom !== undefined ? parsedValues.zoom : organization.map.zoom;
        try {
            mapRef.current?.setZoom(zoom);
        } catch {
            Sentry.captureMessage(`Invalid zoom value: ${JSON.stringify(zoom)}`);
            mapRef.current?.setZoom(1);
        }

        const center = (parsedValues.long !== undefined && parsedValues.lat !== undefined) ?
            [parsedValues.long, parsedValues.lat] : [organization.map.center.lon, organization.map.center.lat];
        try {
            mapRef.current?.setCenter(center);
        } catch {
            Sentry.captureMessage(`Invalid position value: ${JSON.stringify(center)}`);
            mapRef.current?.setZoom(1);
        }

        mapRef.current?.addControl(new mapBoxGL.NavigationControl());
        mapRef.current?.dragRotate.disable();

        mapRef.current?.on('moveend', () => {
            if (mapRef.current) {
                const { lat, lng } = mapRef.current.getCenter();
                const zoom = mapRef.current.getZoom();
                const position = [lat, lng, zoom];
                localStorage.setItem('lastPosition', position.join(';'));
            }
        });

        return () => {
            mapRef.current = null
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        mapRef.current?.resize();
    }, [drawerOpen]);

    const applyMarkerStyle = useCallback((el, { model, index, state }) => {
        const value = index;
        const isExternal = model.startsWith(MODEL_EXTERNAL_PREFIX);
        const markerSize = 20;
        const iconPadding = isExternal ? 8 : 4;
        const borderRadius = isExternal ? 0 : '100%';

        const subEl = document.createElement('div');
        el.appendChild(subEl);

        el.style.width = `${markerSize}px`;
        el.style.height = `${markerSize}px`;
        el.style.display = 'flex';
        el.style.alignItems = 'center';
        el.style.justifyContent = 'center';

        let backgroundColor = '';
        let backgroundUrl = '';
        let borderColor = '';
        let textColor = '';
        let opacity = '1';

        if (state === STATION_STATE_OFFLINE) {
            subEl.innerText = "!";
            textColor = TEXT_COLOR_CONTRAST;
            backgroundColor = DARK_GRAY;
            opacity = 0.8;
        } else if (value === undefined) {
            subEl.innerText = "?";
            textColor = TEXT_COLOR_CONTRAST;
            backgroundColor = GRAY;
        } else if (units.index === INDEX_AQI) {
            if (value <= 50) {
                backgroundColor = GREEN_AQI;
            } else if (value <= 100) {
                backgroundColor = YELLOW_AQI;
            } else if (value <= 150) {
                backgroundColor = ORANGE_AQI;
            } else if (value <= 200) {
                backgroundColor = RED_AQI;
            } else if (value <= 300) {
                backgroundColor = PURPLE_AQI;
            } else {
                backgroundColor = DARK_PURPLE_AQI;
            }
        } else if (units.index === INDEX_CAQI) {
            if (value <= 25) {
                backgroundColor = GREEN_CAQI;
            } else if (value <= 50) {
                backgroundColor = GREEN_YELLOW_CAQI;
            } else if (value <= 75) {
                backgroundColor = YELLOW_CAQI;
            } else if (value <= 100) {
                backgroundColor = ORANGE_CAQI;
            } else if (value > 100) {
                backgroundColor = RED_CAQI;
            }
        }

        const iconSize = backgroundColor ? markerSize - iconPadding : markerSize;
        const style = getIconStyle({
            iconSize, backgroundUrl, backgroundColor, borderRadius, borderColor, textColor, opacity
        });
        Object.assign(subEl.style, style);

        return el;
    }, [units.index]);

    useEffect(() => {
        if (isStationRedrawRequired(previousStation.current,stations)) {
            const stationsWithLocation = stations.filter(station => station.position !== undefined)
            markers.current?.forEach(value => {
                if (value !== undefined) {
                    value.remove();
                }
            })

            markers.current = stationsWithLocation.map(value => {
                let el = document.createElement('div');
                el.id = value.id;
                el.alias = value.alias;
                el.position = value.position;
                applyMarkerStyle(el, value);

                el.addEventListener('click', function () {
                    showDetail(el.id, el.position);
                });

                const marker = new mapBoxGL.Marker(el);
                try {
                    return marker.setLngLat({lng: value.position.long, lat: value.position.lat}).addTo(mapRef.current);
                } catch {
                    Sentry.captureMessage(`Invalid position value in station ${value.id}: ${JSON.stringify(value?.position)}`);
                    return null;
                }
            }).filter(item => !!item); // Marker is not null
        }
        previousStation.current = JSON.stringify(stations);
    }, [applyMarkerStyle, showDetail, stations])


    return (
        <div>
            <div className={"no-border-map"}>
                <div className={clsx(styles.root, classes.rootHeight, drawerOpen ? classes.rootWidthOpen : classes.rootWidth)} id="map"/>
            </div>
            <MapLegend indexType={units.index}/>
        </div>
    );
};

export default HomeScreen;
