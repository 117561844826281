import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const noiseDataRequest = (stationId,init,end,callback) => {
    let params = end ? `?time=${init}&endtime=${end}` : `?time=${init}`
    let url = `/station-data/${stationId}/noise/${params}`
    webClient.get(url)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        })
};