import type from "./action_types/type";

const initialState = {
    notifications: []
}

export const notificationReducer = (state = initialState, action) =>{
    switch (action.type){
        case type.addNotificationAction:
            return state.notifications.filter(notification => notification.id === action.payload.id).length === 0 ?
                {notifications: [...state.notifications,{...action.payload}]} : {notifications: [...state.notifications]}
        case type.removeNotificationByIdAction:
            return {notifications: state.notifications.filter(notification => notification.id !== action.payload)}
        case type.removeNotificationByAlarmIdAction:
            return {notifications: state.notifications.filter(notification => notification.alarmId !== action.payload)}
        case type.setNotificationsAction:
            return {notifications: [...action.payload]}
        default:
            return state;
    }
}

export const addNotificationAction = function (notificationObject){
    return {
        type:type.addNotificationAction,
        payload:notificationObject
    };
}

export const removeNotificationByIdAction = function (id){
    return {
        type:type.removeNotificationByIdAction,
        payload:id
    };
}

export const removeNotificationByAlarmIdAction = function (alarmId){
    return {
        type:type.removeNotificationByAlarmIdAction,
        payload:alarmId
    };
}

export const setNotificationAction = function (notificationList){
    return {
        type:type.setNotificationsAction,
        payload:notificationList
    };
}