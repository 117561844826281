import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import HomeScreen from "./home/HomeScreen";
import NotFoundScreen from "../public/not_found/NotFoundScreen";
import CompareScreen from "./compare/CompareScreen";
import DownloadScreen from "./download/DownloadScreen";
import AlarmsScreen from "./alarms/AlarmsScreen";
import AnalyticScreen from "./analytics/AnalyticScreen";
import {PrivateRoute} from "./PrivateRoute";
import OrganizationScreen from "./organization/OrganizationScreen";
import {
    ALARM_ROUTE,
    ANALYTICS_ROUTE,
    COMPARE_ROUTE,
    DOWNLOAD_ROUTE, HOME_ROUTE,
    ORGANIZATION_ROUTE,
    PROFILE_ROUTE, REPORT_ROUTE
} from "../../../constants";
import ReportScreen from "./report/ReportScreen";
import ProfileScreen from "./profile/ProfileScreen";


export const PrivateRoutesTree = ({isAuthenticated,updateNotFound})=>{

    return (
             <Switch>
                 <PrivateRoute isAuthenticated={isAuthenticated}
                               exact path={HOME_ROUTE} component={HomeScreen}/>
                 <PrivateRoute isAuthenticated={isAuthenticated} exact path={ANALYTICS_ROUTE} component={AnalyticScreen}/>
                 <PrivateRoute isAuthenticated={isAuthenticated} exact path={COMPARE_ROUTE} component={CompareScreen}/>
                 <PrivateRoute isAuthenticated={isAuthenticated} exact path={DOWNLOAD_ROUTE} component ={DownloadScreen}/>
                 <PrivateRoute isAuthenticated={isAuthenticated} exact path={REPORT_ROUTE} component ={ReportScreen}/>
                 <PrivateRoute isAuthenticated={isAuthenticated} exact path={ALARM_ROUTE} component ={AlarmsScreen}/>
                 <PrivateRoute isAuthenticated={isAuthenticated} exact path={PROFILE_ROUTE} component ={ProfileScreen}/>
                 <PrivateRoute isAuthenticated={isAuthenticated} exact path={ORGANIZATION_ROUTE} component ={OrganizationScreen}/>
                 <Route exact path="/">
                     <Redirect to={HOME_ROUTE} />
                 </Route>
                 <Route  path={"*"}
                         render={() => (
                             <NotFoundScreen updateNotFound = {updateNotFound}/>
                         )}
                 />
              </Switch>
    );
}


