import React, {useCallback, useEffect, useState} from 'react';
import {
    Card,
    Grid,
    IconButton, ToggleButton, ToggleButtonGroup,
} from "@mui/material";
import Plot from 'react-plotly.js';
import {makeStyles} from "@mui/styles";
import './chart_style.css';
import {useSnackbar} from 'notistack';
import RefreshIcon from '@mui/icons-material/Refresh';
import {useDispatch, useSelector} from "react-redux";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ANALYTICS_ROUTE, DEFAULT_PERIOD} from "../../../../../constants";
import HelpPopup from "../common/HelpPopup";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import PeriodSelector, {CUSTOM_RANGE} from "../common/PeriodSelector";
import CardTittle from "../common/CardTittle";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import {pollutantNames} from "../../pollutantNames";
import DateRangeComponent from "../../../../common/dateRange/DateRangeComponent";
import useDateRange from "../../../../../hooks/useDateRange";
import {roundAccurately} from "../../../../../utils/roundNumbers";
import DataNotFound from "../../../../common/DataNotFound";
import ChartLoading from "../common/ChartLoading";
import ErrorFetchingDataMessage from "../common/ErrorFetchingDataMessage";
import {useTranslation} from "react-i18next";
import {copyVisibility, toLocalTimeZone} from "../../../../../utils/timeZoneUtil";
import {vocIaqDataRequest} from "../../../../../requests/analytics/vocIaqRequest";

const baseSensorData = {
    type: 'scatter'
}


const initialPlotLayout = {
    legend: {x: -0.25, y: 0.9},
    yaxis: {
        title: "IAQ",
    },
    xaxis: {showgrid: false},
};

const plotConfig = {
    modeBarButtonsToRemove: ["select2d", "lasso2d",
        "toggleHover", "sendDataToCloud", "toggleSpikelines",
    ],
    displaylogo: false
};


const useStyles = makeStyles({
    loading: {
        left: "50%",
        position: "relative",
        top: "50%",
        zIndex: 999
    },
    rightControls: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    refreshButton: {
        marginLeft: "auto",
        color: "gray"
    }
});

const initialState = {
    data: [],
    localData: [],
    error: "",
    period: DEFAULT_PERIOD,
    loading: true,
    timeZone: "local"
}

const VocIaqCardView = ({className}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [{
        data, period, loading, error, timeZone, localData
    }, updateState] = useState(initialState)
    const {anchorEl, setAnchorEl, handleHelpClose} = useAnchorEl();
    const {enqueueSnackbar} = useSnackbar();
    const isMounted = useMountComponent();
    const {selectedStation} = useSelector(state => state.dashboardUI);
    const [{openDateRangePicker, dateRange}, updateOpenDatePickerCallback,
        updateDatePickedCallback, clearDataRange] = useDateRange();

    useEffect(() => {
        if (timeZone === "utc") {
            updateState(state => ({...state, data: copyVisibility(state.localData, state.data)}))
        } else {
            updateState(state => ({...state, localData: copyVisibility(state.data, state.localData)}))
        }
    }, [timeZone])


    useEffect(
        () => {
            if (dateRange != null) {
                updateData(CUSTOM_RANGE);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dateRange]);

    useEffect(() => {
        updateData(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStation])

    const updateData = useCallback((selectedPeriod) => {

        let currentPeriod = selectedPeriod || period

        updateState(state => {
            return {...state, loading: true, data: [], localData: [], error: "", period: currentPeriod}
        });

        let selectedRange = getDateRange(currentPeriod, dateRange);
        vocIaqDataRequest(selectedStation,
            selectedRange[0],
            selectedRange[1], (err, data) => {
                if (!isMounted.current) {
                    return
                }
                if (!err) {
                    let dataArray = Object.entries(data).filter(item => item[0] === "VOC_IAQ")
                    let newData = dataArray.map((value, index) => {
                        let y = value[1].y.map(item => {
                            return roundAccurately(item, 2);
                        });
                        let adaptedData = {...value[1], y};
                        return {
                            name: ` ${pollutantNames.get(value[0])} - IAQ`,
                            ...adaptedData,
                            ...baseSensorData,
                            hoverlabel: {namelength: 0},
                            hovertemplate: `<b>${pollutantNames.get(value[0])}</b>: %{y}`,
                            marker: {color: "#D01A55"},
                            yaxis: "y1"
                        };
                    });
                    if (newData.length > 0) {
                        updateState(state => {
                            return {
                                ...state, data: newData,
                                localData: toLocalTimeZone(newData),
                                loading: false,
                            }
                        });
                    } else {
                        updateState(state => {
                            return {...state, data: [], localData: [], loading: false,}
                        });
                    }
                } else {
                    if (data.status === 404) {
                        updateState(state => {
                            return {...state, loading: false}
                        });
                        enqueueSnackbar(t("analyticScreen.voc_iaq.data_not_found"), {variant: "info"});
                    } else {
                        updateState(state => {
                            return {...state, loading: false, error: data.status}
                        });
                        if (data.status === 401) {
                            expiredSession(ANALYTICS_ROUTE)(dispatch)
                        } else {
                            enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("analyticScreen.voc_iaq.could_not_update_data")}`, {variant: "error"});
                        }
                    }
                }
            });
    }, [t, dispatch, dateRange,isMounted, period, selectedStation, enqueueSnackbar]);


    const handleSelectorChange = useCallback((event) => {
        if (Number(event.target.value) !== CUSTOM_RANGE) {
            clearDataRange();
            updateData(event.target.value)
        }
    }, [clearDataRange, updateData]);

    const onCustomPressedCallback = useCallback(() => {
            updateOpenDatePickerCallback(true);
        }
        , [updateOpenDatePickerCallback]);


    const handleRefresh = () => {
        updateData(null);
    }



    const handleTimeZoneChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            updateState(state => ({...state, timeZone: newAlignment}))
        }
    }

    const classes = useStyles();

    return (
        <Card className={className}>
            <DateRangeComponent open={openDateRangePicker} changeState={updateOpenDatePickerCallback}
                                onDateRangePicked={updateDatePickedCallback}/>
            <Grid container>
                <Grid container item xs={12} alignItems={"center"} alignContent={"center"}>
                    <CardTittle tittle={t("analyticScreen.voc_iaq.title")} setAnchorEl={setAnchorEl}/>
                    <IconButton className={classes.refreshButton} aria-label="refresh" disabled={loading}
                                onClick={handleRefresh}>
                        <RefreshIcon fontSize={"large"}/>
                    </IconButton>
                </Grid>
                {data.length > 0 && <Grid container item xs={10} className={"vocData"}>
                    <Plot
                        useResizeHandler={true}
                        layout={initialPlotLayout}
                        data={(timeZone === "utc" ? data : localData)}
                        onInitialized={(figure) => this.setState(figure)}
                        onUpdate={
                            (figure) =>
                                this.setState(figure)

                        }
                        config={plotConfig}>
                    </Plot>

                </Grid>}
                {(data.length === 0 && !loading) && <Grid container item xs={10} className={"notFoundVocData"}>
                    <DataNotFound/>
                </Grid>}
                {error !== "" && <Grid container item xs={10} className={"notFoundVocData"}>
                    <ErrorFetchingDataMessage/>
                </Grid>}
                {loading && <Grid container item xs={10} className={"notFoundVocData"}>
                    <ChartLoading/>
                </Grid>}
                <Grid item className={classes.rightControls} xs={2}>
                    <ToggleButtonGroup
                        style={{marginBottom: 16}}
                        color="primary"
                        value={timeZone}
                        onChange={handleTimeZoneChange}
                        exclusive
                    >
                        <ToggleButton size={"small"} style={{width: 80}} value="local">{t("local")}</ToggleButton>
                        <ToggleButton size={"small"} style={{width: 80}} value="utc">{t("utc")}</ToggleButton>
                    </ToggleButtonGroup>
                    <PeriodSelector loading={loading}
                                    period={period}
                                    handleSelectorChange={handleSelectorChange}
                                    dateRange={dateRange}
                                    onCustomPressedCallback={onCustomPressedCallback}
                    />
                </Grid>
            </Grid>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose}
                       message={t("analyticScreen.voc_iaq.en_voc_iaq")}/>
        </Card>
    );
};

export default VocIaqCardView;
