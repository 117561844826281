import { combineReducers, compose, createStore} from "redux";


import {authReducer} from "../authReducer";
import {requestUIReducer} from "../requestUIReducer";
import {dashboardUIReducer} from "../dashboardUIReducer";
import {downloadReducer} from "../downloadReducer";
import {reportReducer} from "../reportReducer";
import {alarmReducer} from "../alarmReducer";
import {notificationReducer} from "../notificationReducer";

export const reducers = combineReducers({auth:authReducer,
    requestUI:requestUIReducer,
    dashboardUI:dashboardUIReducer,
    download:downloadReducer,
    report:reportReducer,
    alarm:alarmReducer,
    notification:notificationReducer
})

export const store = compose((createStore)(reducers));

