import React, {useCallback, useEffect, useState} from 'react';
import {
    Card,
    Grid, IconButton,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import DateRangeComponent from "../../../../common/dateRange/DateRangeComponent";
import CardTittle from "../../analytics/common/CardTittle";
import RefreshIcon from "@mui/icons-material/Refresh";
import Plot from "../../../../../../node_modules/react-plotly.js/react-plotly";
import DataNotFound from "../../../../common/DataNotFound";
import ErrorFetchingDataMessage from "../../analytics/common/ErrorFetchingDataMessage";
import ChartLoading from "../../analytics/common/ChartLoading";
import PeriodSelector, {CUSTOM_RANGE} from "../../analytics/common/PeriodSelector";
import HelpPopup from "../../analytics/common/HelpPopup";
import './chart_style.css';
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import {useSnackbar} from "notistack";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useDispatch, useSelector} from "react-redux";
import useDateRange from "../../../../../hooks/useDateRange";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ANALYTICS_ROUTE, DEFAULT_PERIOD, pollutants} from "../../../../../constants";
import PollutantToggleButtons from "./PollutantToggleButtons";
import {unitsMap} from "../../unitsNames";
import {pollutantNames} from "../../pollutantNames";
import {roundAccurately} from "../../../../../utils/roundNumbers";
import {compareStationsColors} from "../CompareScreen";
import {getAvailableSensors} from "../../../../../utils/stationUtil";
import {compareStationsDataRequest} from "../../../../../requests/compare/compareStationsDataRequest";
import {sortBySelection} from "../../../../../utils/sortComparedPollutants";
import {getY4Range} from "../../../../../utils/chartAxeRangeUtil";
import {useTranslation} from "react-i18next";


const chartDomains = {
    first: [0.48, 1],
    second: [0.32, 0.44],
    third: [0.16, 0.28],
    fourth: [0, 0.12],
    firstExpanded: [0.4, 1],
    secondExpanded: [0.19, 0.33],
    thirdExpanded: [0, 0.12]
};

const getAxeVisibilityConfig = (visiblePollutants) => {
    const units = [...new Set(visiblePollutants.map(item => item.units))];
    const pressureData = visiblePollutants.filter((item) => item.units === "hPa" && item.visible === true);

    const y1Visible = !!pressureData.find((item) => item.y.find((value) => value !== null && !Number.isNaN(value)));
    const y2Visible = units.includes("percentage");
    const y3Visible = units.includes("ug-m3") || units.includes("ppb");
    const y4Visible = units.includes("mg-m3") || units.includes("ppm");
    const y5Visible = units.includes("celsius") || units.includes("fahrenheit");
    const y1Domain = !y1Visible ? [0, 0.001] : chartDomains.fourth;
    const y2Domain = !y2Visible ? [0, 0.001] : y1Visible ? chartDomains.third : chartDomains.thirdExpanded;
    const y3Domain = (!y3Visible && !y4Visible) ? [0, 0.001] : y1Visible ? chartDomains.first : chartDomains.firstExpanded;
    const y4Domain = y3Domain;
    const y5Domain = !y5Visible ? [0, 0.001] : y1Visible ? chartDomains.second : chartDomains.secondExpanded;

    return { y2Visible, y5Visible, y3Visible, y4Visible, y1Visible, y2Domain, y5Domain, y3Domain, y4Domain, y1Domain };
}

const initialPlotLayout = {
    legend: {x: -0.37, y: 0.9},
    yaxis: {
        title: "hPa"
    },
    yaxis2: {
        title: "RH %",
        range: [0, 100]
    },
    yaxis3: {
        title: 'µg/m³'
    },
    yaxis4: {
        overlaying: 'y3',
        title: 'mg/m³'
    },
    yaxis5: {
        title: "ºC",
    },
    xaxis: {showgrid: false},
};

const useStyles = makeStyles({
    pollutantList: {
        marginTop: 10,
        marginBottom: 10
    },
    loading: {
        left: "50%",
        position: "relative",
        top: "50%",
        zIndex: 999
    },
    rightControls: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    refreshButton: {
        marginLeft: "auto",
        color: "gray"
    }
});

const CompareSensorDataCardView = ({className}) => {

    const initialState = {
        rawData: {},
        y4Range: [],
        data: [],
        hiddenVariables: [],
        error: "",
        period: DEFAULT_PERIOD,
        loading: true,
        plotLayout: initialPlotLayout,
        selectedPollutant: null,
        pollutantList: [],
        y1Visible: true,
        y2Visible: true,
        y3Visible: true,
        y4Visible: true,
        y5Visible: true,
        y1Domain: chartDomains.fourth,
        y2Domain: chartDomains.third,
        y3Domain: chartDomains.first,
        y4Domain: chartDomains.first,
        y5Domain: chartDomains.second
    };

    const[{
        data, period, loading, y4Range, plotLayout, rawData, hiddenVariables, error, selectedPollutant, pollutantList,
        y1Visible, y2Visible, y3Visible, y4Visible, y5Visible, y1Domain, y2Domain, y3Domain, y4Domain, y5Domain
    }, updateState] = useState(initialState);

    const {t} = useTranslation()
    const dispatch = useDispatch();
    const {anchorEl, setAnchorEl, handleHelpClose} = useAnchorEl();
    const {enqueueSnackbar} = useSnackbar();
    const isMounted = useMountComponent();
    const {selectCompareStations, stations} = useSelector(state => state.dashboardUI);
    const {units} = useSelector(state => state.auth);
    const [{openDateRangePicker, dateRange}, updateOpenDatePickerCallback,
        updateDatePickedCallback, clearDataRange] = useDateRange();

    useEffect(
        () => {
            if (dateRange != null) {
                updateData(CUSTOM_RANGE, dateRange, null, true, null);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dateRange]);

    useEffect(() => {
        let selectedPollutant = getAvailableSensors(selectCompareStations).map(it => (it.id))[0];
        updateData(null, null, selectedPollutant, true, []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectCompareStations])

    const classes = useStyles();

    useEffect(() => {
        updateState(state => ({
            ...state, plotLayout: {
                ...initialPlotLayout,
                yaxis3: units.pollutants === "eu" ? {...initialPlotLayout.yaxis3} : {
                    title: (selectedPollutant === "PM2P5" || selectedPollutant === "PM10" || selectedPollutant === "PM1") ? "µg/m³" : 'ppb'
                },
                yaxis4: units.pollutants === "eu" ? {...initialPlotLayout.yaxis4} : {
                    overlaying: 'y3',
                    title: 'ppm'
                },
                yaxis5: units.temperature === "celsius" ? {...initialPlotLayout.yaxis5} : {
                    title: "ºF",
                }
            }
        }))
    }, [units.pollutants, units.temperature, selectedPollutant])


    const getChartData = useCallback((serverData, pollutant, hiddenStations) => {

        let currentHiddenStations = hiddenStations ? hiddenStations : []
        const stationsHiddenList = currentHiddenStations.map(item => {
            return [...new Set(data.map(item => item.stationId))][item]})

        let currentData = serverData || rawData
        let currentPollutant = pollutant || selectedPollutant
        let dataArray = Object.entries(currentData)
        let sortedDataArray = sortBySelection(dataArray, selectCompareStations)
        let output = []

        sortedDataArray.forEach((station, stationIndex) => {
            let aux = Object.entries(station[1]);
            let variablesListToShow = aux.filter(pollutantItem => {
                return (
                    pollutantItem[0] === currentPollutant ||
                    pollutantItem[0] === pollutants.temperature ||
                    pollutantItem[0] === pollutants.rh ||
                    pollutantItem[0] === pollutants.pressure
                );
            })
            let currentStationChartInfo = []
            variablesListToShow.forEach((aux3, variableIndex) => {
                let units = aux3[1].units
                let stationName = stations.find(item => item.id === station[0]).alias
                currentStationChartInfo.push({
                    visible: stationsHiddenList.includes(station[0]) ? "legendonly" : true,
                    stationId:station[0],
                    marker: {color: compareStationsColors[stationIndex]},
                    legendgroup: `group${station[0]}`,
                    name: ` ${stations.find(item => item.id === station[0]).alias}`,
                    ...aux3[1],
                    type: 'scatter',
                    showlegend: variableIndex === 0,
                    y: aux3[1].y.map(yValue => {
                        return roundAccurately(yValue, 2);
                    }),
                    yaxis: units === "celsius" || units === "fahrenheit" ? 'y5' :
                        units === "percentage" ? 'y2' : units === "ppb" || units === "ug-m3" ? 'y3' :
                            units === "mg-m3" || units === "ppm" ? 'y4': units === "hPa" ? 'y1': "error",

                    hovertemplate: `<b>${stationName} - ${pollutantNames.get(aux3[0])}</b> %{y} ${unitsMap.get(aux3[1].units)}`,
                    hoverlabel: {namelength: 0},
                });
            })
            output.push(...currentStationChartInfo)
        })
        return output
    }, [data,selectCompareStations, stations, rawData, selectedPollutant])

    const isVariableDownloaded = useCallback((variable) => {
        if (Object.keys(rawData).length === 0) {
            return false
        }
        let isContained = false
        Object.entries(rawData).forEach(station => {
                Object.keys(station[1]).forEach(pollutant => {
                    if (pollutant === variable) {
                        isContained = true
                    }
                })
            }
        )
        return isContained
    }, [rawData])

    const getMergedRawAndNewData = useCallback((data, rawData) => {
        let output = rawData
        if (Object.keys(rawData).length === 0) {
            return data
        }
        selectCompareStations.forEach(station => {
            output[station.id] = {...output[station.id], ...data[station.id]}
        })
        return output
    }, [selectCompareStations])


    const updateData = useCallback((selectedPeriod, selectedDateRange, variable, updateAll, hiddenStations) => {
        let currentPollutant = variable || selectedPollutant
        let currentDateRange = selectedDateRange || dateRange
        let variableAlreadyDownloaded = updateAll ? false : isVariableDownloaded(currentPollutant)
        let currentPeriod = selectedPeriod || period

        let pollutantList = getAvailableSensors(selectCompareStations).map(it => (it.id)).filter(item => (
            item !== pollutants.rh &&
            item !== pollutants.temperature &&
            item !== pollutants.noise &&
            item !== pollutants.pressure &&
            item !== pollutants.equivalent_pressure
        ));

        updateState(state => {
            return {
                ...state,
                data: [],
                rawData: updateAll ? {} : state.rawData,
                loading: true,
                pollutantList,
                error: "",
                hiddenVariables: hiddenStations !== null ? hiddenStations : state.hiddenVariables,
                period: currentPeriod,
                y4Range: [],
                selectedPollutant: currentPollutant
            }
        });

        if (!variableAlreadyDownloaded) {
            let variablesToDownload = updateAll ? [
                pollutants.temperature,
                pollutants.rh,
                pollutants.pressure,
                currentPollutant
            ] : [currentPollutant];
            let selectedRange = getDateRange(currentPeriod, currentDateRange);
            compareStationsDataRequest({
                    "stations": selectCompareStations.map(item => item.id),
                    "pollutants": units.pollutants,
                    "variables": variablesToDownload,
                    "temperature": units.temperature,
                    "time": selectedRange[0],
                    "endtime": selectedRange[1]
                }, (err, data) => {
                    if (!isMounted.current) {
                        return
                    }
                    if (!err) {
                        let mergedData = getMergedRawAndNewData(data, updateAll ? {} : rawData)
                        let chartData = getChartData(mergedData, currentPollutant, hiddenStations != null ? hiddenStations : hiddenVariables)

                        if (chartData.length > 0) {
                            updateState(state => {
                                return {
                                    ...state, data: chartData, rawData: mergedData, y4Range: getY4Range(chartData),
                                    loading: false,
                                    hiddenVariables: hiddenStations !== null ? hiddenStations : state.hiddenVariables,
                                    ...getAxeVisibilityConfig(chartData)
                                }
                            });
                        } else {
                            updateState(state => {
                                return {
                                    ...state, data: chartData, rawData: mergedData,
                                    loading: false,
                                    hiddenVariables: hiddenStations !== null ? hiddenStations : state.hiddenVariables
                                }
                            });
                        }
                    } else {
                        if (data.status === 404) {
                            updateState(state => {
                                return {...state, loading: false}
                            });
                            enqueueSnackbar(t("compareScreen.stationData.comparable_data_not_found"), {variant: "info"});
                        } else {
                            updateState(state => {
                                return {...state, loading: false, error: data.status}
                            });
                            if (data.status === 401) {
                                expiredSession(ANALYTICS_ROUTE)(dispatch)
                            } else {
                                enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("compareScreen.stationData.could_not_compare")}`, {variant: "error"});
                            }
                        }
                    }
                }
            )
        } else {

            const chartData = getChartData(null, currentPollutant, null)
            if (chartData.length > 0) {
                updateState(state => {
                    return {...state, data: chartData, loading: false, y4Range: getY4Range(chartData)}
                });
            } else {
                updateState(state => {
                    return {...state, data: chartData, loading: false}
                });
            }

        }
    }, [hiddenVariables,t, dispatch, rawData, getMergedRawAndNewData, selectedPollutant, isVariableDownloaded, selectCompareStations,
        getChartData,
        units.temperature, units.pollutants, dateRange, isMounted, period, enqueueSnackbar]);

    const pollutantCallback = useCallback((selectedPollutant) => {
        updateData(null, null, selectedPollutant, false, [])
    }, [updateData])

    const handleSelectorChange = useCallback((event) => {
        if (Number(event.target.value) !== CUSTOM_RANGE) {
            clearDataRange();
            updateData(event.target.value, null, null, true, null)
        }
    }, [clearDataRange, updateData]);

    const onCustomPressedCallback = useCallback(() => {
            updateOpenDatePickerCallback(true);
        }
        , [updateOpenDatePickerCallback]);

    const plotConfig = {
        modeBarButtonsToRemove: ["select2d", "lasso2d",
            "toggleHover", "sendDataToCloud", "toggleSpikelines",
        ],
        displaylogo: false
    };

    const handleRefresh = () => {
        updateData(null, null, null, true, null);
    }

    const onLegendClick = (event) => {
        const hiddenStationArray = hiddenVariables !== undefined ? hiddenVariables : [];
        const position = event.curveNumber / 4;
        const newVisibleList = !hiddenStationArray.includes(position) ? [...hiddenStationArray, position] : hiddenStationArray.filter(item => item !== position);
        const chartData = getChartData(null, null, newVisibleList);

        updateState(state => ({
            ...state,
            hiddenVariables: newVisibleList,
            ...getAxeVisibilityConfig(chartData)
        }));
    }

    const onLegendDoubleClick = (_) => {
        return false
    }

    return (
        <Card className={className}>
            <DateRangeComponent open={openDateRangePicker} changeState={updateOpenDatePickerCallback}
                                onDateRangePicked={updateDatePickedCallback}/>
            <Grid container>
                <Grid container item xs={12} alignItems={"center"} alignContent={"center"}>
                    <CardTittle tittle={t("compareScreen.stationData.title")} setAnchorEl={setAnchorEl}/>
                    <IconButton className={classes.refreshButton} aria-label="refresh" disabled={loading}
                                onClick={ _ => handleRefresh}>
                        <RefreshIcon fontSize={"large"}/>
                    </IconButton>
                </Grid>
                <Grid container item xs={12} justifyContent={"center"}>
                    <Grid item className={classes.pollutantList}>
                        {(selectCompareStations.length !== 0 && !loading) && <PollutantToggleButtons pollutantList={pollutantList}
                                                                        pollutantCallback={pollutantCallback}
                                                                        selectedPollutant={selectedPollutant}/>}
                    </Grid>
                </Grid>

                {data.length > 0 && <Grid container item xs={10} className={"compareData"}>
                    <Plot
                        useResizeHandler={true}
                        layout={{
                            ...plotLayout,
                            yaxis: {...plotLayout.yaxis, visible: y1Visible, domain: y1Domain},
                            yaxis2: {...plotLayout.yaxis2, visible: y2Visible, domain: y2Domain},
                            yaxis3: {...plotLayout.yaxis3, visible: y3Visible, domain: y3Domain},
                            yaxis4: y4Range.length === 0 ? {...plotLayout.yaxis4, visible: y4Visible, domain: y4Domain} :
                                {...plotLayout.yaxis4, visible: y4Visible, domain: y4Domain, range: y4Range},
                            yaxis5: {...plotLayout.yaxis5, visible: y5Visible, domain: y5Domain}
                        }}
                        data={data}
                        onInitialized={(figure) => this.setState(figure)}
                        onUpdate={
                            (figure) =>
                                this.setState(figure)

                        }
                        onLegendClick={onLegendClick}
                        onLegendDoubleClick={onLegendDoubleClick}
                        config={plotConfig}>
                    </Plot>

                </Grid>}
                {(data.length === 0 && !loading) && <Grid container item xs={10} className={"notFoundCompareData"}>
                    <DataNotFound/>
                </Grid>}
                {error !== "" && <Grid container item xs={10} className={"notFoundCompareData"}>
                    <ErrorFetchingDataMessage/>
                </Grid>}
                {loading && <Grid container item xs={10} className={"notFoundCompareData"}>
                    <ChartLoading/>
                </Grid>}
                <Grid item className={classes.rightControls} xs={2}>
                    <PeriodSelector loading={loading}
                                    period={period}
                                    handleSelectorChange={handleSelectorChange}
                                    dateRange={dateRange}
                                    onCustomPressedCallback={onCustomPressedCallback}
                    />
                </Grid>
            </Grid>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose}
                       message={t("compareScreen.stationData.en_compare_stationData")}/>
        </Card>
    );
};

export default CompareSensorDataCardView;
