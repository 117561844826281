import React, {useCallback, useEffect, useState} from 'react';
import {Card, Grid, IconButton} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Plot from "react-plotly.js";
import {makeStyles} from "@mui/styles";
import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {expiredSession} from "../../../../../reducers/authReducer";
import { COMPARE_ROUTE, DEFAULT_PERIOD} from "../../../../../constants";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import useDateRange from "../../../../../hooks/useDateRange";
import DateRangeComponent from "../../../../common/dateRange/DateRangeComponent";
import {roundAccurately} from "../../../../../utils/roundNumbers";
import './chart_style.css';
import DataNotFound from "../../../../common/DataNotFound";
import PeriodSelector, {CUSTOM_RANGE} from "../../analytics/common/PeriodSelector";
import ChartLoading from "../../analytics/common/ChartLoading";
import ErrorFetchingDataMessage from "../../analytics/common/ErrorFetchingDataMessage";
import HelpPopup from "../../analytics/common/HelpPopup";
import CardTittle from "../../analytics/common/CardTittle";
import {compareAmbientNoiseRequest} from "../../../../../requests/compare/compareAmbienNoiseRequest";
import {compareStationsColors} from "../CompareScreen";
import {sortBySelection} from "../../../../../utils/sortComparedPollutants";
import {useTranslation} from "react-i18next";


const plotLayout = {
    autosize: true,
    legend:{x: -0.37, y: 0.9},
    yaxis: {
        title: 'dBA',
        range:[0,120]
    },
    xaxis:{showgrid:false,domain: [0.01, 1]},
};

const useStyles = makeStyles({
    loading:{
        left: "50%",
        position: "relative",
        top: "50%",
        zIndex:999
    },
    rightControls:{
        display:"flex",
        flexDirection:"column",
        alignItems:"flex-start"
    },
    refreshButton:{
        marginLeft:"auto",
        color:"gray"
    }
});

const plotConfig = {
    modeBarButtonsToRemove:[ "select2d", "lasso2d",
        "toggleHover", "sendDataToCloud", "toggleSpikelines","hoverCompareCartesian",
        "hoverClosestCartesian"
    ],
    displaylogo : false
};

const CompareAmbientNoiseCardView = ({className}) => {

    const {t} = useTranslation()
    const dispatch = useDispatch();
    const initialState = {data:[],hiddenVariables:[],period:DEFAULT_PERIOD,loading:true,error:""}
    const[{data,period,hiddenVariables,loading,error},updateState] = useState(initialState)
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const { enqueueSnackbar } = useSnackbar();
    const isMounted = useMountComponent();
    const { selectCompareStations,stations } = useSelector( state => state.dashboardUI );

    const [{openDateRangePicker,dateRange},updateOpenDatePickerCallback,
        updateDatePickedCallback,clearDataRange] = useDateRange();

    useEffect(
        ()=>{
            if(dateRange != null){
                updateData(CUSTOM_RANGE,dateRange,null);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[dateRange]);

    useEffect(()=>{
            updateData(null,null,[]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[selectCompareStations])

    const handleRefresh = ()=>{
        updateData(null,null,null);
    }

    const getChartData = useCallback( (serverData,hiddenStations)=>{

            let currentHiddenStations = hiddenStations !== null ? hiddenStations : hiddenVariables !== null ? hiddenVariables :[]
            let dataArray = Object.entries(serverData)
            let output = []
            let sortedDataArray = sortBySelection(dataArray,selectCompareStations)

            sortedDataArray.forEach((station,stationIndex) => {
                let stationName = stations.find(item => item.id === station[0]).alias
                output.push( {
                    visible:currentHiddenStations.includes(stationIndex)? "legendonly":true,
                    marker: {color:compareStationsColors[stationIndex]},
                    name:stationName ,
                    type: 'scatter',
                    y: station[1].y !== undefined ? station[1].y.map(yValue =>{
                        return roundAccurately(yValue,2);
                    }):[],
                    x:station[1].x,
                    hovertemplate: `<b>${stationName} - Noise level</b> %{y} dBA`,
                    hoverlabel:{namelength:0}
                })
            })

        return output
    },[selectCompareStations,hiddenVariables,stations])

    const updateData = useCallback((selectedPeriod,selectedDateRange,hiddenStations)=>{

        let currentPeriod = selectedPeriod || period
        let currentDateRange = selectedDateRange || dateRange

        updateState(state =>{
            return {...state,
                data:[],
                rawData: {},
                loading: true,
                error: "",
                hiddenVariables: hiddenStations !== null ? hiddenStations : state.hiddenVariables,
                period: currentPeriod,
                }
        });

        let selectedRange = getDateRange(currentPeriod,currentDateRange);

        compareAmbientNoiseRequest(
            {
                "stations": selectCompareStations.map(item=> item.id),
                "time": selectedRange[0],
                "endtime": selectedRange[1]
            }, (err,data)=>{

                if (!isMounted.current) {return}
                if(!err){
                    let chartData = getChartData(data,hiddenStations)
                    updateState(state =>{
                        return {...state,data:chartData,rawData:data,loading: false, hiddenVariables: hiddenStations !== null ? hiddenStations : state.hiddenVariables}
                    });
                }
                else {
                    if(data.status === 404){
                        updateState(state =>{
                            return {...state,loading: false}
                        });
                        enqueueSnackbar(t("compareScreen.ambientNoise.no_data_found"),{ variant:"info" });
                    }
                    else {
                        updateState(state =>{
                            return {...state,loading: false,error:data.status}
                        });
                        if(data.status === 401){
                            expiredSession(COMPARE_ROUTE)(dispatch)
                        }
                        else {
                            enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("compareScreen.ambientNoise.could_not_update")}`,{ variant:"error" });
                        }
                    }
                }
            });
    },[t,getChartData,dispatch,enqueueSnackbar,isMounted,period,selectCompareStations,dateRange]);

    const handleSelectorChange = useCallback((event)=>{
        if( Number(event.target.value) !== CUSTOM_RANGE){
            clearDataRange();
            updateData(event.target.value,null,null)
        }
    },[clearDataRange,updateData]);

    const onCustomPressedCallback = useCallback(()=>{
            updateOpenDatePickerCallback(true);
        }
        ,[updateOpenDatePickerCallback]);

    const onLegendClick = (event)=>{
        let hiddenStationArray = hiddenVariables !== undefined ? hiddenVariables : []
        let position = event.curveNumber
        let newVisibleList = !hiddenStationArray.includes(position) ? [...hiddenStationArray,position] : hiddenStationArray.filter(item => item !== position)
        updateState(state => ({...state,hiddenVariables: newVisibleList}))
    }

    const onLegendDoubleClick = (event)=>{
        return false
    }

    const classes = useStyles();

    return (
        <Card className={className}>
            <DateRangeComponent open={openDateRangePicker} changeState={updateOpenDatePickerCallback}
                                onDateRangePicked={updateDatePickedCallback} />
            <Grid container>
                <Grid container item xs={12} alignItems={"center"} alignContent={"center"}>
                    <CardTittle tittle={t("compareScreen.ambientNoise.title")} setAnchorEl={setAnchorEl}/>
                    <IconButton className={classes.refreshButton} aria-label="refresh"  disabled={loading}
                                onClick={handleRefresh}>
                        <RefreshIcon fontSize={"large"}/>
                    </IconButton>
                </Grid>
                {data.length>0 &&  <Grid  container item xs={10} className={"compareAmbientNoiseData"} >
                    <Plot
                        useResizeHandler = {true}
                        layout={plotLayout}
                        data={data}
                        onInitialized={(figure) => this.setState(figure)}
                        onUpdate={
                            (figure) =>
                                this.setState(figure)

                        }
                        onLegendClick ={onLegendClick}
                        onLegendDoubleClick={onLegendDoubleClick}
                        config = {plotConfig}>

                    </Plot>
                </Grid>}
                {(data.length === 0 && !loading) &&  <Grid container item xs={10} className={"notFoundAmbientNoise"} >
                    <DataNotFound/>
                </Grid>}
                {error !== "" &&  <Grid container item xs={10} className={"notFoundAmbientNoise"} >
                    <ErrorFetchingDataMessage/>
                </Grid>}
                {loading &&  <Grid container item xs={10} className={"notFoundAmbientNoise"} >
                    <ChartLoading/>
                </Grid>}
                <Grid item className={classes.rightControls} xs={2}>
                    <PeriodSelector loading={loading}
                                    period={period}
                                    handleSelectorChange={handleSelectorChange}
                                    dateRange={dateRange}
                                    onCustomPressedCallback={onCustomPressedCallback}
                    />
                </Grid>
            </Grid>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("compareScreen.ambientNoise.en_compare_ambient_noise")}/>
        </Card>
    );
};

export default CompareAmbientNoiseCardView;
