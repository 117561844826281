import React from 'react';
import Plot from "react-plotly.js";
import './chart_style.css';
import {roundAccurately} from "../../../../../../utils/roundNumbers";

const colors = ["#f8cc3d","#f89b3d","#f87e3d","#f8593d",
    "#f83d3d"]

const getLabel = (value)=>{
    let output = ""
    // eslint-disable-next-line default-case
    switch (value){
        case "0":
            output =  "0 - 5 m/s"
            break;
        case "5":
            output =  "5 - 8 m/s"
            break;
        case "8":
            output =  "8 - 11 m/s"
            break;
        case "11":
            output =  "> 11 m/s"
            break;
    }
    return output;
}


const formatData = (data)=>{
    const totalSamples = data.total;
    const filteredData = {...data}
    delete filteredData.total
    return Object.entries(filteredData).map((item,index) =>  {
        return {
            r: Object.values(item[1]).map(item => {return roundAccurately((item/totalSamples)*100,2)}),
            theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
            name: getLabel(item[0]),
            marker: {color: colors[index]},
            type: "barpolar"
        }
    })
}



const WindRoseChart = ({data}) => {

    const plotConfig = {
        modeBarButtonsToRemove:[ "select2d", "lasso2d",
            "toggleHover", "sendDataToCloud", "toggleSpikelines","zoom2d"
        ],
        displaylogo : false
    };

    const layout = {
        polar: {
            domain: {
                x: [0,0.9],
                y: [0,1]
            },
            barmode: "overlay",
            bargap: 0,
            radialaxis: {ticksuffix: "%", angle: 45, dtick: 20},
            angularaxis: {direction: "clockwise"}
        }
    }

    return (
        <div className={"windRoseChart"}>
            <Plot
                config = {plotConfig}
                useResizeHandler = {true}
                layout= {layout}
                data={formatData(data)}
                onInitialized={(figure) => this.setState(figure)}
                onUpdate={
                    (figure) =>
                        this.setState(figure)
                }>
        </Plot>
        </div>

    );
};

export default WindRoseChart;
