import React, {useCallback, useEffect, useState} from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import PeriodSelector, {CUSTOM_RANGE} from "../../analytics/common/PeriodSelector";
import useDateRange from "../../../../../hooks/useDateRange";
import {makeStyles} from "@mui/styles";
import DateRangeComponent from "../../../../common/dateRange/DateRangeComponent";
import {useTranslation} from "react-i18next";
import {DECIMAL_SEPARATOR} from "../DownloadConfigPopUp";

const useStyles = makeStyles({
    saveButton:{
        color:'#ffffff',
    },
    changePasswordButton:{
        marginTop:24
    },
    card: {
        maxWidth:1600,
        width:"100%",
        display:"flex",
        background:"white",
        padding:40,
        marginTop: 20,
        marginLeft:20,
        marginRight:20,
        borderRadius: 6,
    },
    form:{
        width:"100%",
    },
    email:{
        color:"#8a8a8a"
    },
    emailTittle:{
        fontWeight:"bold"
    },
    unitsTittle:{
       marginTop:32,
       color:"#c4c4c4",
        marginBottom:0
    },
    grip:{
        marginTop:12,

    },
    rowMargin:{
    },
});

const Step1 = ({name,nameCallback,pollutantUnit,pollutantCallback,
                   tempUnit,tempCallback,period,initialDate,updateDate,windSpeedCallback,windSpeedUnit,
                   groupInterval,groupIntervalCallback,decimalSeparator,decimalSeparatorCallback}) => {

    const [{openDateRangePicker,dateRange},updateOpenDatePickerCallback,
        updateDatePickedCallback,clearDataRange] = useDateRange();
    const [{nameError},updateState] = useState({nameError:false})
    const {t} = useTranslation();
    const classes = useStyles();

    const handleNameUpdate = (event)=>{
        let value = event.target.value
        updateState({nameError: value.includes(`/`) || value.includes(`\\`) || value.startsWith(".")})
        nameCallback(event)
    }

    useEffect(
        ()=>{
            if(dateRange != null){
                updateDate(CUSTOM_RANGE,dateRange);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[dateRange]);

    useEffect(()=>{
        if(initialDate !== null){
            updateDatePickedCallback(initialDate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleSelectorChange = useCallback((event)=>{
        if( Number(event.target.value) !== CUSTOM_RANGE){
            clearDataRange();
            updateDate(event.target.value,null)
        }
    },[clearDataRange,updateDate]);

    const onCustomPressedCallback = useCallback(()=>{
            updateOpenDatePickerCallback(true);
        }
        ,[updateOpenDatePickerCallback]);


    return (
        <><DateRangeComponent open={openDateRangePicker} changeState={updateOpenDatePickerCallback}
                                 onDateRangePicked={updateDatePickedCallback} />
        <Grid container justifyContent="center" alignItems="center" spacing={2}>

            <Grid item xs={4}>
                <TextField
                    error={nameError}
                    inputProps={{ "data-testid": "download-step1-name" }}
                    onChange={handleNameUpdate}
                    value={name}
                    helperText={nameError ? t("downloadScreen.popUp.invalid_characters") : null}
                    label={ !nameError ? t("downloadScreen.popUp.download_name") : t("error")} fullWidth/>
            </Grid>
            <Grid item xs={4} >
                <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"none"}>
                    <InputLabel>{t("units.concentration_units")}</InputLabel>
                    <Select
                        label={t("units.concentration_units")}
                        onChange={pollutantCallback}
                        value={pollutantUnit}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        <MenuItem value={"eu"}>µg/m³ - mg/m³</MenuItem>
                        <MenuItem value={"usa"}>{t("units.ppm_ppb")}</MenuItem>

                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4} className={classes.rowMargin}>
                <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                    <InputLabel>{t("units.temperature_units")}</InputLabel>
                    <Select
                        label={t("units.temperature_units")}
                        onChange={tempCallback}
                        value={tempUnit}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        <MenuItem value={"celsius"}>Celsius (ºC)</MenuItem>
                        <MenuItem value={"fahrenheit"}>Fahrenheit (ºF)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4} >
                <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                    <InputLabel>{t("units.wind_speed_unit")}</InputLabel>
                    <Select
                        label={t("units.wind_speed_unit")}
                        onChange={windSpeedCallback}
                        value={windSpeedUnit}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        <MenuItem value={"mps"}>{t("units.meter_per_second")}</MenuItem>
                        <MenuItem value={"mph"}>{t("units.mile_per_hour")}</MenuItem>
                        <MenuItem value={"knots"}>{t("units.knots")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={3} >
                <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                    <InputLabel>{t("downloadScreen.popUp.group_interval")}</InputLabel>
                    <Select
                        label={t("downloadScreen.popUp.group_interval")}
                        value={groupInterval}
                        onChange={groupIntervalCallback}
                    >
                        <MenuItem value={5}>{t("5 min")}</MenuItem>
                        <MenuItem value={10}>{t("10 min")}</MenuItem>
                        <MenuItem value={15}>{t("15 min")}</MenuItem>
                        <MenuItem value={30}>{t("30 min")}</MenuItem>
                        <MenuItem value={60}>{t("60 min")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={3} >
                <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                    <InputLabel>{t("downloadScreen.popUp.decimal_separator")}</InputLabel>
                    <Select
                        label={t("downloadScreen.popUp.decimal_separator")}
                        value={decimalSeparator}
                        onChange={decimalSeparatorCallback}
                    >
                        <MenuItem value={DECIMAL_SEPARATOR.dot}>{t("downloadScreen.popUp.dot_separator")}</MenuItem>
                        <MenuItem value={DECIMAL_SEPARATOR.comma}>{t("downloadScreen.popUp.comma_separator")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>


            <Grid item xs={2}>
                <PeriodSelector loading={false}
                                period={period}
                                handleSelectorChange={handleSelectorChange}
                                dateRange={dateRange}
                                onCustomPressedCallback={onCustomPressedCallback}
                />
            </Grid>






        <Grid item xs={3}/>
        </Grid>
        </>
    );
};

export default Step1;
