
export const calendarAQIColors = {
    green:{
        background:"#B7DCB3"
    },
    yellow:{
        background:"#FBF89B"
    },
    orange:{
        background:"#FBB988"
    },
    red:{
        background:"#F49EA2"
    },
    purple:{
        background:"#C99BC8"
    },
    darkPurple:{
        background:"#A67288"
    }
}

export const calendarCAQIColors = {
    greenCAQI:{
        background:"#B7DCB3"
    },
    greenYellowCAQI:{
        background:"#c8dd8c"
    },
    yellowCAQI:{
        background:"#FBF89B"
    },
    orangeCAQI:{
        background:"#FBB988"
    },
    redCAQI:{
        background:"#F49EA2"
    }
}