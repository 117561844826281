import React, {useCallback, useEffect, useState} from 'react';
import {
    Card,
    Grid,
    IconButton, ToggleButton, ToggleButtonGroup,
} from "@mui/material";
import Plot from 'react-plotly.js';
import {makeStyles} from "@mui/styles";
import './chart_style.css';
import {sensorDataRequest} from "../../../../../requests/analytics/sensorDataRequest";
import {useSnackbar} from 'notistack';
import RefreshIcon from '@mui/icons-material/Refresh';
import {useDispatch, useSelector} from "react-redux";
import {expiredSession} from "../../../../../reducers/authReducer";
import {ANALYTICS_ROUTE, DEFAULT_PERIOD} from "../../../../../constants";
import HelpPopup from "../common/HelpPopup";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import PeriodSelector, {CUSTOM_RANGE} from "../common/PeriodSelector";
import CardTittle from "../common/CardTittle";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import {unitsMap} from "../../unitsNames";
import {pollutantNames} from "../../pollutantNames";
import {pollutantColors} from "../../pollutantColors";
import DateRangeComponent from "../../../../common/dateRange/DateRangeComponent";
import useDateRange from "../../../../../hooks/useDateRange";
import {roundAccurately} from "../../../../../utils/roundNumbers";
import DataNotFound from "../../../../common/DataNotFound";
import ChartLoading from "../common/ChartLoading";
import ErrorFetchingDataMessage from "../common/ErrorFetchingDataMessage";
import {getY4Range} from "../../../../../utils/chartAxeRangeUtil";
import {useTranslation} from "react-i18next";
import {copyVisibility, toLocalTimeZone} from "../../../../../utils/timeZoneUtil";
import {sortBySensorType} from "../../../../../utils/stationUtil";

const baseSensorData = {
    type: 'scatter'
};

const chartDomains = {
    first: [0.6, 1],
    second: [0.3, 0.54],
    third: [0, 0.24],
    firstExpanded: [0.42, 1],
    secondExpanded: [0, 0.36],
    secondFifty: [0.53, 1],
    thirdFifty: [0, 0.47],
    full: [0, 1]
};

const initialPlotLayout = {
    legend: {x: -0.25, y: 0.9},
    yaxis5: {
        title: 'mg/m³',
        rangemode: "nonnegative",
        overlaying: 'y4'
    },
    yaxis4: {
        title: 'µg/m³',
        rangemode: "nonnegative"
    },
    yaxis3: {
        title: "RH %",
        range: [0, 100],
        overlaying: 'y2'
    },
    yaxis2: {
        title: "ºC"
    },
    yaxis: {
        title: "hPa"
    },
    xaxis: {
        showgrid: false,
        type: "date"
    }
};

const plotConfig = {
    modeBarButtonsToRemove: ["select2d", "lasso2d",
        "toggleHover", "sendDataToCloud", "toggleSpikelines",
    ],
    displaylogo: false
};

const presetVariables = ['temperature', 'relativeHumidity', 'pressure', 'equivalentPressure'];

const getVisibleVariables = (data, ids) => {
    const variables = [];
    data.forEach((item, index) => {
        if (ids.includes(item.id)) {
            variables.push(index);
        }
    });
    return variables;
};

const getAxeVisibilityConfig = (visiblePollutants) => {
    const units = [...new Set(visiblePollutants.map(item => item.units))];
    const y1Visible = units.includes("hPa");
    const y2Visible = units.includes("celsius") || units.includes("fahrenheit");
    const y3Visible = units.includes("percentage");
    const y4Visible = units.includes("ug-m3") || units.includes("ppb");
    const y5Visible = units.includes("mg-m3") || units.includes("ppm");
    const y3Side = y2Visible ? "right" : "left";
    const y4Side = "left";
    const y5Side = y4Visible ? "right" : "left";
    const y1Domain = !y1Visible ? [0, 0.001] : (
        (y4Visible || y5Visible) ? (
            (y2Visible || y3Visible) ? chartDomains.third : chartDomains.secondExpanded
        ) : (
            (y2Visible || y3Visible) ? chartDomains.thirdFifty : chartDomains.full
        )
    );
    const y2Domain = (!y2Visible && !y3Visible) ? [0, 0.001] : (
        (y4Visible || y5Visible) ? (
            y1Visible ? chartDomains.second : chartDomains.secondExpanded
        ) : (
            y1Visible ? chartDomains.secondFifty : chartDomains.full
        )
    );
    const y3Domain = y2Domain;
    const y4Domain = (!y4Visible && !y5Visible) ? [0.999, 1] : (
        (y1Visible && (y2Visible || y3Visible)) ? chartDomains.first : (
            (y1Visible || y2Visible || y3Visible) ? chartDomains.firstExpanded : chartDomains.full
        )
    );
    const y5Domain = y4Domain;
    return { y1Visible, y2Visible, y3Visible, y4Visible, y5Visible, y3Side, y4Side, y5Side, y1Domain, y2Domain, y3Domain, y4Domain, y5Domain };
}

const useStyles = makeStyles({
    loading: {
        left: "50%",
        position: "relative",
        top: "50%",
        zIndex: 999
    },
    rightControls: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    refreshButton: {
        marginLeft: "auto",
        color: "gray"
    }
});

const initialState = {
    data: [],
    localData: [],
    visibleVariables: [],
    error: "",
    period: DEFAULT_PERIOD,
    loading: true,
    plotLayout: initialPlotLayout,
    y1Visible: true,
    y2Visible: true,
    y3Visible: true,
    y4Visible: true,
    y5Visible: true,
    y3Side: "right",
    y4Side: "left",
    y5Side: "right",
    y5Range: [],
    y1Domain: chartDomains.third,
    y2Domain: chartDomains.second,
    y3Domain: chartDomains.second,
    y4Domain: chartDomains.first,
    y5Domain: chartDomains.first,
    timeZone: "local"
}

const SensorDataCardView = ({className}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [{
        data, period, loading, plotLayout, error, visibleVariables, y1Visible, y2Visible, y3Side, y3Visible,
        y4Side, y4Visible, y5Side, y5Visible, y5Range, y1Domain, y2Domain, y3Domain, y4Domain, y5Domain, timeZone, localData
    }, updateState] = useState(initialState)
    const {anchorEl, setAnchorEl, handleHelpClose} = useAnchorEl();
    const {enqueueSnackbar} = useSnackbar();
    const isMounted = useMountComponent();
    const {selectedStation} = useSelector(state => state.dashboardUI);
    const {units} = useSelector(state => state.auth);

    const [{openDateRangePicker, dateRange}, updateOpenDatePickerCallback,
        updateDatePickedCallback, clearDataRange] = useDateRange();

    useEffect(() => {
        if (timeZone === "utc") {
            updateState(state => ({...state, data: copyVisibility(state.localData, state.data)}))
        } else {
            updateState(state => ({...state, localData: copyVisibility(state.data, state.localData)}))
        }
    }, [timeZone])

    useEffect(() => {
        updateState(state => ({
            ...state, plotLayout: {
                ...initialPlotLayout,
                yaxis4: units.pollutants === "eu" ? {...initialPlotLayout.yaxis4} : {
                    title: 'ppb - µg/m³'
                },
                yaxis5: units.pollutants === "eu" ? {...initialPlotLayout.yaxis5} : {
                    side: "right",
                    overlaying: 'y4',
                    title: 'ppm'
                },
                yaxis2: units.temperature === "celsius" ? {...initialPlotLayout.yaxis2} : {
                    title: "ºF"
                }
            }
        }))
    }, [units.pollutants, units.temperature])

    useEffect(
        () => {
            if (dateRange != null) {
                updateData(CUSTOM_RANGE);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dateRange]);

    useEffect(() => {
        updateData(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStation])

    const getSelectedPollutants = useCallback((visibleVariables, newData = null) => {
        let output = []
        visibleVariables.forEach(position => {
            output.push(newData !== null ? newData[position] : timeZone === "utc" ? data[position] : localData[position])
        })
        return output
    }, [data, localData, timeZone])


    const updateData = useCallback((selectedPeriod) => {

        let currentPeriod = selectedPeriod || period

        updateState(state => {
            return {...state, loading: true, data: [], localData: [], error: "", period: currentPeriod, y5Range: []}
        });

        let selectedRange = getDateRange(currentPeriod, dateRange);
        sensorDataRequest(units.temperature, units.pollutants, selectedStation,
            selectedRange[0],
            selectedRange[1], (err, data) => {
                if (!isMounted.current) {
                    return
                }
                if (!err) {
                    const dataArray = sortBySensorType(
                        Object.entries(data).filter(item => item[0] !== "VOC_IAQ"),
                        (item) => item[0]
                    );
                    const newData = dataArray.map((value, index) => {
                        const id = value[0];
                        const units = unitsMap.get(value[1].units)
                        const y = value[1].y.map(item => roundAccurately(item, 2));
                        const adaptedData = { ...value[1], y };
                        const defaultVisible = index === 0 || presetVariables.includes(id);
                        return {
                            id,
                            visible: (visibleVariables.length ? visibleVariables.includes(index) : defaultVisible)
                                ? true : "legendonly",
                            name: ` ${pollutantNames.get(id)} - ${units}`,
                            ...adaptedData,
                            ...baseSensorData,
                            hoverlabel: {namelength: 0},
                            hovertemplate: `<b>${pollutantNames.get(id)}</b>: %{y} ${units}`,
                            marker: {color: pollutantColors.get(id)},
                            yaxis: ['celsius', 'fahrenheit'].includes(value[1].units) ? 'y2' :
                                value[1].units === "percentage" ? 'y3' :
                                    ['ppb', 'ug-m3'].includes(value[1].units) ? 'y4' :
                                        ['mg-m3', 'ppm'].includes(value[1].units) ? 'y5' :
                                            value[1].units === "hPa" ? 'y1' : "error"
                        };
                    });
                    if (newData.length > 0) {
                        updateState(state => {
                            const newVisibleVariables = state.visibleVariables.length ? state.visibleVariables
                                : [0, ...getVisibleVariables(newData, presetVariables)];
                            return {
                                ...state, data: newData,
                                localData: toLocalTimeZone(newData),
                                y5Range: getY4Range(newData),
                                loading: false,
                                ...getAxeVisibilityConfig(getSelectedPollutants(newVisibleVariables, newData)),
                                visibleVariables: newVisibleVariables
                            }
                        });
                    } else {
                        updateState(state => {
                            return {...state, data: [], localData: [], loading: false,}
                        });
                    }
                } else {
                    if (data.status === 404) {
                        updateState(state => {
                            return {...state, loading: false}
                        });
                        enqueueSnackbar(t("analyticScreen.sensorData.sensor_data_not_found"), {variant: "info"});
                    } else {
                        updateState(state => {
                            return {...state, loading: false, error: data.status}
                        });
                        if (data.status === 401) {
                            expiredSession(ANALYTICS_ROUTE)(dispatch)
                        } else {
                            enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("analyticScreen.sensorData.could_not_update_station_data")}`, {variant: "error"});
                        }
                    }
                }
            });
    }, [t, getSelectedPollutants, visibleVariables, dispatch,
        units.temperature, units.pollutants, dateRange,
        isMounted, period, selectedStation, enqueueSnackbar]);


    const handleSelectorChange = useCallback((event) => {
        if (Number(event.target.value) !== CUSTOM_RANGE) {
            clearDataRange();
            updateData(event.target.value)
        }
    }, [clearDataRange, updateData]);

    const onCustomPressedCallback = useCallback(() => {
            updateOpenDatePickerCallback(true);
        }
        , [updateOpenDatePickerCallback]);


    const handleRefresh = () => {
        updateData(null);
    }

    const onLegendClick = useCallback((event) => {
        const position = event.curveNumber;
        const newVisibleList = !visibleVariables.includes(position) ? [...visibleVariables, position] :
            visibleVariables.filter(item => item !== position);
        const visiblePollutants = getSelectedPollutants(newVisibleList, null);

        updateState(state => ({
            ...state,
            visibleVariables: newVisibleList,
            ...getAxeVisibilityConfig(visiblePollutants)
        }));
    }, [getSelectedPollutants, visibleVariables])


    const onLegendDoubleClick = useCallback(() => {
        return false;
    }, [])


    const handleTimeZoneChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            updateState(state => ({...state, timeZone: newAlignment}))
        }
    }

    const classes = useStyles();

    return (
        <Card className={className}>
            <DateRangeComponent open={openDateRangePicker} changeState={updateOpenDatePickerCallback}
                                onDateRangePicked={updateDatePickedCallback}/>
            <Grid container>
                <Grid container item xs={12} alignItems={"center"} alignContent={"center"}>
                    <CardTittle tittle={t("analyticScreen.sensorData.station_data")} setAnchorEl={setAnchorEl}/>
                    <IconButton className={classes.refreshButton} aria-label="refresh" disabled={loading}
                                onClick={handleRefresh}>
                        <RefreshIcon fontSize={"large"}/>
                    </IconButton>
                </Grid>
                {data.length > 0 && <Grid container item xs={10} className={"sensorData"}>
                    <Plot
                        useResizeHandler={true}
                        layout={{
                            ...plotLayout,
                            yaxis: {...plotLayout.yaxis, visible: y1Visible, domain: y1Domain},
                            yaxis2: {...plotLayout.yaxis2, visible: y2Visible, domain: y2Domain},
                            yaxis3: {...plotLayout.yaxis3, visible: y3Visible, side: y3Side, domain: y3Domain},
                            yaxis4: {...plotLayout.yaxis4, visible: y4Visible, side: y4Side, domain: y4Domain},
                            yaxis5: y5Range.length === 0 ? {...plotLayout.yaxis5, visible: y5Visible, side: y5Side, domain: y5Domain} :
                                {...plotLayout.yaxis5, visible: y5Visible, side: y5Side, domain: y5Domain, range: y5Range}
                        }}
                        data={(timeZone === "utc" ? data : localData)}
                        onInitialized={(figure) => this.setState(figure)}
                        onUpdate={(figure) => this.setState(figure)}
                        onLegendDoubleClick={onLegendDoubleClick}
                        onLegendClick={onLegendClick}
                        config={plotConfig}>
                    </Plot>

                </Grid>}
                {(data.length === 0 && !loading) && <Grid container item xs={10} className={"notFoundSensorData"}>
                    <DataNotFound/>
                </Grid>}
                {error !== "" && <Grid container item xs={10} className={"notFoundSensorData"}>
                    <ErrorFetchingDataMessage/>
                </Grid>}
                {loading && <Grid container item xs={10} className={"notFoundSensorData"}>
                    <ChartLoading/>
                </Grid>}
                <Grid item className={classes.rightControls} xs={2}>
                    <ToggleButtonGroup
                        style={{marginBottom: 16}}
                        color="primary"
                        value={timeZone}
                        onChange={handleTimeZoneChange}
                        exclusive
                    >
                        <ToggleButton size={"small"} style={{width: 80}} value="local">{t("local")}</ToggleButton>
                        <ToggleButton size={"small"} style={{width: 80}} value="utc">{t("utc")}</ToggleButton>
                    </ToggleButtonGroup>
                    <PeriodSelector loading={loading}
                                    period={period}
                                    handleSelectorChange={handleSelectorChange}
                                    dateRange={dateRange}
                                    onCustomPressedCallback={onCustomPressedCallback}
                    />
                </Grid>
            </Grid>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose}
                       message={t("analyticScreen.sensorData.en_analytics_stationData")}/>
        </Card>
    );
};

export default SensorDataCardView;
