import React, {useCallback} from 'react';
import StationTransferList from "../../../../common/StationTransferList";
import {useSelector} from "react-redux";

const Step2 = ({initialSelection,callback}) => {

    const { stations } = useSelector( state => state.dashboardUI );

    const notSelectedStations = useCallback(()=>{
        return stations.filter(station => {
            return !initialSelection.includes(station)
        })
    },[stations,initialSelection])


    return (
            <StationTransferList initialSelected={initialSelection}
                                 initialNotSelected={notSelectedStations()}
                                 selectedCallback={callback}/>
    );
};

export default Step2;
