import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        supportedLngs: [ 'en', 'es'],
        detection: { order: ["path", "navigator"] },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    local:"Local",
                    utc:"UTC",
                    websocket_disconnected:"WebSocket Disconnected",
                    more_info:"What does it mean?",
                    not_available:"Not available",
                    save:"Save",
                    ok:"OK",
                    cancel:"Cancel",
                    error:"Error",
                    delete:"Delete",
                    update:"Update",
                    dataNotFound:"Data not found",
                    warning:"Warning",
                    processing:"Processing",
                    finish:"Finish",
                    next:"Next",
                    back:"Back",
                    error_500:"500 Internal server error",
                    error_404:"404 Page not found",

                    helpMessages:{
                        en_organization_units : "This card view contains the organization units used for auto-report generation and " +
                        "new members default units. Changing the organization units will have no effect on previous " +
                            "generated reports and configured alarms"
                    },
                    loginScreen:{
                        password:"Password",
                        minimun_password:"The password must contains at least ",
                        characters:"characters",
                        remember:"Remember",
                        sign_in:"Sign in",
                        forgot_password:"Forgot password?",
                        email:"Email"
                    },
                    forgot:{
                        forgot:"Recover Password",
                        not_user_found:"Not user found with this email",
                        invalid_email:"Error, invalid email",
                        unknown:"Unknown error recovering password",
                        enter_email:"Please enter your email address below. You will receive a link to create a new password via email.",
                        email:"Email",
                        reset_password:"Reset password",
                        success:'Please check your email and follow the steps to reset your password'
                    },
                    cookies:{
                        enable:"Enable website cookies?",
                        description:"This website uses cookies to improve your experience.\n" +
                            "                        It only includes cookies that ensure basic functionalities and security features.",
                        accept:"Accept"
                    },
                    dashboard:{
                        error_logout:"An error occurred, trying to logout",
                        sentry_front_feedback:"Fronted feedback",
                        sentry_send_suggestion:"Send Suggestions",
                        sentry_opinion:"Our team would love to hear your opinion.",
                        sentry_send:"Submit",
                        sentry_something_to_say:"Do you want to suggest something?",
                        bettair_platform:"Bettair® Platform",
                        select_station:"Select Station",
                        select_stations:"Select stations",
                        available_stations:"Available",
                        selected_stations:"Selected",
                        selected:"selected"
                    },
                    drawer:{
                        home:"Home",
                        analytics:"Analytics",
                        compare:"Compare",
                        alarms:"Alarms",
                        download:"Downloads",
                        reports:"Reports",
                        send_suggestions:"Send suggestions"
                    },
                    dashboardMenu:{
                        profile:"Profile",
                        organization:"Organization",
                        logout:"Logout",
                        platform_manual:"Platform Documentation",
                        api_manual:"API Documentation"
                    },
                    home:{
                        state:"State:",
                        battery:"Battery level:",
                        last_connection:"Last connection:",
                        more_detail:"More detail"
                    },
                    aqiLegend:{
                        hazardous:"Hazardous",
                        very_unhealthy:"Very Unhealthy",
                        unhealthy:"Unhealthy",
                        unhealthy_for_sensitive:"Unhealthy for sensitive groups",
                        moderate:"Moderate",
                        good:"Good"
                    },
                    caqiLegend:{
                        very_high:"Very high",
                        high:"High",
                        medium:"Medium",
                        low:"Low",
                        very_low:"Very low"
                    },
                    legend:{
                        aqi:"Air Quality Index",
                        caqi:"Common Air Quality Index",
                        bettair_station:"Bettair station",
                        external_source:"External source",
                        no_data:"No data",
                        no_connection:"No connection"
                    },
                    profile:{
                        success_updated:`Profile successfully updated`,
                        error_updating:`Error updating profile, invalid name or lastName values`,
                        could_not_updated:"Could not update user profile",
                        user_profile:"User Profile",
                        name:"Name",
                        last_name:"Last name",
                        email:"Email",
                        change_password:"Change Password",
                    },
                    units:{
                        pollutant_index:"Pollutant Index",
                        concentration_units:"Concentration Units",
                        temperature_units:"Temperature Units",
                        wind_speed_unit:"Wind Speed Units",
                        meter_per_second:"Meters per second (m/s)",
                        mile_per_hour:"Miles per hour (mph)",
                        knots:"Knots (kn)",
                        ppm_ppb:"ppb - ppm (Particulate Matter in µg/m³)"
                    },
                    changePassword:{
                        change_password:"Change Password",
                        success_changed:"Password successfully updated",
                        invalid_new_password:"Invalid new password",
                        error_unknown:"Unknown error",
                        new_password:"New Password",
                        new_password_must_contain:"New password must contains at least",
                        character:"character",
                        enter_new_password:"Enter your new password",
                        confirm:"Confirm Password",
                        confirm_must_match:"Confirm password must match new password",
                        confirm_your_password:"Confirm your password",
                    },
                    appPassword:{
                        error_fetching:"Error fetching passwords",
                        fetching_password:"fetching passwords",
                        creating_password:"creating password",
                        deleting_password:"deleting passwords",
                        error_deleting_password:"Unknown error deleting password",
                        name:"Name",
                        created:"Created",
                        app_password:"App Password",
                        create_app_password:"Create App Password",
                        delete_app_password:"Delete App password",
                        are_you_sure_to_delete:"Are you sure you want to delete the selected app password?",
                        success_created:`Password sucessfully created`,
                        clipboard:"Password copied to clipboard",
                        password_name:"App Password Name",
                        configure_app_password:"Configure App Password",
                    },
                    organizationPreferences:{
                        title:"Organization Settings",
                        language:"Language",
                        spanish:"Spanish",
                        english:"English",
                        help:"The selected language is used for the generation of reports and emails "+
                            "sent by the platform. Update the language" +
                            "does not affect previously generated reports",
                        could_not_fetch_organization_preference:"Could not fetch organization preferences",
                        previous_report_keep_language:"Language change will not affect previous generated reports",
                        locale_update:"Organization language sucessfully updated",
                        updating_organization_locale:"Updating organization language"
                    },
                    organizationUnit:{
                        title:"Organization Units",
                        previous_report_keep_units:"Previous generated reports and configured alarms will keep the old units.",
                        could_not_fetch_organization_unit:"Could not fetch organization units",
                        units_updated:`Units sucessfully updated`,
                        updating_organization_units:"updating organization units"
                    },
                    organizationMembers:{
                        could_not_fetch_members:"Could not fetch organization members",
                        sucessfully_deleted:`Members successfully deleted`,
                        error_deleting:`Error deleting organization members`,
                        invitation_sent:`Invitation successfully sent`,
                        error_user_not_exist:`Error, the user might not exist anymore. Please refresh the page`,
                        error_sending_invitation:`Unknown error sending invitation`,
                        name:"Name",
                        last_name:'Last Name',
                        email:"Email",
                        resend_invitation:"Resend invitation",
                        memberList:"Member List",
                        invite_new_member:"Invite new member",
                        delete_member:"Delete Member",
                        are_you_sure_to_delete:"Are you sure you want to delete the selected members?"
                    },
                    organizationNodes:{
                        nodes:"Organization Stations",
                        alias:"Alias",
                        serial:"Serial",
                        location:"Location",
                        data:"Data",
                        filter:"Filter stations list by alias, serial or ID",
                        view:"View Data",
                        deviceId:"Station ID"
                    },
                    organizationMembersDialog:{
                        sucessfully_sent:"Invitation successfully sent",
                        invalid_email:"Invalid email, an user with this email might already exist",
                        unknown_error:"Unknown error sending invitation",
                        invite_member:"Invite Member",
                        please_enter_email:"Please enter the receiver email address.",
                        email:"Email",
                        send_invitation:"Send invitation"
                    },
                    analyticScreen:{
                        verifying_device:"Verifying Device...",
                        deviceDescription:{
                            could_not_update_info:"Could not update device Info.",
                            serial_number:"Serial Number",
                            model:"Model",
                            id:"ID",
                            fw:"FW",
                            state:"State",
                            location:"Location",
                            battery_level:"Battery Level",
                            last_data:"Last Data",
                            last_connection:"Last connection establishment:",
                            alias_success_updated:`Alias successfully updated`,
                            updating_alias:"Updating alias.",
                            change_station_alias:"Change device alias",
                            device_alias:"Device alias"
                        },
                        deviceLog:{
                            could_not_update_log:"Could not update station log.",
                            sensor_log:"Station Log",
                            empty:"Empty List",
                            date:"Date",
                            event:"Event"
                        },
                        sensorData:{
                            en_analytics_stationData:"This card view shows the data of each sensor integrated into the " +
                                "node through an interactive chart. You can see all the data of a node and zoom on a range " +
                                "of specific dates, hide sensors, take screenshots among other possibilities.",
                            sensor_data_not_found:`Station data not found for selected period`,
                            could_not_update_station_data:"Could not update station data chart.",
                            station_data:"Station Data"
                        },
                        voc_iaq:{
                            data_not_found:`VOC IAQ sensor data not found for the selected period`,
                            could_not_update_data:"Could not update VOC IAQ chart",
                            title:"VOC IAQ",
                            en_voc_iaq:"This card view shows the VOC IAQ sensor data integrated into the node through an interactive chart. " +
                                "You can zoom on a range of specific dates and take screenshots among other possibilities."
                        },
                        statistic:{
                            mean:"Mean",
                            max:"Max",
                            en_analytics_stationStatistic:"This card view shows the mean and maximum values of the sensors " +
                                "integrated into the node for the selected period",
                            statistic:"Statistic",
                            could_not_update_data:"Could not update sensor statistic data chart.",
                            statistic_data_not_found:`Statistics data not found for selected period`
                        },
                        ambientNoise:{
                            not_found_for_period:`Environment noise data not found for selected period`,
                            could_not_update:"Could not update noise data chart.",
                            ambient_noise:"Ambient Noise",
                            en_analytics_noise: "This card view shows ambient noise sensor data integrated into the " +
                                "node through an interactive chart,You can zoom on a range " +
                                "of specific dates, take screenshots among other possibilities."
                        },
                        pollutantTrend:{
                            data_not_available_for_period:`Pollutant trend data not available for the last month`,
                            could_not_update_trend:"Could not update pollutant trend chart.",
                            pollutant_trend:"Pollutant Trend",
                            data_not_available_for_the_last_month:"No data available for the last month",
                            week_day:"Week day",
                            hour_in_day:"Hour in day",
                            who_threshold:"RD102/2011 Threshold",
                            en_analytics_pollutant_trend: "This card view displays the behavior of each pollutant either daily" +
                                " (averages per day of the week) or hourly (averages per hour of the day), taking as a basis for the calculation" +
                                " the last month of available data",
                            mean_by:"Mean by",
                            pollutant:"Pollutant"
                        },
                        daysOverThreshold:{
                            could_not_update_who_table:"Could not update days over days over Royal decree table.",
                            tittle:"Days Over 102/2011 Royal Decree Threshold",
                            en_analytics_days_over_who:"This card view shows the number of times that a pollutant has been above " +
                                "the limits recommended by the Spanish Royal decree (102/2011) for different predefined periods " +
                                "(last year, last 30 days, and last 7 days)",
                            pollutant:"Pollutant",
                            last_year:"Last year",
                            last_30_days:"Last 30 days",
                            last_7_days:"Last 7 days"
                        },
                        calendar:{
                            data_not_found:`Data not found for selected month`,
                            could_not_update_calendar_invalid_date:"Could not update calendar, invalid date.",
                            could_not_update:"Could not update calendar.",
                            aqi_predominant:"AQI - Predominant Pollutant",
                            caqi_predominant:"CAQI - Predominant Pollutant",
                            en_analytics_calendar : "This card view shows the predominant pollutant for each day of the selected month." +
                                " The predominant pollutant represents the variable with the greatest impact when calculating the pollution index " +
                                "configured in the user profile (AQI / CAQI). ",
                            select_date:"Select Date",
                            monday:"Monday",
                            tuesday:"Tuesday",
                            wednesday:"Wednesday",
                            thursday:"Thursday",
                            friday:"Friday",
                            saturday:"Saturday",
                            sunday:"Sunday"
                        },
                        windSpeed:{
                            title:"Wind Data",
                            sensor_data_not_found:"Wind data not found for selected period",
                            could_not_update_station_data:"Could not update wind data chart.",
                            en_analytics_wind_speed:"This card view shows the wind speed," +
                                " wind gut and wind direction for the selected period",
                            degrees_uppercase:"Degrees",
                            degrees_lowercase:"degrees",
                            speed_mps:"m/s",
                            speed_mph:"mps",
                            speed_knots:"kn",
                        }

                    },
                    compareScreen:{
                        noStationSelected:"No Stations Selected",
                        stationData:{
                            title:"Stations Data",
                            en_compare_stationData : "This card shows the data of each sensor of the selected nodes one " +
                            "sensor at a time, except for relative humidity and temperature, which remain present at all times.",
                            comparable_data_not_found:`Comparable sensor data not found for selected period`,
                            could_not_compare:"Could not update compare sensor data chart."
                        },
                        ambientNoise:{
                            title:"Ambient Noise",
                            en_compare_ambient_noise : "This card shows the ambient noise sensors data of the selected nodes",
                            could_not_update:"Could not update compare noise data chart.",
                            no_data_found:`Comparable environment noise data not found for selected period`
                        },
                        statistic:{
                            title:"Statistics",
                            en_compare_stationStatistic : "This card view displays the average and maximum values of the sensors" +
                                " present in each of the selected nodes for the indicated period",
                            data_not_found:`Comparable statistic data not found for selected period`,
                            could_not_update:"Could not update the statistic comparison chart.",
                            value:"Value",
                            mean:"Mean",
                            max:"Max"
                        }
                    },
                    reportScreen:{
                        name:"Name",
                        status:"Status",
                        queued:"Queued",
                        processing:`Processing...`,
                        completed:"Completed",
                        link:"Link",
                        error_fetching:"Fetching report list",
                        could_not_create_report:"Could not create new report",
                        error_deleting:"deleting reports",
                        error_deleting_unknown:"Unknown error deleting report preference",
                        reports:"Reports",
                        configure_report:"Configure report",
                        delete_report:"Delete Report",
                        are_you_sure_to_delete:"Are you sure you want to delete the selected reports?",
                        there_is_already:"There is already a report called ",
                        with_this_config:" with this preference",
                        preview:"Report Preview",
                        reportConfig:{
                            title:"Configure Report",
                            invalid_period:"Invalid period",
                            report_date_range:"Report date range",
                            report_name:"Report Name",
                            report_period:"Report Period",
                            one_month:"One month",
                            two_month:"Two months",
                            step1:"Report Params",
                            step2:"Stations",
                        }
                    },
                    downloadScreen:{
                        name:"Name",
                        status:"Status",
                        queued:"Queued",
                        processing:"Processing...",
                        completed:"Completed",
                        download_link:'Download Link',
                        detail:"Detail",
                        error_fetching_download_list:"Fetching download list",
                        download_limit_reached:"Download limit reached. Delete download configurations to add new ones",
                        could_not_create_new_download:"Could not create new download",
                        deleting_download:"deleting download configurations",
                        unknown_error_deleting:`Unknown error deleting download configuration`,
                        downloads:"Downloads",
                        configure_download:"Configure download",
                        delete:"Delete",
                        delete_download:"Delete Download",
                        are_you_sure_to_delete:"Are you sure you want to delete the selected downloads configurations?",
                        size:"Size",
                        view_detail:"View Detail",
                        en_download_time :"Downloadable packages files time zone are expressed in UTC. The download preference " +
                            "list could be updated because the interaction of another user of your organization. " +
                            "The CSV file format split columns with a semicolon \";\" and the decimals with a dot \".\"",
                        popUp:{
                            invalid_characters:"Name can not include / and \\ characters or start with .",
                            configure_download:"Configure Download",
                            step1:'Name and Period',
                            step2:"Stations",
                            step3:"Variables",
                            download_name:"Download Name",
                            group_interval:"Grouping Interval",
                            decimal_separator:"Decimal Separator",
                            comma_separator:"Comma \",\"",
                            dot_separator:"Dot \".\""
                        },
                        detailPopUp:{
                            date_range:"Date Range",
                            stations:"Stations",
                            download_detail:"Download Detail",
                            variables:"Variables"
                        }
                    },
                    alarmScreen:{
                        error_fetching_alarms:"downloading alarms",
                        alarm_created:"Alarm sucessfully created",
                        creating_alarm:"creating alarm",
                        alarm_deleted:"Alarms successfully deleted",
                        deleting_alarm:"deleting alarms",
                        deleting_notification:"deleting notifications",
                        notification_deleted:"Notifications successfully deleted",
                        alarm_station:"Alarm Stations",
                        view:"View List",
                        stations:"Stations",
                        station:"Station",
                        sensor:"Sensor",
                        trigger:"Trigger",
                        threshold:"Threshold",
                        creating_date:"Creation Date",
                        delete_alarm:"Delete Alarm",
                        are_you_sure_to_delete:"Are you sure you want to delete the selected alarms?",
                        alarm:"Alarms",
                        add_alarm:"Add alarm",
                        delete:"Delete",
                        popUp:{
                            name:"Name",
                            configureAlarm:"Configure Alarm",
                            value_gratter_theshold:"Value > Threshold",
                            value_gratter_equal_theshold:"Value >= Threshold",
                            value_smaller_threshold:"Value < Threshold",
                            value_smaller_equal_threshold:"Value <= Threshold"
                        },
                        notifications:{
                            value:"Value",
                            date:"Date",
                            notifications:"Notifications",
                            delete_notification:"Delete Notification",
                            are_you_sure_to_delete:"Are you sure you want to delete the selected notifications?"
                        }
                    },
                    invitationAndResetScreen:{
                        invalid_link:"Invalid link",
                        expired_link:"This link expired",
                        no_token_provided:"Invalid link, token not provided",
                        unknown_error:"Unknown error",
                        successfully_joined:"You have successfully joined the organization",
                        an_error_occurred_check_info:"An error occurred, please check the information provided",
                        invitation_link_expired:"The invitation link is expired",
                        password_sucessfully_updated:"Password successfully updated",
                        recover_link_expired:"The recover link is expired",
                        invitation_to_join:"Invitation to join",
                        reset_password:"Reset password",
                        name:"Name",
                        last_name:"Last name",
                        password:"Password",
                        password_too_short:"Password is too short, it must contain at least ",
                        characters:"characters",
                        confirm_password:"Confirm Password",
                        confirm_must_match:"Confirm password must match new password",
                        confirm_your_password:"Confirm your password",
                        join:"Join",
                        reset:"Reset",
                        password_must_contain:"The password must contain at least"
                    },
                    periodSelector:{
                        period:"Period",
                        last_day:"Last 24h",
                        last_7_day:"Last 7 days",
                        last_30_day:"Last 30 days",
                        last_60_day:"Last 60 days",
                        custom_period:"Custom period"
                    }
                }
            },
            es:{
                translation: {
                    local:"Local",
                    utc:"UTC",
                    more_info:"¿Qué significa?",
                    websocket_disconnected:"WebSocket Desconectado",
                    not_available:"No disponible",
                    save:"Guardar",
                    ok:"Ok",
                    cancel:"Cancelar",
                    error:"Error",
                    delete:"Borrar",
                    update:"Actualizar",
                    dataNotFound:"No se encontraron datos",
                    warning:"Advertencia",
                    processing:"Procesando",
                    finish:"Finalizar",
                    next:"Siguiente",
                    back:"Regresar",
                    error_500:"500 Error interno del servidor",
                    error_404:"404 Pagina no encontrada",
                    helpMessages:{
                        en_organization_units : "Esta tarjeta contiene las unidades de la organización usadas para la " +
                            "generación de auto-reportes y como unidades por defecto de los nuevos miembros. " +
                            "Al Cambiar de unidades no se vera afectado los reportes ya generados y las alarmas configuradas"
                    },
                    loginScreen:{
                        forgot:"Forgot password?",
                        password:"Contraseña",
                        minimun_password:"La contraseña debe contener al menos ",
                        characters:"caracteres",
                        remember:"Recordar",
                        sign_in:"Iniciar sesión",
                        forgot_password:"Olvidaste tu contraseña?",
                        email:"Correo electrónico"
                    },
                    forgot:{
                        forgot:"Olvidaste tu contraseña?",
                        not_user_found:"No se encontró ningún usuario asociado a este correo electrónico",
                        invalid_email:"Error, correo electrónico invalido",
                        unknown:"Ha ocurrido un error al intentar recuperar su contraseña",
                        enter_email:"Por favor introduce el email asociado a tu cuenta. Recibirás un enlace por correo electrónico para para crear una nueva contraseña.",
                        email:"Correo electrónico",
                        reset_password:"Restablecer la contraseña",
                        success:'Verifique su correo electrónico y siga los pasos para restablecer su contraseña'
                    },
                    cookies:{
                        enable:"¿Habilitar las cookies del sitio web?",
                        description:"Este sitio web utiliza cookies para mejorar su experiencia.\n" +
                            "                        Solo incluye cookies que garantizan funcionalidades básicas y características de seguridad.",
                        accept:"Aceptar"
                    },
                    dashboard:{
                        error_logout:"Ha ocurrido un error al cerrar la sesión",
                        sentry_front_feedback:"Sugerencias de la plataforma",
                        sentry_send_suggestion:"Enviar sugerencia",
                        sentry_opinion:"A nuestro equipo le encantaría escuchar tu opinion.",
                        sentry_send:"Enviar",
                        sentry_something_to_say:"¿Quieres sugerirnos algo?",
                        bettair_platform:"Plataforma Bettair®",
                        select_station:"Seleccionar Estación",
                        select_stations:"Seleccionar Estaciones",
                        available_stations:"Disponibles",
                        selected_stations:"Seleccionadas",
                        selected:"seleccionadas"
                    },
                    drawer:{
                        home:"Inicio",
                        analytics:"Analizar",
                        compare:"Comparar",
                        alarms:"Alarmas",
                        download:"Descargas",
                        reports:"Reportes",
                        send_suggestions:"Enviar sugerencia"
                    },
                    dashboardMenu:{
                        profile:"Perfil de usuario",
                        organization:"Organización",
                        logout:"Cerrar sesión",
                        platform_manual:"Documentación Plataforma",
                        api_manual:"Documentación API"
                    },
                    home:{
                        state:"Estado:",
                        battery:"Nivel de bateria:",
                        last_connection:"Última conexión:",
                        more_detail:"Más detalle"
                    },
                    aqiLegend:{
                        hazardous:"Peligroso",
                        very_unhealthy:"Muy insalubre",
                        unhealthy:"Insalubre",
                        unhealthy_for_sensitive:"Insalubre para grupos sensibles",
                        moderate:"Moderado",
                        good:"Bueno"
                    },
                    caqiLegend:{
                        very_high:"Muy alto",
                        high:"Alto",
                        medium:"Medio",
                        low:"Bajo",
                        very_low:"Muy bajo"
                    },
                    legend:{
                        aqi:"Indice de Calidad del Aire",
                        caqi:"Indice Común de Calidad del Aire",
                        bettair_station:"Estación Bettair",
                        external_source:"Fuente externa",
                        no_data:"Sin datos",
                        no_connection:"Sin conexión"
                    },
                    profile:{
                        success_updated:`Perfil actualizado con éxito`,
                        error_updating:`Error actualizando el perfil, campo de nombre o apellido inválidos`,
                        could_not_updated:"No se puedo actualizar el perfil",
                        user_profile:"Perfil de Usuario",
                        name:"Nombre",
                        last_name:"Apellido",
                        email:"Correo electrónico",
                        change_password:"Cambiar Contraseña"
                    },
                    units:{
                        pollutant_index:"Indice de Contaminación",
                        concentration_units:"Unidades de Concentración",
                        temperature_units:"Unidades de Temperatura",
                        wind_speed_unit:"Unidades de Velocidad del Viento",
                        meter_per_second:"Metros por segundo (m/s)",
                        mile_per_hour:"Millas por hora (mph)",
                        knots:"Nudos (kn)",
                        ppm_ppb:"ppb - ppm (Particulate Matter en µg/m³)"
                    },
                    changePassword:{
                        change_password:"Cambiar Contraseña",
                        success_changed:"Contraseña actualizada con éxito",
                        invalid_new_password:"Contraseña nueva invalida",
                        error_unknown:"Ha ocurrido un error al actualizar la contraseña",
                        new_password:"Nueva Contraseña",
                        new_password_must_contain:"La nueva contraseña debe contener al menos ",
                        character:"caracteres",
                        enter_new_password:"Introduce tu nueva contraseña",
                        confirm:"Confirmar Contraseña",
                        confirm_must_match:"La confirmación de contraseña debe coincidir con tu nueva contraseña",
                        confirm_your_password:"Confirma tu contraseña",
                    },
                    appPassword:{
                        error_fetching:"Ha ocurrido un error descargando las contraseñas",
                        fetching_password:"descargando contraseñas",
                        creating_password:"creando contraseña",
                        deleting_password:"borrando contraseñas",
                        error_deleting_password:"Ha ocurrido un error borrando las contraseñas",
                        name:"Nombre",
                        created:"Creado",
                        app_password:"Contraseña de Aplicación",
                        create_app_password:"Crear Contraseña",

                        delete_app_password:"Borrar Contraseña de Aplicación",
                        are_you_sure_to_delete:"Estas seguro de borrar las contraseñas seleccionadas?",
                        success_created:`Contraseña creada con éxito`,
                        clipboard:"Contraseña copiada al portapapeles",
                        password_name:"Nombre de la Contraseña",
                        configure_app_password:"Configurar Contraseña",
                    }, organizationPreferences:{
                        title:"Preferencias de la Organización",
                        language:"Idioma",
                        spanish:"Castellano",
                        english:"Ingles",
                        help:"El idioma seleccionado se usa para la generación de reportes y los correos electrónicos " +
                            "enviados por la plataforma. Actualizar el idioma " +
                            "no afecta a reportes previamente generados",
                        could_not_fetch_organization_preference:"No se pudo descargar las preferencias de la organización",
                        previous_report_keep_language:"El cambio de idioma no afecta a reportes previamente generados.",
                        locale_update:"Se ha actualizado con éxito las preferencias de usuario",
                        updating_organization_locale:"Actualizando el idioma de la organización"
                    },
                    organizationUnit:{
                        title:"Unidades de la Organización",
                        previous_report_keep_units:"Los informes generados anteriormente y las alarmas configuradas mantendrán las unidades con las que fueron generadas.",
                        could_not_fetch_organization_unit:"No se pudieron recuperar las unidades de la organización.",
                        units_updated:`Unidades actualizadas con éxito`,
                        updating_organization_units:"actualizando las unidades de la organización "
                    },
                    organizationMembers:{
                        could_not_fetch_members:"No se pudo descarga la lista de miembros de la organización",
                        sucessfully_deleted:`Miembro borrado con éxito`,
                        error_deleting:`Ha ocurrido un error al borrar los miembros de la organización`,
                        invitation_sent:`Invitación enviada con éxito`,
                        error_user_not_exist:`Error, el usuario puedo no continuar existiendo, por favor refresque la pagina`,
                        error_sending_invitation:`Ha ocurrido un error al enviar la invitación`,
                        name:"Nombre",
                        last_name:'Apellido',
                        email:"Correo Electrónico",
                        resend_invitation:"Reenviar invitación",
                        memberList:"Lista de Miembros de la Organización",
                        invite_new_member:"Invitar a nuevo Miembro",
                        delete_member:"Borrar Miembro",
                        are_you_sure_to_delete:"Esta seguro que desea borrar los miembros seleccionados?"
                    },
                    organizationNodes:{
                        nodes:"Estaciones de la Organización",
                        alias:"Alias",
                        serial:"Serial",
                        location:"Localización",
                        data:"Datos",
                        filter:"Filtrar estaciones por alias, serial o ID",
                        view:"Ver Datos",
                        deviceId:"ID de Estación"
                    },
                    organizationMembersDialog:{
                        sucessfully_sent:"Invitación enviada con éxito",
                        invalid_email:"Correo electrónico no valido, ya existe una cuenta asociada a este correo",
                        unknown_error:"Ha ocurrido un error al intentar enviar la invitación",
                        invite_member:"Invitar Miembro",
                        please_enter_email:"Introduzca el correo electrónico del nuevo miembro de la organización",
                        email:"Correo Electrónico",
                        send_invitation:"Enviar invitación"
                    },
                    analyticScreen:{
                        verifying_device:"Verificando Dispositivo...",
                        deviceDescription:{
                            could_not_update_info:"No se pudo actualizar la información de la estación.",
                            serial_number:"Número de serie",
                            model:"Modelo",
                            id:"ID",
                            fw:"FW",
                            state:"Estado",
                            location:"Localización",
                            battery_level:" Batería",
                            last_data:"Último dato",
                            last_connection:"Última conexión establecida:",
                            alias_success_updated:`Alias actualizado con éxito`,
                            updating_alias:"Actualizando el alias.",
                            change_station_alias:"Cambiar alias de estación",
                            device_alias:"Alias"
                        },
                        deviceLog:{
                            could_not_update_log:"No se pudo actualizar el registro de eventos de la estación",
                            sensor_log:"Eventos de la Estación",
                            empty:"Lista vacia",
                            date:"Fecha",
                            event:"Evento"
                        },
                        sensorData:{
                            en_analytics_stationData:"Esta tarjeta muestra los datos de cada sensor integrado en la " +
                                "estación mediante un gráfico interactivo. Puedes ver todos los datos " +
                                "de la estación y hacer zoom en un rango de fechas especifico, ocultar sensores " +
                                "o hacer capturas de pantalla entre otras posibilidades",
                            sensor_data_not_found:`No se han encontrado datos de la estación para el periodo seleccionado`,
                            could_not_update_station_data:"No se pudo actualizar el gráfico de datos de la estación",
                            station_data:"Datos de la Estación"
                        },
                        voc_iaq:{
                            data_not_found:`No se han encontrado datos del sensor VOC IAQ para el periodo seleccionado`,
                            could_not_update_data:"No se pudo actualizar el gráfico de VOC IAQ",
                            title:"VOC IAQ",
                            en_voc_iaq:"Esta tarjeta muestra los datos del sensor VOC IAQ integrado en la estación mediante" +
                                "un gráfico interactivo. Puedes hacer zoom en un rango de fechas especifico y hacer capturas" +
                                "de pantalla."
                        },
                        statistic:{
                            mean:"Media",
                            max:"Máximos",
                            en_analytics_stationStatistic:"Esta tarjeta muestra los valores medios y máximos de los " +
                                "sensores integrados en la estación para el periodo seleccionado",
                            statistic:"Estadística",
                            could_not_update_data:"No se pudo actualizar el gráfico de estadísticas.",
                            statistic_data_not_found:`Datos estadísticos no encontrados para el periodo seleccionado`
                        },
                        ambientNoise:{
                            not_found_for_period:`No se encontraron datos de ruido ambiental para el periodo seleccionado`,
                            could_not_update:"No se pudo actualizar el gráfico de ruido ambiental.",
                            ambient_noise:"Ruido Ambiental",
                            en_analytics_noise: "Esta tarjeta muestra los datos del sensor de ruido ambiental implementado" +
                                " en la estacion mediante un gráfico interactivo. Puedes hacer zoom en un rango de fechas " +
                                "especifico, o hacer capturas de pantalla entre otras posibilidades"
                        },
                        pollutantTrend:{
                            data_not_available_for_period:`Datos de tendencia no disponibles`,
                            could_not_update_trend:"No se pudo actualizar el gráfico de tendencia.",
                            pollutant_trend:"Tendencia",
                            data_not_available_for_the_last_month:"No hay datos disponibles del mes anterior",
                            week_day:"Día de la semana",
                            hour_in_day:"Hora del día",
                            who_threshold:"Umbral RD102/2011",
                            en_analytics_pollutant_trend: "Esta tarjeta muestra el comportamiento de cada contaminante " +
                                "de forma diaria (promedio por dia de la semana) o de forma horaria " +
                                "(promedio por hora del dia), usando como base de calculo el último mes de datos disponible",
                            mean_by:"Media por",
                            pollutant:"Sensor"
                        },
                        daysOverThreshold:{
                            could_not_update_who_table:"No se pudo actualizar la tabla de dias que superan la recomendación del Real decreto.",
                            tittle:"Días por Encima del Umbral del RD102/2011",
                            en_analytics_days_over_who:"Esta tarjeta muestra el numero de días que un contaminante ha " +
                                "estado por encima de los limites recomendados por el Real decreto Español RD102/2011 " +
                                "para diferentes periodos (último año, últimos 30 días, últimos 7 días)",
                            pollutant:"Contaminante",
                            last_year:"Último año",
                            last_30_days:"Últimos 30 días",
                            last_7_days:"Últimos 7 días"
                        },
                        calendar:{
                            data_not_found:`No se encontraron datos para el mes seleccionado`,
                            could_not_update_calendar_invalid_date:"No se pudo actualizar el calendario, fecha no valida.",
                            could_not_update:"No se pudo actualizar el calendario.",
                            aqi_predominant:"AQI - Variable Predominante",
                            caqi_predominant:"CAQI - Variable Predominante",
                            en_analytics_calendar : "Esta tarjeta muestra el contaminante que predomina para cada día" +
                                " del mes seleccionado. El contaminante predominante representa la variable de mayor" +
                                " impacto al calcular el indice de contaminación configurado en el perfil de " +
                                "usuario (AQI - CAQI)",
                            select_date:"Seleccionar Fecha",
                            monday:"Lunes",
                            tuesday:"Martes",
                            wednesday:"Miércoles",
                            thursday:"Jueves",
                            friday:"Viernes",
                            saturday:"Sábado",
                            sunday:"Domingo"
                        },
                        windSpeed:{
                            title:"Datos de Viento",
                            sensor_data_not_found:"No se han encontrado datos de viento para el periodo selecionado",
                            could_not_update_station_data:"No se pudo actualizar el gráfico de datos de viento",
                            en_analytics_wind_speed:"Esta tarjeta muestra datos de la velocidad del viento, " +
                                "de las ráfagas de viento y la dirección del mismo.",
                            degrees_uppercase:"Grados",
                            degrees_lowercase:"grados",
                            speed_mps:"m/s",
                            speed_mph:"mps",
                            speed_knots:"kn",
                        }
                    },
                    compareScreen:{
                        noStationSelected:"No hay estaciones seleccionadas",
                        stationData:{
                            title:"Datos de Estaciones",
                            en_compare_stationData : "Esta tarjeta muestra los datos de cada sensor de las estaciones " +
                                "seleccionadas un sensor a la vez. Con excepción de la temperatura y la " +
                                "humedad relativa presentes de forma permanente",
                            comparable_data_not_found:`No se encontraron datos de las estaciones seleccionadas para el periodo seleccionado`,
                            could_not_compare:"No se pudo actualizar la gráfica con los datos de sensores de las estaciones seleccionadas."
                        },
                        ambientNoise:{
                            title:"Ruido Ambiental",
                            en_compare_ambient_noise : "Esta tarjeta muestra el ruido ambiental registrado por las estaciones seleccionadas",
                            could_not_update:"No se pudo actualizar la gráfica de ruido ambiental.",
                            no_data_found:`No se encontraron datos de ruido ambiental para el periodo seleccionado`
                        },
                        statistic:{
                            title:"Estadística",
                            en_compare_stationStatistic : "Esta tarjeta muestra el promedio y valores máximos de los" +
                                " sensores presentes en las estaciones seleccionadas para el perido seleccionado",
                            data_not_found:`No se encontraron datos estadísticos para el periodo seleccionado`,
                            could_not_update:"No se ha podido actualizar la gráfica de estadísticas.",
                            value:"Valor",
                            mean:"Media",
                            max:"Máximos"
                        }
                    },
                    reportScreen:{
                        name:"Nombre",
                        status:"Estado",
                        queued:"En cola",
                        processing:`Procesando...`,
                        completed:"Completado",
                        link:"Enlace",
                        error_fetching:"Descargando la lista de reportes",
                        could_not_create_report:"No se pudo crear el reporte",
                        error_deleting:"borrando los reportes",
                        error_deleting_unknown:"Ha ocurrido un error al borrar los reportes",
                        reports:"Reportes",
                        configure_report:"Crear reporte",
                        delete_report:"Borrar Reporte",
                        are_you_sure_to_delete:"Estas seguro de que quieres borrar los reportes seleccionados?",
                        there_is_already:"Ya existe un reporte llamado ",
                        with_this_config:" con esta configuración",
                        preview:"Vista previa",
                        reportConfig:{
                            title:"Configurar Reporte",
                            invalid_period:"Periodo no valido",
                            report_date_range:"Periodo del reporte",
                            report_name:"Nombre",
                            report_period:"Periodo de Reporte",
                            one_month:"Un mes",
                            two_month:"Dos meses",
                            step1:"Parámetros",
                            step2:"Estaciones",
                        }
                    },
                    downloadScreen:{
                        name:"Nombre",
                        status:"Estado",
                        queued:"En cola",
                        processing:"Procesando...",
                        completed:"Completado",
                        download_link:'Enlace de descarga',
                        detail:"Detalles",
                        error_fetching_download_list:"Descargando configuraciones de descargas",
                        download_limit_reached:"Se ha alcanzado el limite de descargas. Borre configuraciones de descargas para añadir otras nuevas",
                        could_not_create_new_download:"No se pudo crear una nueva descarga",
                        deleting_download:"borrando configuraciones de descargas",
                        unknown_error_deleting:`Error desconocido al borrar configuraciones de descargas`,
                        downloads:"Descargas",
                        configure_download:"Configurar descarga",
                        delete:"Borrar",
                        delete_download:"Borrar Descarga",
                        are_you_sure_to_delete:"¿Estas seguro de borrar las configuraciones de descargas seleccionadas?",
                        size:"Tamaño",
                        view_detail:"Ver Detalle",
                        en_download_time :"Los archivos de descarga muestran los datos en zona horaria UTC. La lista de" +
                            "descargas puede actualizarse debido a la interacción de otros usuarios de la organización." +
                            " El formato de los ficheros CSV divide las columnas con un punto y coma \";\" y los decimales con un punto \".\"",
                        popUp:{
                            invalid_characters:`El nombre no puede contener los simbolos "/" ó "\\" y no puede empezar con un punto "."`,
                            configure_download:"Configurar Descargas",
                            step1:'Nombre y Periodo',
                            step2:"Estaciones",
                            step3:"Variables",
                            download_name:"Nombre de Descarga",
                            group_interval:"Intervalo de Agrupación",
                            decimal_separator:"Separador Decimal",
                            comma_separator:"Coma \",\"",
                            dot_separator:"Punto \".\""
                        },
                        detailPopUp:{
                            date_range:"Rango de Fechas",
                            stations:"Estaciones",
                            download_detail:"Detalles de Descarga",
                            variables:"Variables"
                        }
                    },
                    alarmScreen:{
                        error_fetching_alarms:"descargando alarmas",
                        alarm_created:"Alarma creada con éxito",
                        creating_alarm:"creando alarma",
                        alarm_deleted:"Alarmas borradas con éxito",
                        deleting_alarm:"borrando alarmas",
                        deleting_notification:"borrando notificaciones",
                        notification_deleted:"Notificaciones borradas con éxito",
                        alarm_station:"Estaciones de Alarma",
                        view:"Ver Lista",
                        station:"Estación",
                        stations:"Estaciones",
                        sensor:"Sensor",
                        trigger:"Evento",
                        threshold:"Umbral",
                        creating_date:"Fecha de Creación",
                        delete_alarm:"Borrar Alarma",
                        are_you_sure_to_delete:"¿Estas seguro de que quieres borrar las alarmas seleccionadas?",
                        alarm:"Alarmas",
                        add_alarm:"Añadir Alarma",
                        delete:"Borrar",
                        popUp:{
                            name:"Nombre",
                            configureAlarm:"Configurar Alarma",
                            value_gratter_theshold:"Valor > Umbral",
                            value_gratter_equal_theshold:"Valor >= Umbral",
                            value_smaller_threshold:"Valor < Umbral",
                            value_smaller_equal_threshold:"Valor <= Umbral"
                        },
                        notifications:{
                            value:"Valor",
                            date:"Fecha",
                            notifications:"Notificaciones",
                            delete_notification:"Borrar Notificación",
                            are_you_sure_to_delete:"¿Estas seguro de borrar las notificaciones seleccionadas?"
                        }
                    },
                    invitationAndResetScreen:{
                        invalid_link:"Enlace no valido",
                        expired_link:"Enlace ha expirado",
                        no_token_provided:"Enlace no valido, el enlace no contiene token",
                        unknown_error:"Ha ocurrido un error",
                        successfully_joined:"Has sido añadido a la organización con éxito",
                        an_error_occurred_check_info:"Ha ocurrido un error, por favor revisa la información",
                        invitation_link_expired:"El enlace de la invitación ha caducado",
                        password_sucessfully_updated:"Contraseña actualizada con éxito",
                        recover_link_expired:"El enlace de recuperación ha caducado",
                        invitation_to_join:"Invitación para unirse a",
                        reset_password:"Restablecer contraseña",
                        name:"Nombre",
                        last_name:"Apellido",
                        password:"Contraseña",
                        password_too_short:"Contraseña muy corta, debe contener al menos ",
                        characters:"caracteres",
                        confirm_password:"Confirmar Contraseña",
                        confirm_must_match:"La confirmación debe coincidir con la nueva contraseña",
                        confirm_your_password:"Confirma tu contraseña",
                        join:"Unirse",
                        reset:"Restablecer",
                        password_must_contain:"La contraseña debe contener al menos"
                    },
                    periodSelector:{
                        period:"Periodo",
                        last_day:"Últimas 24h",
                        last_7_day:"Últimos 7 días",
                        last_30_day:"Últimos 30 días",
                        last_60_day:"Últimos 60 días",
                        custom_period:"Personalizado"
                    }
                }
            }
        }
    });

export default i18n;