

export const MINIMUN_PASSWORD_LENGHT = 8;

export const PROFILE_ROUTE = "/profile";
export const LOGIN_ROUTE = "/login";
export const REGISTER_ROUTE = "/register";
export const RESET_ROUTE = "/reset";
export const ANALYTICS_ROUTE = "/analytics/:id";
export const COMPARE_ROUTE = "/compare";
export const DOWNLOAD_ROUTE = "/download";
export const REPORT_ROUTE = "/reports";
export const ALARM_ROUTE = "/alarm";
export const ORGANIZATION_ROUTE = "/organization";
export const HOME_ROUTE = "/home";

export const DEFAULT_PERIOD = 7;

export const pollutants = {
    no2:"NO2",
    co2:"CO2",
    co:"CO",
    o3:"O3",
    no:"NO",
    so2:"SO2",
    pm1:"PM1",
    pm10:"PM10",
    pm2p5:"PM2P5",
    pm4:"PM4",
    temperature:"temperature",
    rh:"relativeHumidity",
    ah:"absoluteHumidity",
    noise:"noise",
    wind_direction:"wind",
    pressure:"pressure",
    equivalent_pressure:"equivalentPressure"
};

export const STATION_STATE_OFFLINE = "offline";
export const INDEX_AQI = "aqi";
export const INDEX_CAQI = "caqi";

export const MODEL_EXTERNAL_PREFIX = "EXT-";





