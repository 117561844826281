import JsSHA from "jssha";

export async function hashGenerator(message) {
    const shaObj = new JsSHA("SHA-512", "TEXT", { encoding: "UTF8" });
    shaObj.update(message);
    return  shaObj.getHash("HEX");
}



