import React, {useCallback, useEffect, useState} from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography,} from "@mui/material";
import CalendarMonthSelector from "../../analytics/calendar_card/CalendarMonthSelector";
import {dateToReadableUTCString} from "../../../../../utils/dateToReadableDateTimeString";
import {getFinalDayOfMonthInUTC, getInitOfDayMonthInUTC} from "../../../../../utils/dateUtil";
import {isCurrentPeriodValid} from "./ReportConfigPopUp";
import {useTranslation} from "react-i18next";

const ReportParamsConfig = ({name,nameCallback,period,periodCallback,initialDate,
                                updateDate, pollutantUnit, pollutantUnitsCallback,
                            tempUnits,tempoCallback, index,indexCallback,windSpeedUnit,
                                windSpeedCallback
                            }) => {

    const {t} = useTranslation()

    const getReadablePeriod = useCallback((date,period)=>{
       if(isCurrentPeriodValid(date,period)){
           return `${t("reportScreen.reportConfig.report_date_range")}  ${dateToReadableUTCString(getInitOfDayMonthInUTC(date))} - 
        ${dateToReadableUTCString(getFinalDayOfMonthInUTC(date,period-1))}`
       }else {
           return t("reportScreen.reportConfig.invalid_period")
       }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [{readablePeriod},updateState] = useState({readablePeriod:getReadablePeriod(initialDate,period)})

    useEffect(()=>{
        updateState(state => ({...state,readablePeriod: getReadablePeriod(initialDate,period)}))
    },[getReadablePeriod,initialDate,period])

    useEffect(()=>{
        if(initialDate !== null){

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={1}/>
            <Grid item xs={5}>
                <TextField
                    inputProps={{ "data-testid": "report-param-text" }}
                    onChange={nameCallback}
                    value={name}
                    label={t("reportScreen.reportConfig.report_name")} fullWidth/>
            </Grid>
            <Grid item xs={5} >
                <FormControl variant="outlined" fullWidth  margin={"none"}>
                    <InputLabel>{t("units.concentration_units")}</InputLabel>
                    <Select
                        label={t("units.concentration_units")}
                        onChange={pollutantUnitsCallback}
                        value={pollutantUnit}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        <MenuItem value={"eu"}>µg/m³ - mg/m³</MenuItem>
                        <MenuItem value={"usa"}>{t("units.ppm_ppb")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1}/>


            <Grid item xs={1}/>
            <Grid item xs={5} >
                <FormControl variant="outlined" fullWidth  margin={"none"}>
                    <InputLabel>{t("units.temperature_units")}</InputLabel>
                    <Select
                        label={t("units.temperature_units")}
                        onChange={tempoCallback}
                        value={tempUnits}
                    >
                        <MenuItem value={"celsius"}>Celsius (ºC)</MenuItem>
                        <MenuItem value={"fahrenheit"}>Fahrenheit (ºF)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={5}>
                <FormControl variant="outlined" fullWidth margin={"none"}>
                    <InputLabel >{t("units.pollutant_index")}</InputLabel>
                    <Select
                        onChange={indexCallback}
                        label={t("units.pollutant_index")}
                        value={index}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        <MenuItem value={"aqi"}>AQI</MenuItem>
                        <MenuItem value={"caqi"}>CAQI</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1}/>

            <Grid item xs={1}/>
            <Grid item xs={5} >
                <FormControl variant="outlined" fullWidth margin={"none"}>
                    <InputLabel>{t("units.wind_speed_unit")}</InputLabel>
                    <Select
                        label={t("units.wind_speed_unit")}
                        onChange={windSpeedCallback}
                        value={windSpeedUnit}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                    >
                        <MenuItem value={"mps"}>{t("units.meter_per_second")}</MenuItem>
                        <MenuItem value={"mph"}>{t("units.mile_per_hour")}</MenuItem>
                        <MenuItem value={"knots"}>{t("units.knots")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                    <CalendarMonthSelector
                        includeCurrentMonth = {false}
                        date={initialDate}
                        handleDateChange={updateDate}/>
            </Grid>
            <Grid item xs={3} >
                <FormControl variant="outlined" fullWidth  margin={"none"}>
                    <InputLabel>Report Period</InputLabel>
                    <Select
                        label={t("reportScreen.reportConfig.report_period")}
                        onChange={periodCallback}
                        value={period}
                    >
                        <MenuItem value={1}>{t("reportScreen.reportConfig.one_month")}</MenuItem>
                        <MenuItem value={2}>{t("reportScreen.reportConfig.two_month")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={6}/>
            <Grid item container xs={5} style={{display:"flex",alignItems:"flex-start",paddingTop:0,justifyContent:"flex-end"}}>
                <Typography style={{textAlign:"right",fontSize:14,color:isCurrentPeriodValid(initialDate,period) ?
                        "#a1a1a1" :"#d72f2f"}} >{readablePeriod}</Typography>
            </Grid>
            <Grid item xs={1}/>
        </Grid>
        </>
    );
};

export default ReportParamsConfig;
