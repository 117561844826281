import type from "./action_types/type";

const initialState = {
    alarms: []
}

export const alarmReducer = (state = initialState, action) =>{
    switch (action.type){
        case type.addAlarmAction:
            return state.alarms.filter(alarm => alarm.id === action.payload.id).length === 0 ?
             {alarms: [...state.alarms,{...action.payload}]} : {alarms: [...state.alarms]}
        case type.removeAlarmAction:
            return {alarms: state.alarms.filter(alarm => alarm.id !== action.payload)}
        case type.setAlarmsAction:
            return {alarms: [...action.payload]}
        default:
            return state;
    }
}

export const addAlarmAction = function (alarmObject){
    return {
        type:type.addAlarmAction,
        payload:alarmObject
    };
}

export const removeAlarmAction = function (alarmId){
    return {
        type:type.removeAlarmAction,
        payload:alarmId
    };
}

export const setAlarmsAction = function (alarmList){
    return {
        type:type.setAlarmsAction,
        payload:alarmList
    };
}
