import React from 'react';
import {
    Table, TableBody,
    TableContainer,
    TableRow
} from "@mui/material";
import MuiTableCell from "@mui/material/TableCell";
import {makeStyles, withStyles} from "@mui/styles";

const useStyles = makeStyles({
    table: {
        minWidth: 0,
    },
    clickable:{
        color: "#1A23D0",
        fontWeight: "bold",
        '&:hover': {
            textDecoration:"underline",
            cursor: "pointer"
        }
    }
});

const TableCellStyled = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell);

const DeviceInfoList = ({list, locationCallback}) => {
    
    const classes = useStyles();

    return (
    <TableContainer >
        <Table className={classes.table} aria-label="caption table">
            <TableBody>
                {list.map((item) => (
                    <TableRow key={item.id}>
                        <TableCellStyled padding={"none"} variant={"head"} align="left">{item.name}</TableCellStyled>
                        { item.clickable &&
                        <TableCellStyled
                            onClick = { event => locationCallback(item.value)}
                           className = {classes.clickable}
                            padding={"none"}
                            align="right">{item.value}</TableCellStyled>
                        }
                        {
                            !item.clickable &&  <TableCellStyled
                                style={{ color: item.color,fontWeight: item.color && "bold"}}
                                padding={"none"}
                                align="right">{item.value}</TableCellStyled>
                        }
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    );
};

export default DeviceInfoList;
