import React, { useState } from 'react';
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {Divider, Grid, IconButton, Typography} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AQILegend from "./AQILegend";
import CAQILegend from "./CAQILegend";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from './MapLegend.module.scss';
import getIconStyle from "../util/getIconStyle";
import {DARK_GRAY, GRAY, LIGHT_GRAY, TEXT_COLOR_CONTRAST, WHITE} from "./colors";

const useStyles = makeStyles({
    box: {
        borderRadius: 6,
        width: 520
    }
});

const MapLegend = ({ indexType }) => {

    const { t } = useTranslation();
    const { downloadProcessList, minimized } = useSelector(state => state.download);
    const [{ showLegend }, updateState] = useState({ showLegend: true });

    const handleMinimize = () => {
        updateState({ showLegend: false });
    };

    const handleMaximize = () => {
        updateState({ showLegend: true });
    };

    const statusClass = downloadProcessList.length && (
            minimized ? styles.minimized :
                downloadProcessList.length === 1 ? styles.length1 :
                downloadProcessList.length === 2 ? styles.length2 :
                styles.length3orMore
        );

    const classes = useStyles();

    const legendItems = [
        {
            icon: {
                iconSize: 16,
                backgroundColor: WHITE,
                borderRadius: '100%',
                borderColor: LIGHT_GRAY
            },
            text: t("legend.bettair_station")
        },
        {
            icon: {
                iconSize: 12,
                backgroundColor: WHITE,
                borderRadius: 0,
                borderColor: LIGHT_GRAY
            },
            text: t("legend.external_source")
        },
        {
            divider: true
        },
        {
            icon: {
                iconSize: 16,
                backgroundColor: GRAY,
                borderRadius: '100%',
                textColor: TEXT_COLOR_CONTRAST
            },
            iconText: '?',
            text: t("legend.no_data")
        },
        {
            icon: {
                iconSize: 16,
                backgroundColor: DARK_GRAY,
                borderRadius: '100%',
                textColor: TEXT_COLOR_CONTRAST,
                opacity: 0.8
            },
            iconText: '!',
            text: t("legend.no_connection")
        }
    ];

    return (
        <>
            {showLegend &&
                <Grid container className={clsx(classes.box, styles.box, statusClass)} columnSpacing="4px">
                    <Grid item container xs={8} justifyContent={"space-between"} alignItems={"center"}
                        style={{padding: 0}}>
                        <Grid item>
                            <Typography variant={"h6"}
                                        className={styles.mainTitle}>{ indexType === "aqi" ? t("legend.aqi") :
                                indexType === "caqi" ? t("legend.caqi") : "ERROR" }</Typography>
                        </Grid>
                        {indexType === "aqi" ? <AQILegend/> : <CAQILegend/>}
                    </Grid>
                    <Grid item container xs={4} direction="row" columnSpacing="4px">
                        <Grid item container xs={1} justifyContent={"center"}>
                            <Divider orientation="vertical" flexItem />
                        </Grid>
                        <Grid item container xs={11} direction="column"
                              justifyContent={"space-between"} alignItems={"flex-end"} >
                            <Grid item>
                                <IconButton aria-label="minimize" onClick={handleMinimize}>
                                    <RemoveIcon fontSize="small" />
                                </IconButton>
                            </Grid>
                            <Grid item container alignItems={"flex-start"} rowSpacing="6px">
                                {legendItems.map((item) => item.divider ? (
                                    <Divider style={{width:'100%', margin:'7px 0 0'}} />
                                ) : (
                                    <Grid item container direction="row" columnSpacing="5px">
                                        <Grid item container xs={2}
                                              justifyContent={"center"} alignItems={"center"}>
                                            <div style={getIconStyle(item.icon)}>{item.iconText}</div>
                                        </Grid>
                                        <Grid item container spac xs={9} alignItems={"center"}>
                                            <Typography variant="body2">
                                                {item.text}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {!showLegend &&
                <div className={clsx(styles.infoButton, statusClass)}>
                    <IconButton onClick={handleMaximize}>
                        <InfoOutlinedIcon  />
                    </IconButton>
                </div>
            }
        </>
    );
};

export default MapLegend;
