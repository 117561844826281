import {Button, Card, CardContent, Grid, IconButton, Link, Typography} from "@mui/material";
import React, {useCallback, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGrid} from "@mui/x-data-grid";
import {makeStyles} from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import clsx from "clsx";
import DownloadConfigPopUp from "./DownloadConfigPopUp";
import DownloadDetailPopUp from "./DownloadDetailPopUp";
import ConfirmDialog from "../../../common/ConfirmDialog";
import DownloadProgress from "./DownloadProgress";
import DownloadIcon from '@mui/icons-material/Download';
import {useDispatch, useSelector} from "react-redux";
import {createDownloadRequest} from "../../../../requests/downloads/createDownloadSetupRequest";
import {useMountComponent} from "../../../../hooks/useMountComponent";
import {downloadConfigRequest} from "../../../../requests/downloads/downloadConfigRequest";
import {
    addDownloadProcessAction, removeDownloadProcessAction,
    setDownloadListAction, setDownloadProcessErrorAction, setDownloadProgressMinimizedStateAction,
    updateDownloadProcessProgressAction
} from "../../../../reducers/downloadReducer";
import {useSnackbar} from "notistack";
import {expiredSession} from "../../../../reducers/authReducer";
import { DOWNLOAD_ROUTE} from "../../../../constants";
import {deleteDownloadRequest} from "../../../../requests/downloads/deleteDownloadSetupRequest";
import LoadingFullScreen from "../../../common/LoadingFullScreen";
import ServerErrorScreen from "../../../common/ServerErrorScreen";
import {getFileExport} from "../../../../requests/downloads/getFileRequest";
import axios from "axios";
import {roundAccurately} from "../../../../utils/roundNumbers";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {useAnchorEl} from "../../../../hooks/useAnchorEl";
import HelpPopup from "../analytics/common/HelpPopup";
import {useTranslation} from "react-i18next";

const LIST_PAGE_SIZE = 7;

const useStyles = makeStyles({
    root: {
        maxWidth:1800,
        paddingBottom:20,
        paddingTop:20,
        paddingLeft:40,
        paddingRight:40,
    },
    error:{
       color:"#d22929",
        fontWeight:"bold"
    },
    card: {
        background:"white",
        padding:20,
        borderRadius: 5,
        minHeight:245
    },
    firstRowCard: {
        minHeight:600
    },
    cardTittle:{
        marginLeft:20,
        color:"#000000",
        fontWeight:"bold"
    },
    deleteButton:{
        color:"#ffffff",
        background:"#f6343d",
        '&:hover': {
            background:"#81191c"
        },
    },
    rowTable:{
        display:"flex",
        width:"100%vw",
        justifyContent:"center",
        marginTop:32
    },
    helpButton:{
        color:"black"
    },
    table:{
        maxWidth:1600,
        height: 480,
        '& .super-app.current_user': {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {
        },
    },
    not_deletable: {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
    deletable: {
    }
});

const DownloadScreen = () => {

    const {t} = useTranslation()
    const { downloadList,downloadProcessList,processingProgressList } = useSelector( state => state.download );
    const isMounted = useMountComponent();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const axiosCancelToken = axios.CancelToken
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();

    const [{enableDelete,selectionModel,serverError,loading,openConfirmDialog,detailIdPopUp,open,page},updateState] =
        useState({selectionModel:[],
            page:0,
            openConfirmDialog:false,
            detailIdPopUp:"",
            serverError:false,
            enableDelete:false,
            data:[],
            loading:false,
            selectedDate:new Date(),
            open:false});

    const hideDialog = useCallback(()=>{
        updateState(state => ({...state,openConfirmDialog: false}))
    },[])

    const handleDeleteButtonPressed = ()=>{
        updateState(state =>({...state,openConfirmDialog: true}))
    }

    const handleDetail  = (detailIdPopUp)=>{
        updateState(state => ({...state,detailIdPopUp}))
    }

    const handleCloseDetailCallback = useCallback(()=>{
        updateState(state => ({...state,detailIdPopUp:""}))
    },[])

    const updateLoadingState = (isLoading) =>{
        updateState(state => ({...state,loading: isLoading}))
    }

    const onProgressCallback = useCallback((id,progress)=>{
        dispatch(updateDownloadProcessProgressAction(id,progress))
    },[dispatch])

    const onFinishDownloadCallback = useCallback((err,id,resp)=>{
        if(!err){
            const link = document.createElement('a');
            const blob = resp.data;
            link.href = window.URL.createObjectURL(new Blob([blob]));
            let fileName = downloadList.find(item => item.id === id).name
            link.setAttribute('download', `${fileName}.zip`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setTimeout(()=>{
                dispatch(removeDownloadProcessAction(id))
            }, 500, );
        }
        else {
            dispatch(setDownloadProcessErrorAction(id))
        }
    },[downloadList,dispatch])

    const handleStartDownload = useCallback((id)=>{
        let downloadProcess = {id,name:downloadList.find(item => item.id === id).name,
            source:axiosCancelToken.source(),progress:0,error:false}
        dispatch(addDownloadProcessAction(downloadProcess))
        dispatch(setDownloadProgressMinimizedStateAction(false))
        getFileExport(id,onProgressCallback,downloadProcess.source.token,onFinishDownloadCallback)
    },[downloadList,dispatch,axiosCancelToken,onProgressCallback,onFinishDownloadCallback])

    const columns = [
       // { field: 'id', headerName: 'ID',  hide:true },
        { field: 'name', headerName: t("downloadScreen.name") , width: 280, cellClassName: (params) => (
                params.row.status.state === "completed" || params.row.status.state === "error" ?
                    classes.deletable : classes.not_deletable)
        },
        { field: 'status', headerName: t("downloadScreen.status"),width: 180,
                renderCell: (params) => {
                    switch (params.value.state){
                        case "queue":
                            return <Typography>{ t("downloadScreen.queued")}</Typography>
                        case "progress":
                            if(processingProgressList.find(item => item.id === params.row.id) === undefined){
                                return <Typography>{ t("downloadScreen.processing")}</Typography>
                            }else {
                                return <DownloadProgress progress={processingProgressList.find(item => item.id === params.row.id).progress}/>
                            }
                        case "completed":
                            return <Typography>{ t("downloadScreen.completed")}</Typography>
                        case "error":
                            return <Typography className={classes.error}>{ t("error")}</Typography>
                        default:
                            return
                    }
                },
            cellClassName: (params) => (
                 params.row.status.state === "completed" || params.row.status.state === "error" ?
                     classes.deletable : classes.not_deletable)
            },
        {field: 'link', headerName: t("downloadScreen.download_link"),width: 180,
            renderCell: (params) => {
                return params.row.status.state === "completed" &&
                    <IconButton
                        disabled={downloadProcessList.find(item => item.id === params.row.id) !== undefined}
                        onClick={ event => handleStartDownload(params.row.id)} size="small">
                    <DownloadIcon fontSize="inherit" />
                </IconButton>
            },
            cellClassName: (params) => (
                params.row.status.state === "completed" || params.row.status.state === "error" ?
                    classes.deletable : classes.not_deletable)
        },
        { field: 'id', headerName: t("downloadScreen.detail"),width: 180,
            renderCell: (params) => (
                <Link component="button" underline="always" onClick={ event =>{
                    handleDetail(params.value)
                }}>{t("downloadScreen.view_detail")}</Link>
            ),
            cellClassName: (params) => (
                params.row.status.state === "completed" || params.row.status.state === "error" ?
                    classes.deletable : classes.not_deletable)
        },
        { field: 'size', headerName: t("downloadScreen.size"), width: 320,
            renderCell: (params) => (
                <Typography>{ params.row.status.state === "completed" ? params.row.status.size === undefined ? "--" :
                    getSizeString(params.row.status.size):"" }
                </Typography>
            ), cellClassName: (params) => (
                params.row.status.state === "completed" || params.row.status.state === "error" ?
                    classes.deletable : classes.not_deletable)
        }
    ];

    const getSizeString = (size)=>{
        if(size<1024){
            return `${size} Bytes`
        }else if (size*0.0009765625 < 1024){
            return `${roundAccurately(size*0.0009765625,2)} KB`
        } else if (size*0.0009765625*0.0009765625 < 1024){
            return `${roundAccurately(size*0.0009765625*0.0009765625,2)} MB`
        }else{
            return `${roundAccurately(size*0.0009765625*0.0009765625*0.0009765625,2)} GB`
        }
    }


    const classes = useStyles();

    const onSelectionModelChange = (newSelectionModel)=>{
    //TODO change download constant
        updateState(state =>{
            return {...state,enableDelete:(newSelectionModel.length !== 0),
                selectionModel:newSelectionModel.filter(downloadId =>
                {
                    let download = downloadList.find(download => download.id === downloadId)
                    return  download.status.state === "completed" || download.status.state === "error"
                })
        }})
    }

    const updateTable = useCallback(()=>{
        updateState(state => ({...state,loading: true, enableDelete: false}))
        downloadConfigRequest((err,data)=>{
            if (!isMounted.current) {return}
            updateLoadingState(false)
            if(!err && data){
                if(data.length>0){
                    dispatch(setDownloadListAction(data));
                }
            }
            if(err){
                if(data.status === 401){
                    expiredSession(DOWNLOAD_ROUTE)(dispatch)
                }
                else if (data.status === 500){
                    updateState(state => ({...state,loading: false,serverError: true}))
                }
                else {
                    updateLoadingState(false)
                    enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("downloadScreen.error_fetching_download_list")}`,{ variant:"error" });
                }
            }
        })
    },[t,isMounted,dispatch,enqueueSnackbar])

    const finishDownloadSetup = useCallback((body)=>{
        updateState(state =>({...state,open: false,loading: true}))
        createDownloadRequest(body,(err,data)=>{
            if (!isMounted.current) {return}
            if(!err){
                updateLoadingState(false)
                dispatch(setDownloadListAction(data));
            }else {
                if(data.status === 401){
                    expiredSession(DOWNLOAD_ROUTE)(dispatch)
                } else if (data.status === 403) {
                    updateLoadingState(false)
                    enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("downloadScreen.download_limit_reached")}`,{ variant:"error" });
                }
                else if (data.status === 500){
                    updateState(state => ({...state,loading: false,serverError: true}))
                }
                else {
                    updateLoadingState(false)
                    enqueueSnackbar(`${t("error")} ${data.status},
                         ${t("downloadScreen.could_not_create_new_download")}`,{ variant:"error" });
                }
            }
        })
    },[t,dispatch,enqueueSnackbar,isMounted,updateState])

    const cancelDownload = useCallback(()=>{
       updateState(state =>({...state,open: false}))
    },[updateState])

    const handleConfigureNewDownload = ()=>{
        updateState((state =>({...state,open: true})))
    }

    const confirmDelete = useCallback(()=>{
        updateState(state => ({...state,loading: true, openConfirmDialog: false }))
        Promise.all(deleteDownloadRequest(selectionModel)).then(() => {
            if (!isMounted.current) {return}
            updateTable();
        }).catch(err => {
            if (!isMounted.current) {return}
            updateLoadingState(false)
            if(err.response){
                switch (err.response.status){
                    case 401:
                        expiredSession(DOWNLOAD_ROUTE)(dispatch)
                        break;
                    case 404:
                        updateTable();
                        enqueueSnackbar(`${t("error")} ${err.response.status},
                         ${t("downloadScreen.deleting_download")}`,{ variant:"error" });
                        break;
                    case 403:
                        updateTable();
                        enqueueSnackbar(`${t("error")} ${err.response.status},
                         ${t("downloadScreen.deleting_download")}`,{ variant:"error" });
                        break;

                    case 500:
                        updateState(state => ({...state,loading: false,serverError: true}))
                        break;
                    default:
                        updateLoadingState(false)
                        enqueueSnackbar(`${t("error")} ${err.response.status},
                         ${t("downloadScreen.deleting_download")}`,{ variant:"error" });
                        break;
                }
            }else {
                enqueueSnackbar(t("downloadScreen.unknown_error_deleting"),{ variant:"error" });
            }
        });
    },[t,dispatch,updateTable,selectionModel,enqueueSnackbar,isMounted])

    const handlePageUpdate = (event)=>{
        updateState(state =>({...state,page: event.page}))
    }

    return (
        <>
        <Grid className={classes.root} container direction={"row"}
              alignItems={"center"} justifyContent="space-around" spacing={2} >
            <Grid item  xs={12} >
                <Card  className={clsx(classes.card,classes.firstRowCard)}>
                    {
                    <CardContent>
                        <Grid container direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                            <Grid container  item xs={12} sm={12} md={4} lg={6} alignItems={"center"} alignContent={"flex-start"}>
                                <Typography className={classes.cardTittle} variant={"h5"}>{t("downloadScreen.downloads")}</Typography>
                                <IconButton className={classes.helpButton} aria-label="help"
                                            onClick={e => setAnchorEl(e.currentTarget)}>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3} >
                                <Button
                                    data-testid={"download-screen-add"}
                                    fullWidth
                                    disabled={loading}
                                    color={"primary"}
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    onClick={handleConfigureNewDownload}
                                >
                                    {t("downloadScreen.configure_download")}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <Button
                                    fullWidth
                                    data-testid={"download-screen-delete"}
                                    disabled={loading || !enableDelete }
                                    variant="contained"
                                    className={classes.deleteButton}
                                    startIcon={<DeleteIcon />}
                                    onClick={handleDeleteButtonPressed}
                                >
                                    {t("downloadScreen.delete")}
                                </Button>
                            </Grid>
                            <Grid item xs={12} className={classes.rowTable} >
                                <DataGrid
                                    page={page}
                                    onPageChange={handlePageUpdate}
                                    className={classes.table}
                                    rows={downloadList}
                                    columns={columns.map((column) => {
                                            return {
                                                ...column,disableClickEventBubbling: column.field === "link" ||  column.field === "id"
                                            }
                                        }
                                    )}
                                    columnBuffer = {8}
                                    pageSize={LIST_PAGE_SIZE}
                                    Name="dataGrid1"
                                    selectionModel={selectionModel}
                                    onSelectionModelChange={onSelectionModelChange}
                                    checkboxSelection  density={"standard"}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    }
                </Card>
            </Grid>
        </Grid>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("downloadScreen.en_download_time")}/>
            {open && < DownloadConfigPopUp open={open}
                             finishDownloadSetup={finishDownloadSetup}
                             cancelDownload={cancelDownload}/>}
            {detailIdPopUp !== "" && <DownloadDetailPopUp open={detailIdPopUp !== ""}
                                                          downloadDetail = {downloadList.find(item => item.id === detailIdPopUp)}
                                                          handleCloseDetailCallback={handleCloseDetailCallback}/>}
            {openConfirmDialog && <ConfirmDialog hideDialog={hideDialog} tittle={t("downloadScreen.delete_download")}
                           question={t("downloadScreen.are_you_sure_to_delete")}
                           confirm={confirmDelete}/>}
            {loading && <LoadingFullScreen loading={loading}/>}
            {serverError && <ServerErrorScreen />}
        </>
    );
}

export default DownloadScreen;
