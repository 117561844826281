

export const getInitOfDayInUTC = (date)=>{
    return new Date(Date.UTC(date.getFullYear(),date.getMonth(),date.getDate()))
}

export const getFinishOfDayInUTC = (date)=>{
    return new Date(Date.UTC(date.getFullYear(),date.getMonth(),date.getDate()+1)-1)
}

export const getInitOfDayMonthInUTC = (date)=>{
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    return new Date(Date.UTC(startOfMonth.getFullYear(),startOfMonth.getMonth(),startOfMonth.getDate()))
}

export const getFinalDayOfMonthInUTC = (date, monthOffset)=>{
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth()+1+monthOffset, 0);
    return new Date(Date.UTC(lastDayOfMonth.getFullYear(),lastDayOfMonth.getMonth(),lastDayOfMonth.getDate()))
}